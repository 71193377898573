<template>
  <div class="review">
    <div class="search-bar block">
      <div class="search-bar-input">
        <div class="label-input">
          <div class="label order-id-label">订单号</div>
          <el-input v-model="search.orderId"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">订单名称</div>
          <el-input v-model="search.title"
                    class="search-input order-name"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">学习知识点</div>
          <el-input v-model="search.knowledgePoints"
                    class="search-input knowledge-point"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label not-master-label">未攻克的知识点</div>
          <el-input v-model="search.notMaster"
                    class="search-input not-master"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">订单发布者</div>
          <el-input v-model="search.parentPhone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">接单托管员</div>
          <el-input v-model="search.tutorPhone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="button-box">
          <el-button @click="handleSearch"
                    size="small"
                    type="primary">查询</el-button>
          <el-button @click="resetSearch"
                    size="small"
                    class="line-btn"
                    plain>重置</el-button>
        </div>
      </div>
      <div class="search-bar-select">
        <div class="select-item">
          <div class="label number-label">练习题目数</div>
          <el-select class="select number" v-model="search.contactTopicFilter" size="small" @change="handleSearch">
            <el-option v-for="item in numberList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label status-label">学生上课状态</div>
          <el-select class="select status" v-model="search.learningStatus" size="small" @change="handleSearch">
            <el-option v-for="item in statusList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label master-label">知识点是否掌握</div>
          <el-select class="select" v-model="search.masterAll" size="small" @change="handleSearch">
            <el-option v-for="item in masterList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">点评时间</div>
          <el-date-picker v-model="search.createTime"
                          type="daterange"
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          @change="handleSearch">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="table-list">
      <ym-table-list :loading="loading"
                      :data="listData"
                      :columns="listColumns"
                      :pageData="pageData"
                      :tableOption="tableOption"
                      @handlePageChange="handlePageChange">
        <template #content="{row}">
          <template v-if="row.content">
            <el-tooltip class="item" effect="dark" :content="row.content" placement="top">
              <text-line :text="row.content" :clamp="3"></text-line>
            </el-tooltip>
          </template>
          <template v-else>--</template>
        </template>
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import TextLine from '@/components/TextLine'

export default {
  components: {
    YmTableList,
    TextLine
  },
  data() {
    return {
      numberList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '1-50'
        },
        {
          value: 2,
          label: '51-200'
        },
        {
          value: 3,
          label: '201-500'
        },
        {
          value: 4,
          label: '500以上'
        }
      ],
      statusList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '非常集中'
        },
        {
          value: 2,
          label: '良好'
        },
        {
          value: 3,
          label: '不集中'
        }
      ],
      masterList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '是'
        },
        {
          value: 2,
          label: '否'
        }
      ],
      loading: false,
      search: {
        orderId: '',
        title: '',
        knowledgePoints: '',
        notMaster: '',
        parentPhone: '',
        tutorPhone: '',
        contactTopicFilter: 0,
        learningStatus: 0,
        masterAll: 0,
        createTime: ['', '']
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '订单号',
          prop: 'orderId',
          show: true
        },
        {
          label: '订单名称',
          prop: 'title',
          show: true
        },
        {
          label: '学习知识点',
          prop: 'knowledgePoints',
          show: true
        },
        {
          label: '练习题目数',
          prop: 'contactTopic',
          show: true
        },
        {
          label: '学生上课状态',
          prop: 'learningStatus',
          show: true
        },
        {
          label: '知识点是否掌握',
          prop: 'masterAll',
          show: true
        },
        {
          label: '未攻克的知识点',
          prop: 'notMaster',
          show: true
        },
        {
          label: '订单发布者',
          prop: 'parentPhone',
          show: true
        },
        {
          label: '接单托管员',
          prop: 'tutorPhone',
          show: true
        },
        {
          label: '评价时间',
          prop: 'createTime',
          show: true,
          enableSlot: true,
          width: 160
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '查看',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'courseOrderDetail', params: { orderId: row.orderId } })
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  watch: {
    'search.createTime': {
      // 对点击日期选择器的清空按钮进行特殊处理
      handler(val) {
        if (val === null) {
          this.$set(this.search, 'createTime', ['', ''])
          this.getList()
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.params.orderId) {
      this.$set(this.search, 'orderId', this.$route.params.orderId)
    }
    this.getList()
  },
  methods: {
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    resetSearch() {
      this.search = {
        orderId: '',
        title: '',
        knowledgePoints: '',
        notMaster: '',
        parentPhone: '',
        tutorPhone: '',
        contactTopicFilter: 0,
        learningStatus: 0,
        masterAll: 0,
        createTime: ['', '']
      }
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    async getList() {
      this.loading = true
      let res = {}
      try {
        res = await this.$api.feedback.getReviewList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          orderId: this.search.orderId.trim(),
          title: this.search.title.trim(),
          knowledgePoints: this.search.knowledgePoints.trim(),
          notMaster: this.search.notMaster.trim(),
          parentPhone: this.search.parentPhone.trim(),
          tutorPhone: this.search.tutorPhone.trim(),
          contactTopicFilter: this.search.contactTopicFilter,
          learningStatus: this.search.learningStatus,
          masterAll: this.search.masterAll,
          createTimeStart: this.search.createTime[0] ? Date.parse(this.search.createTime[0]) : '',
          createTimeEnd: this.search.createTime[1] ? Date.parse(this.search.createTime[1]) : ''
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.review {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  .search-bar-input {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    .label-input {
      margin-top: 16px;
      .label {
        width: 70px;
        display: inline-block;
        // width: 84px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
        &.order-id-label {
          width: 70px;
        }
        &.not-master-label {
          width: 98px;
        }
      }
      ::v-deep .search-input {
        width: 180px;
        // margin-right: 14px;
        .el-input__inner {
          line-height: 33px;
        }
        &.order-name, &.knowledge-point, &.not-master {
          margin-right: 14px;
        }
      }
    }
    .button-box {
      // margin-left: auto;
      margin-top: 16px;
      margin-left: 28px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  .search-bar-select {
    display: flex;
    flex-wrap: wrap;
    .select-item {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        width: 70px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
        &.number-label {
          width: 70px;
        }
        &.status-label {
          width: 84px;
        }
        &.master-label {
          width: 98px;
        }
      }
      .cascader {
        display: inline-block;
        margin-right: 14px;
      }
      ::v-deep.select {
        width: 100px;
        // margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
        &.number, &.status {
          margin-right: 14px;
        }
        &.status {
          width: 104px;
        }
      }
      ::v-deep.el-date-editor--daterange {
        &.el-input__inner {
          width: 216px;
        }
        .el-range-input {
          width: 70px;
        }
        .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  .control-bar {
    margin-bottom: 10px;
    ::v-deep.el-button {
      width: 100px;
    }
  }
  ::v-deep.table-list {
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body {
        td {
          height: 56px;
        }
        div {
          color: $--color-text-primary;
        }
      }
    }
  }
}
</style>
