<template>
  <div class="distribute">
    <div class="block">
      <!-- <div class="header">新增</div> -->
      <div class="main">
        <el-form
          ref="planForm"
          :model="planForm"
          label-position="right"
          label-width="auto"
          label-suffix="：">
          <el-form-item label="批次名称" prop="name" :rules="rules.name">
            <el-input v-model="planForm.name" placeholder="请输入发送批次名称" size="small"></el-input>
          </el-form-item>
          <template v-for="(set, index) in planForm.planSet">
            <div :key="index" class="plan-set">
              <el-form-item label="产品" :prop="`planSet[${index}].plan`" class="plan" :rules="rules.plan">
                <el-select v-model="planForm.planSet[index].plan" placeholder="请选择" size="small" @change="checkPlanSet(false)">
                  <el-option
                    v-for="item in planOptions"
                    :key="item.productId"
                    :label="item.productName"
                    :value="item.productId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="数量" :prop="`planSet[${index}].quantity`" class="quantity" :rules="rules.quantity">
                <el-input v-model="planForm.planSet[index].quantity" size="small" maxlength="4" @input="checkPlanSet(true, false)"></el-input>
                <span class="tips">份</span>
              </el-form-item>
              <div class="btn-box">
                <el-button @click="handleSetAdd(index)" class="btn">新增</el-button>
                <el-button @click="handleSetDelete(index)" class="btn delete" v-if="index !== 0">删除</el-button>
              </div>
            </div>
          </template>
          <el-form-item label="发送到" prop="phone" :rules="rules.phone">
            <el-input v-model="planForm.phone" placeholder="请输入接收人手机号" size="small" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc" class="desc">
            <el-input v-model="planForm.desc" type="textarea" placeholder="请输入备注" size="small" :rows="8" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item class="footer-box">
            <el-button class="line-btn" size="small" @click="handleCancel">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit('planForm')">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { isPhone } from '@/utils/validate.js'

export default {
  data() {
    let validateName = (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error('批次名称不能为空'))
      } else if (value.length < 2 && value.length > 30) {
        callback(new Error('批次名称长度为2至30'))
      } else {
        callback()
      }
    }

    let validatePlan = (rule, value, callback) => {
      let count = 0
      this.planForm.planSet.forEach(element => {
        if (element.plan === value) {
          count++
        }
      })
      if (!value) {
        callback(new Error('请选择产品'))
      } else if (count > 1) {
        callback(new Error('请勿选择相同产品'))
      } else {
        callback()
      }
    }

    let validateQuantity = (rule, value, callback) => {
      const regExp = /^[1-9]$|^[1-9][0-9]*$/
      console.log(value)
      let sum = 0
      this.planForm.planSet.forEach(item => {
        if (!isNaN(item.quantity)) {
          sum += Number(item.quantity)
        }
      })
      if (!value) {
        callback(new Error('请输入份数'))
      } else if (!regExp.test(value)) {
        callback(new Error('只能输入大于0的整数'))
      } else if (sum > 5000) {
        callback(new Error('产品数量总和不能超过5000'))
      } else {
        callback()
      }
    }

    let validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else if (!isPhone(value)) {
        callback(new Error('手机号码不合法'))
      } else {
        callback()
      }
    }

    return {
      planForm: {
        name: '',
        planSet: [
          {
            plan: '',
            quantity: ''
          }
        ],
        phone: '',
        desc: ''
      },
      rules: {
        name: { validator: validateName, trigger: 'blur', required: true },
        plan: { validator: validatePlan, trigger: 'change', required: true },
        quantity: { validator: validateQuantity, trigger: 'blur', required: true },
        phone: { validator: validatePhone, trigger: 'blur', required: true }
      },
      planOptions: []
    }
  },
  mounted() {
    this.getProductList()
  },
  methods: {
    async getProductList() {
      let res = {}
      try {
        res = await this.$api.operationManagement.getProductList()
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.planOptions = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    handleSetAdd(index) {
      this.planForm.planSet.splice(index + 1, 0, {
        plan: '',
        quantity: ''
      })
      this.resetPlanField()
      this.checkPlanSet(true)
      // this.checkPlan()
    },
    handleSetDelete(index) {
      this.planForm.planSet.splice(index, 1)
      // this.checkPlan()
      this.resetPlanField()
      this.checkPlanSet(true)
    },

    handleSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let packageAssessmentList = []
          this.planForm.planSet.forEach(item => {
            packageAssessmentList.push({
              productTypeId: this.planOptions.find(plan => plan.productId === item.plan).productTypeId,
              productId: item.plan,
              quantity: item.quantity
            })
          })
          const params = {
            batchName: this.planForm.name,
            targetUserPhone: this.planForm.phone,
            remark: this.planForm.desc,
            packageAssessmentList: packageAssessmentList
          }

          this.$confirm(`请核对是否对 ${this.planForm.phone} 发送套餐，发送成功后不可取消`, '发放提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handleDistribute(params)
          })
        } else {
          console.log('error')
          return false
        }
      })
    },
    handleCancel() {
      this.$router.push({ name: 'batchList' })
    },
    // 对产品进行特殊校验（两产品不能重复选择）
    // quantityFlag 为true时对输入的产品数量进行校验
    // setFlag 为 true时对选择的产品进行校验
    checkPlanSet(quantityFlag = false, setFlag = true) {
      console.log(this.planForm)
      for (let i = 0; i < this.planForm.planSet.length; i++) {
        // if (this.planForm.planSet[i].plan) {
        let planField = 'planSet[' + i + '].plan'
        let planQuantityField = 'planSet[' + i + '].quantity'
        console.log(planField)
        if (setFlag) {
          this.$refs.planForm.validateField(planField, error => {
            if (!error) {
            } else {
              return false
            }
          })
        }
        if (quantityFlag) {
          this.$refs.planForm.validateField(planQuantityField, error => {
            if (!error) {
            } else {
              return false
            }
          })
        }
      }
    },
    resetPlanField() {
      console.log(this.$refs.planForm.fields)
      const fields = this.$refs.planForm.fields
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].prop.includes('].plan')) {
          console.log('field', fields[i].prop)
          this.$refs.planForm.clearValidate(fields[i].prop)
        }
      }
    },
    async handleDistribute(params) {
      let res = {}
      try {
        res = await this.$api.operationManagement.distributeBatch(params)
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '发放申请已提交'
        })
        this.$router.push({ name: 'batchList' })
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.distribute {
  .block {
    min-width: 780px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 32px 32px 16px;
  }
  ::v-deep.el-form {
    .el-form-item {
      max-width: 800px;
      .el-form-item__label, .el-radio {
        color: $--color-text-primary;
      }
      &.plan, &.quantity {
        display: inline-block;
      }
      &.plan {
        width: 325px;
      }
      &.quantity {
        width: 260px;
        margin-left: 16px;
        .el-input{
          width: 90px;
        }
        .tips {
          display: inline-block;
          margin-left: 8px;
        }
      }
      &.desc {
        .el-form-item__label {
          line-height: 0;
        }
      }
      .switch-btn {
        .el-switch__label {
          position: absolute;
          display: none;
          color: #fff;
          user-select: none;
          span {
            font-size: 12px;
          }
        }
        .el-switch__label--left {
          z-index: 9;
          left: 21px;
        }
        .el-switch__label--right {
          z-index: 9;
          left: -2px;
        }
        .el-switch__label.is-active {
          display: block;
        }
        .el-switch .el-switch__core,
        .el-switch .el-switch__label {
          width: 50px !important;
        }
        &.refund {
          .el-switch__label--left {
            left: 27px;
          }
          .el-switch__label--right {
            left: 7px;
          }
        }
      }
    }
    .btn-box {
      display: inline-block;
      .btn {
        padding: 6px;
        border: none;
        background-color: transparent;
        font-size: $--font-size-base;
        color: $--color-primary;
        &.delete {
          color: #F9374A;
          // margin-left: 10px;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    .footer-box {
      .el-button {
        width: 100px;
        & + .el-button {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
