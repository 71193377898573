var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-line-box"},[_c('div',{class:['text-line',{
    'text-line--ellipsis':_vm.clamp === 1,
    'text-line--clamp':_vm.clamp > 1,
  }],style:({
    width:_vm.styleWidth,
    '-webkit-line-clamp':_vm.clamp
    }),attrs:{"title":_vm.text}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.text))]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }