
<template>
  <div>
    <ym-table-list :data="listData" :headData="headData" :columns="listColumns">

      <template #sort="{row}">
        <div @click="editCell(row)">
         <span v-if="editCellId!=row.id" style="cursor:pointer">{{row.sort}} </span>
         <el-input-number v-else size="small" :controls="false" v-model="editSortValue" :max='1000' :min='1' @change="changeInput" @blur="sbmitSort(row.id)"></el-input-number>
        </div>
      </template>

      <template #filePath="{row}">
        <div class="img-item" @click="clickFile(row.path, row.type)" >
          <img :src="base+row.path"  v-if="row.type==2">
          <video :src="base+row.path" v-else></video>
        </div>
      </template>

      <template #status="{row}">
        <el-switch v-model="row.status" active-text="显示" :active-value='1' :inactive-value='0' inactive-text="屏蔽" @change="changeSwitch($event, row)"></el-switch>
      </template>
    </ym-table-list>

    <!-- 预览框 -->
    <el-dialog  title="浏览"  :visible.sync="dialogVisible" width="600px">
      <el-image v-if="dialogType==2" :src="dialogUrl" fit="contain"></el-image>
      <video v-else :src="dialogUrl" class="video-dialog" controls="controls"></video>
    </el-dialog>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import dayjs from 'dayjs'
export default {
  components: {
    YmTableList
  },
  props: {
    source: [Number, String]
  },
  data() {
    return {
      loading: false,
      base: window.BASE_URL,
      dialogVisible: false,
      dialogUrl: null,
      dialogType: 1,
      sortForm: {},
      editCellId: null,
      editSortValue: 0,
      headData: {
        items: [
          {
            style: 'button',
            label: '新增',
            icon: 'ym-icon-tianjia',
            size: 'medium',
            type: 'primary',
            clickEvent: (thisVue) => {
              if (thisVue.listData.length >= 10) {
                thisVue.$message.warning('最多上传10张banner')
                return
              }
              thisVue.$router.push({ name: 'banner-add', query: { source: this.source } })
            }
          }
        ]
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '排序(点击可编辑)',
          prop: 'sort',
          show: true,
          enableSlot: true,
          minWidth: 80
        },
        {
          label: 'banner名',
          prop: 'name',
          minWidth: 140,
          show: true
        },
        {
          label: '图片/视频',
          prop: 'filePath',
          show: true,
          enableSlot: true,
          minWidth: 180
        },
        {
          label: '类型',
          prop: 'type',
          show: true,
          formatter: function(row, column, cellValue) {
            return cellValue === (1 || '1') ? '视频' : '图片'
          }
        }, {
          label: '状态',
          prop: 'status',
          enableSlot: true,
          minWidth: 140,
          show: true
        }, {
          label: '创建时间',
          prop: 'createTime',
          minWidth: 140,
          show: true,
          formatter: function(row, column, cellValue) {
            return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 200,
          align: 'right',
          children: {
            edit: {
              label: '编辑',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'banner-add', query: { id: row.id } })
              }
            },
            delete: {
              label: '删除',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.delete(row.id)
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ]
    }
  },
  created() {
    this.initData()
  },
  methods: {
    async sbmitSort(id) {
      try {
        let { data } = await this.$api.banner.updateSort({
          id: id,
          sort: this.editSortValue
        })
        if (data.code === 0) {
          this.$message.success('编辑排序成功')
          setTimeout(() => {
            this.initData()
          }, 1200)
        } else {
          this.$message.error('编辑排序失败')
        }
      } catch (e) {
        console.log(e)
      }
      this.editCellId = null
    },

    async changeSwitch(e, row) {
      console.log(e)
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        let { data } = await this.$api.banner.updateStatus({
          id: row.id,
          status: e
        })
        if (data.code === 0) {
          this.$message.success('修改状态成功')
          this.initData()
        } else {

        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },

    changeInput(e) {
      this.$forceUpdate()
    },

    editCell(row) {
      this.editCellId = row.id
      this.editSortValue = row.sort
    },

    // 预览图片
    clickFile(path, type) {
      this.dialogUrl = window.BASE_URL + path
      this.dialogType = type
      this.dialogVisible = true
    },

    // 删除
    delete(id) {
      this.$confirm('确认删除该banner吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let { data } = await this.$api.banner.del(id)
        if (data.code === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.initData()
        }
      })
    },
    async initData() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.banner.list({
          ascendBySort: true,
          source: this.source
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.img-item{
  width: 250px;
  height: 100px;
  img,video{
    max-width: 300px;
    height: 100%;
    object-fit: cover;
  }
}
.video-dialog{
  width: 100%;
}
</style>
