<template>
  <div class="reservation">
    <div class="search-bar block">
      <div class="search-bar-input">
        <div class="label-input">
          <div class="label">订单号</div>
          <el-input v-model="search.orderId"
                    class="search-input order-id"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">订单标题</div>
          <el-input v-model="search.title"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">手机号</div>
          <el-input v-model="search.phone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="button-box">
          <el-button @click="handleSearch"
                     size="small"
                     type="primary">查询</el-button>
          <el-button @click="resetSearch"
                     size="small"
                     class="line-btn"
                     plain>重置</el-button>
        </div>
      </div>
      <div class="search-bar-select">
        <div class="select-item">
          <div class="label">服务地点</div>
          <ym-address class="cascader"
                      v-model="areaArr"
                      level="3"
                      type="text"
                      separator=""
                      size="small"
                      @changeData="handleArea"></ym-address>
        </div>
        <div class="select-item">
          <div class="label">预约时间</div>
          <el-date-picker v-model="search.createTime"
                          type="daterange"
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          @change="handleSearch">
          </el-date-picker>
        </div>
        <div class="select-item">
          <div class="label">服务类型</div>
          <el-select class="select"
                     v-model="search.stage"
                     size="small"
                     @change="handleStageChange">
            <el-option v-for="(item, index) in stageList"
                       :key="index"
                       :label="item"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">服务项目</div>
          <el-select class="select subject"
                     v-model="search.subject"
                     size="small"
                     :disabled="subjectLoading"
                     @change="handleSearch">
            <el-option v-for="(item, index) in subjectList"
                       :key="index"
                       :label="item"
                       :value="item"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">尾款状态</div>
          <el-select class="select final-pay"
                     v-model="search.payFinal"
                     size="small"
                     @change="handleSearch">
            <el-option v-for="item in finalPaymentList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">订单状态</div>
          <el-select class="select status"
                     v-model="search.authStatus"
                     size="small"
                     @change="handleSearch">
            <el-option v-for="item in statusList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">跟进人</div>
          <el-select class="select follower-select"
                     v-model="search.followerUserId"
                     size="small"
                     @change="handleSearch">
            <el-option v-for="item in followersList"
                       :key="item.userId"
                       :label="item.userName"
                       :value="item.userId"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="table-list">
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #location="{row}">
          {{`${row.city} ${row.area}`}}
        </template>
        <template #address={row}>
          {{`${row.address}`}}
        </template>
        <template #payFinal="{row}">
          <template v-if="row.payFinal === 1">
            <div class="pay-statement">
              <i class="el-icon-circle-check paid"></i>
              <span>已付</span>
            </div>
          </template>
          <template v-else>
            <div class="pay-statement">
              <i class="el-icon-circle-close not-paid"></i>
              <span>未付</span>
            </div>
          </template>
        </template>
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>

        <template #authStatus="{row}">
          <span :class="['status-dot', {'success': row.authStatus === 3, 'progressing': row.authStatus === 2, 'waitting': row.authStatus === 1}]"></span>
          <template>
            <span class="status-text"
                  v-if="row.authStatus === 1">待审核</span>
            <span class="status-text"
                  v-else-if="row.authStatus === 2">审核中</span>
            <span class="status-text"
                  v-else>审核成功</span>
          </template>
        </template>
        <!-- 快捷设置公开订单 -->
        <!-- <template #publicStatus="{row}">
            <el-popover trigger="click" popper-class="follow-confirm" :ref="`popover-switch-${row.id}`">
              <div class="title">订单公开</div>
              <div class="body">
                <span>确认是否公开？订单公开后不能修改，订单将进入“课时订单”且状态更改为“招募中”</span>
              </div>
              <div class="footer">
                <el-button class="line-btn" size="mini" @click="handleSwitchPopClose(row)">取消</el-button>
                <el-button type="primary" size="mini" @click="handleFollow(row.id)">确定</el-button>
              </div>
              <el-switch
                slot="reference"
                :ref="`switch-${row.id}`"
                :model="row.publicStatus"
                active-text="公开"
                inactive-text="不公开"
                :active-value="1"
                :inactive-value="0"
                class="status-switch"
                :width=70
                :disabled="switchLoading[row.id]"
                @change="(val) => handleStatusChange(val, row)"></el-switch>
            </el-popover>
          </template> -->
        <!-- 不存在跟进人是，点击选择跟进人跟进 -->
        <template #follower="{row}">
          <el-popover trigger="click"
                      popper-class="follow-confirm">
            <div class="title">订单跟进</div>
            <div class="body">
              <span>确定跟进该预约订单吗？</span>
              <el-select size="mini"
                         v-model="followers[row.orderId]">
                <el-option v-for="item in followerSelectList"
                           :key="item.userId"
                           :label="item.userName"
                           :value="item.userId"></el-option>
              </el-select>
            </div>
            <div class="footer">
              <el-button class="line-btn"
                         size="mini"
                         @click="handlePopClose()">取消</el-button>
              <el-button type="primary"
                         size="mini"
                         @click="handleFollow(row.orderId, row.followerUserId)">确定</el-button>
            </div>
            <el-button class="follower"
                       slot="reference"
                       v-if="row.followerUserName">{{row.followerUserName}}</el-button>
            <el-button class="follower"
                       slot="reference"
                       v-else>跟进</el-button>
          </el-popover>
        </template>
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmAddress from '@/components/AreaCascade'
import YmTableList from '@/components/TableList/index.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    YmAddress,
    YmTableList
  },
  data () {
    return {
      stageList: [],
      subjectList: [],
      followersList: [],
      followerSelectList: [],
      finalPaymentList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '已付'
        },
        {
          value: 2,
          label: '未付'
        }
      ],
      statusList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '待审核'
        },
        {
          value: 2,
          label: '审核中'
        },
        {
          value: 3,
          label: '审核成功'
        }
      ],
      loading: false,
      subjectLoading: false,
      // btnLoading: false,
      switchLoading: {},
      areaArr: [],
      search: {
        orderId: '',
        title: '',
        phone: '',
        city: '',
        area: '',
        stage: '全部',
        subject: '全部',
        payFinal: 0,
        authStatus: 0,
        followerUserId: '',
        createTime: ['', '']
      },
      userList: [],
      follower: '',
      followers: {},
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '订单号',
          prop: 'orderId',
          show: true
        },
        {
          label: '订单标题',
          prop: 'title',
          show: true
        },
        {
          label: '手机号',
          prop: 'phone',
          show: true,
          width: 120
        },
        {
          label: '服务地点',
          prop: 'address',
          show: true,
          enableSlot: true
          // width: 160
        },
        {
          label: '预约时间',
          prop: 'createTime',
          show: true,
          width: 160,
          enableSlot: true
        },
        {
          label: '服务类型',
          prop: 'stage',
          show: true
        },
        {
          label: '服务项目',
          prop: 'subject',
          show: true
        },
        {
          label: '尾款状态',
          prop: 'payFinal',
          show: true,
          enableSlot: true
        },
        {
          label: '订单状态',
          prop: 'authStatus',
          show: true,
          enableSlot: true,
          'min-width': 90
        },
        {
          label: '跟进人',
          prop: 'follower',
          show: true,
          enableSlot: true,
          'min-width': 120
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '编辑',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'reservationDetail', params: { orderId: row.orderId, editMark: true } })
              }
            }
            // publish: {
            //   label: '公开',
            //   color: '#4674e6',
            //   clickEvent: (thisVue, row) => {
            //     if (!row.followerUserId) {
            //       thisVue.$message({
            //         type: 'error',
            //         message: '您不是当前订单的跟进人，无法公开该订单'
            //       })
            //     } else if (row.authStatus !== 3) {
            //       thisVue.$message({
            //         type: 'error',
            //         message: '当前订单没有通过审核，无法公开该订单'
            //       })
            //     } else if (row.followerUserId === this.userInfo.userId) {
            //       thisVue.$confirm('确认是否公开？订单公开后不能修改，订单将进入“课时订单”且状态更改为“招募中”', '订单公开', {
            //         confirmButtonText: '确定',
            //         cancelButtonText: '取消',
            //         type: 'warning'
            //       }).then(() => {
            //         thisVue.$api.order.openOrder({
            //           orderId: row.orderId
            //         }).then(res => {
            //           if (res.data.code === 0) {
            //             thisVue.$message({
            //               type: 'success',
            //               message: '订单已成功公开'
            //             })
            //             thisVue.getList()
            //           } else {
            //             thisVue.$message({
            //               type: 'error',
            //               message: res.data.message
            //             })
            //           }
            //         }).catch(err => {
            //           thisVue.$message({
            //             type: 'error',
            //             message: err
            //           })
            //         })
            //       }).catch(() => { })
            //     } else {
            //       thisVue.$message({
            //         type: 'error',
            //         message: '您不是当前订单的跟进人，无法公开该订单'
            //       })
            //     }
            //   }
            // },
            // close: {
            //   label: '关闭',
            //   color: '#4674e6',
            //   clickEvent: (thisVue, row) => {
            //     thisVue.$confirm('确认是否关闭订单？关闭订单后状态不能恢复', '订单关闭', {
            //       confirmButtonText: '确定',
            //       cancelButtonText: '取消',
            //       type: 'warning'
            //     }).then(() => {
            //       thisVue.$api.order.closeOrder({
            //         orderId: row.orderId
            //       }).then(res => {
            //         if (res.data.code === 0) {
            //           thisVue.$message({
            //             type: 'success',
            //             message: '订单已关闭'
            //           })
            //           thisVue.getList()
            //         } else {
            //           thisVue.$message({
            //             type: 'error',
            //             message: res.data.message
            //           })
            //         }
            //       }).catch(err => {
            //         thisVue.$message({
            //           type: 'error',
            //           message: err
            //         })
            //       })
            //     })
            //   }
            // }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  watch: {
    'search.createTime': {
      // 对点击日期选择器的清空按钮进行特殊处理
      handler (val) {
        if (val === null) {
          this.$set(this.search, 'createTime', ['', ''])
          console.log(this.search)
          this.getList()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getStageList()
    this.getSubjectList()
    this.getFollowersList()
    // this.getList()
    console.log(this.userInfo)
  },
  // 只有跟进人可以编辑订单
  beforeRouteLeave (to, from, next) {
    console.log(from)
    console.log(to)
    if (to.name === 'reservationDetail') {
      const orderInfo = this.listData.find(item => item.orderId === to.params.orderId)
      if (!orderInfo) {
        this.$message({
          type: 'error',
          message: '请通过点击“编辑”按钮，进入编辑订单'
        })
      } else if (!orderInfo.followerUserId) {
        this.$message({
          type: 'error',
          message: '您不是当前订单的跟进人，无法编辑该订单'
        })
      } else if (this.userInfo.userId === orderInfo.followerUserId) {
        next()
      } else {
        this.$message({
          type: 'error',
          message: '您不是当前订单的跟进人，无法编辑该订单'
        })
      }
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    handleSearch () {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    resetSearch () {
      this.search = {
        orderId: '',
        title: '',
        phone: '',
        city: '',
        area: '',
        stage: '全部',
        subject: '全部',
        payFinal: 0,
        authStatus: 0,
        followerUserId: 0,
        createTime: ['', '']
      }
      this.areaArr = []
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
      this.getSubjectList()
    },
    handleArea () {
      this.$set(this.search, 'city', this.areaArr[1])
      this.$set(this.search, 'area', this.areaArr[2])
      this.getList()
      console.log(this.search)
    },
    // 获取学段列表
    async getStageList () {
      let res = {}
      try {
        res = await this.$api.order.getStageList()
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.stageList = res.data.data
        this.stageList.unshift('全部')
        console.log(this.stageList)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    handleStageChange () {
      this.subjectLoading = true
      this.getSubjectList()
      // if (this.subjectList.includes())
    },
    // 获取科目列表
    async getSubjectList () {
      let res = {}
      try {
        res = await this.$api.order.getSubjectList({
          stage: this.search.stage === '全部' ? '' : this.search.stage
        })
      } catch (err) {
        this.subjectLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.subjectList = res.data.data
        this.subjectList.unshift('全科')
        this.subjectList.unshift('全部')
        if (!this.subjectList.includes(this.search.subject)) {
          this.search.subject = '全部'
        }
        this.getList()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.subjectLoading = false
    },
    // 获取跟进人列表
    async getFollowersList () {
      let res = {}
      try {
        res = await this.$api.order.getFollowersList()
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.followersList = JSON.parse(JSON.stringify(res.data.data))
        this.followerSelectList = res.data.data
        this.followersList.unshift({
          userId: 0,
          userName: '全部'
        })
        this.$set(this.search, 'followerUserId', 0)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async getList () {
      this.loading = true
      let res = {}
      try {
        res = await this.$api.order.getBookingOrderList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          orderId: this.search.orderId.trim(),
          title: this.search.title.trim(),
          phone: this.search.phone.trim(),
          city: this.search.city,
          area: this.search.area,
          stage: this.search.stage === '全部' ? '' : this.search.stage,
          subject: this.search.subject === '全部' ? '' : this.search.subject,
          startTime: this.search.createTime[0] ? Date.parse(this.search.createTime[0]) : '',
          endTime: this.search.createTime[1] ? Date.parse(this.search.createTime[1]) : '',
          payFinal: this.search.payFinal,
          authStatus: this.search.authStatus,
          followerUserId: this.search.followerUserId === 0 ? '' : this.search.followerUserId
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.initFollowers()
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    // 公开订单开关
    // handleStatusChange(val, row) {
    //   this.$set(this.switchLoading, row.id, true)
    // },
    // 获取每条数据中的跟进人信息
    initFollowers () {
      this.followers = {}
      this.listData.forEach(item => {
        this.$set(this.followers, `${item.orderId}`, item.followerUserId)
      })
      console.log(this.followers)
    },
    // 关闭跟随弹出框
    handlePopClose () {
      // console.log(id)
      // console.log(this.$refs[`popover-${id}`])
      // this.$refs[`popover-${id}`].doClose()
      document.body.click()
    },
    // 关闭开关弹出框
    // handleSwitchPopClose(row) {
    //   this.$refs[`popover-switch-${row.id}`].doClose()
    //   console.log(this.$refs[`switch-${row.id}`])
    //   this.$set(this.switchLoading, row.id, false)
    // },
    // 提交跟进人
    handleFollow (orderId, followerUserId) {
      if (!this.followers[orderId] || this.followers[orderId] === followerUserId) {
        this.handlePopClose()
      } else {
        this.setFollower(orderId)
      }
    },
    async setFollower (orderId) {
      let res = {}
      try {
        res = await this.$api.order.setFollower({
          orderId: orderId,
          followerUserId: this.followers[orderId]
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: res.data.message
        })
        this.getList()
        this.handlePopClose()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    handlePageChange (page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.follow-confirm {
  width: 250px;
  padding: 0;
  text-align: left;
  .title {
    padding: 6px 12px;
    border-bottom: $--header-border;
  }
  .body {
    padding: 12px;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__error {
      padding: 0;
    }
    .el-select {
      margin-top: 8px;
      width: 223px;
    }
  }
  .footer {
    padding: 0 12px 12px;
    text-align: right;
  }
  .el-button--primary,
  .line-btn {
    padding: 4px 12px;
  }
}
</style>

<style lang="scss" scoped>
.reservation {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  .search-bar-input {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    .label-input {
      margin-top: 16px;
      .label {
        width: 56px;
        display: inline-block;
        // width: 84px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      ::v-deep .search-input {
        width: 215px;
        // margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
        &.order-id {
          margin-right: 14px;
        }
      }
    }
    .button-box {
      // margin-left: auto;
      margin-top: 16px;
      margin-left: 28px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover,
        &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  .search-bar-select {
    display: flex;
    flex-wrap: wrap;
    .select-item {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        width: 56px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      .cascader {
        display: inline-block;
        margin-right: 14px;
      }
      ::v-deep.select {
        width: 100px;
        margin-right: 20px;
        &.follower-select {
          width: 150px;
        }
        .el-input__inner {
          line-height: 33px;
        }
        &.subject,
        &.final-pay {
          width: 105px;
        }
        &.status {
          width: 105px;
          margin-right: 0;
        }
      }
      ::v-deep.el-date-editor--daterange {
        &.el-input__inner {
          width: 216px;
        }
        .el-range-input {
          width: 70px;
        }
        .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  .control-bar {
    margin-bottom: 10px;
    ::v-deep.el-button {
      width: 100px;
    }
  }
  ::v-deep.table-list {
    .pay-statement {
      display: flex;
      align-items: center;
      .paid {
        color: #27cc85;
      }
      .not-paid {
        color: #ff584d;
      }
      i {
        margin-right: 4px;
        font-size: 12px;
      }
    }
    .status-switch {
      .el-switch__label {
        position: absolute;
        display: none;
        color: #fff;
        user-select: none;
        span {
          font-size: 12px;
        }
      }
      .el-switch__label--left {
        z-index: 9;
        left: 23px;
      }
      .el-switch__label--right {
        z-index: 9;
        left: 8px;
      }
      .el-switch__label.is-active {
        display: block;
      }
    }
    .follower {
      padding: 2px;
      // border: 1px solid $--color-primary;
      border: none;
      background-color: transparent;
      font-size: $--font-size-base;
      color: $--color-primary;
      &:hover {
        background-color: transparent;
      }
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body {
        td {
          height: 56px;
        }
        div {
          color: $--color-text-primary;
        }
      }
    }
    .status-dot {
      display: inline-block;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      &.success {
        background-color: #27cc85;
      }
      &.fail {
        background-color: #ff584d;
      }
      &.waitting {
        background-color: #ffc051;
      }
      &.progressing {
        position: relative;
        background-color: #006eff;
        &::after {
          content: "";
          position: absolute;
          box-sizing: border-box;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid #006eff;
          border-radius: 50%;
          animation: statusProgressing 1.2s ease-in-out infinite;
        }
      }
    }
    .status-text {
      margin-left: 8px;
    }
  }
}
@keyframes statusProgressing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
</style>
