<template>
  <div class="text-line-box">
    <div :class="['text-line',{
      'text-line--ellipsis':clamp === 1,
      'text-line--clamp':clamp > 1,
    }]"
    :style="{
      width:styleWidth,
      '-webkit-line-clamp':clamp
      }"
      :title="text">
      <slot>{{text}}</slot>
    </div>
  </div>
</template>

<script>
/**
 * 行文本
 * 用于多文本溢出省略号
 */
export default {
  name: 'TextLine',
  props: {
    // 宽度
    width: {
      type: [Number, String]
    },
    // 最大行数
    clamp: {
      type: Number,
      default: 1
    },
    // 文本
    text: {
      type: String
    }
  },
  computed: {
    // 判断并返回宽度
    styleWidth() {
      if (typeof this.width === 'number' || /^\d+$/.test(String(this.width))) {
        return `${this.width}px`
      }
      return this.width || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.text-line{
  display: block;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}
//单行省略样式
.text-line--ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap;
}
// 多行省略样式
.text-line--clamp{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  // word-break:break-all;
}

.text-line-box{
  max-width: 100%;
}
</style>
