<template>
  <div class="ticket-management">
    <!-- 三级导航需要从二级导航接入 -->
    <router-view></router-view>
    <!-- 通过自定义$route.meta.menuShow控制跳转至三级导航时，二级导航内容不显示 -->
    <div class="ticket-management-part"
         v-if="$route.meta.menuShow">
      <div class="block">
        <div class="top-left">
          <!-- 票券类型选择菜单 -->
          <div class="selector">
            <el-select v-model="selectedType"
                       placeholder="票券类型"
                       clearable
                       filterable
                       @change="handleTypeSearch">
              <el-option v-for="type in typeList"
                         :key="type.typeId"
                         :label="type.typeName"
                         :value="type.typeId">
              </el-option>
            </el-select>
          </div>

          <div class="selector">
            <el-select v-model="selectedStatus"
                       placeholder="状态"
                       clearable
                       filterable
                       @change="handleStatusSearch">
              <el-option v-for="status in statusList"
                         :key="status.value"
                         :label="status.value"
                         :value="status.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="search-bar top-right">
          <el-input v-model="tableSearch"
                    class="search-input"
                    placeholder="搜索票券名称"
                    prefix-icon="el-icon-search"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch">
          </el-input>
          <div class="button-box">
            <el-button @click="handleSearch"
                       type="primary">搜索</el-button>
            <el-button @click="resetSearchForm" plain>重置</el-button>
          </div>
        </div>
      </div>

      <div>
        <ym-table-list stripe
                       :loading="loading"
                       :headData="headData"
                       :data="listData"
                       :columns="listColumns"
                       :pageData="pageData"
                       :row-style="rowStyle"
                       @handlePageChange="handlePageChange">
          <template #used="{row}">
            {{ row.quantity - row.remain }}
          </template>
          <template #expiry="{row}">
            {{row.beginTime | timeFilter('YYYY.MM.DD')}} - {{row.endTime | timeFilter('YYYY.MM.DD')}}
          </template>
          <template #createTime="{row}">
            <template v-if="row.createTime">{{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
            <template v-else>-</template>
          </template>
        </ym-table-list>
      </div>

      <el-dialog title="分配票券"
                 :visible.sync="showTransfer"
                 destroy-on-close
                 :show-close=false
                 width="30%"
                 @close="handleTransferClose">
        <el-form ref="form"
                 :model="form"
                 class="form"
                 :rules="rules"
                 label-width="120px">
          <el-form-item label="票券名称">
            <el-input disabled
                      v-model="form.ticketName"></el-input>
          </el-form-item>
          <el-form-item label="剩余数量">
            <el-input disabled
                      v-model="form.remain"></el-input>
          </el-form-item>
          <el-form-item label="发放数量(每人)"
                        prop="issueNumber">
            <el-input-number v-model="form.issueNumber"
                             :min="1"></el-input-number>
          </el-form-item>
          <el-form-item label="发放用户"
                        prop="receiverIds">
            <el-select v-model="form.receiverIds"
                       multiple
                       placeholder="请选择用户">
              <el-option v-for='user in userList'
                         :key="user.userId"
                         :label="user.loginAccount"
                         :value="user.userId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="bottom">
          <el-button type="primary"
                     @click="submitForm('form')">确定</el-button>
          <el-button @click="handleTransferClose">取消</el-button>
        </div>
      </el-dialog>

      <el-dialog title="兑换票券"
                 :visible.sync="showWriteOff"
                 destroy-on-close
                 :show-close=false
                 width="30%"
                 @close="handleWriteOffClose">
        <div class="cover">
          <el-input v-model="writeOffCode"
                    placeholder="请输入兑换码"></el-input>
          <el-button type="primary"
                     @click="handleWriteOff">兑换</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    let checkEmpty = (rule, value, callback) => {
      const regExp = /^[1-9]\d*$/
      if (!value) {
        return callback(new Error('发放数量不能为空'))
      } else if (!regExp.test(value)) {
        return callback(new Error('请输入不小于1的正整数'))
      }
      return callback()
    }
    let checkUserList = (rule, value, callback) => {
      if (this.form.receiverIds.length === 0) {
        return callback(new Error('发放用户不能为空'))
      }
      return callback()
    }
    return {
      loading: false,
      tableSearch: '',
      search: '',
      selectedType: '',
      selectedStatus: '',
      typeList: [],
      showTransfer: false,
      statusList: [
        {
          value: '可发放'
        },
        {
          value: '已发完'
        },
        {
          value: '已过期'
        }
      ],
      headData: {
        title: '票券列表',
        items: [
          {
            style: 'button',
            label: '新增',
            icon: 'ym-icon-tianjia',
            size: 'small',
            type: 'primary',
            clickEvent: (thisVue) => {
              thisVue.$router.push('/ticket/list/addition')
            }
          },
          {
            style: 'button',
            label: '兑换',
            icon: 'ym-icon-quanxuan',
            size: 'small',
            clickEvent: (thisVue) => {
              thisVue.writeOffCode = ''
              thisVue.showWriteOff = true
            }
          }
        ]
      },
      listData: [],
      listColumns: [
        {
          label: '票券名称',
          prop: 'ticketName',
          show: true,
          align: 'center'
        },
        {
          label: '总数(张)',
          prop: 'quantity',
          show: true,
          align: 'center'
        },
        {
          label: '已发放(张)',
          prop: 'used',
          show: true,
          align: 'center',
          enableSlot: true
        },

        {
          label: '已兑换(张)',
          prop: 'writeOff',
          show: true,
          align: 'center'
        },
        {
          label: '剩余数量(张)',
          prop: 'remain',
          show: true,
          align: 'center'
        },
        {
          label: '状态',
          prop: 'status',
          show: true,
          align: 'center'
        },
        {
          label: '所属类型',
          prop: 'typeName',
          show: true,
          align: 'center'
        },
        {
          label: '有效期',
          prop: 'expiry',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '描述',
          prop: 'description',
          show: true,
          align: 'center',
          showOverflowTooltip: true
        },
        {
          label: '创建时间',
          prop: 'createTime',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          align: 'center',
          minWidth: '140',
          children: {
            detail: {
              label: '详情',
              icon: 'ym-icon-liulan',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'ticket-list-detail', query: { ticketId: row.ticketId } })
              }
            },
            transfer: {
              label: '分配',
              icon: 'ym-icon-xinxi',
              clickEvent: (thisVue, row) => {
                thisVue.$api.tickets.getUserList().then(res => {
                  if (res.data.code === 0) {
                    thisVue.userList = res.data.data.filter(item => item.userId !== '1')
                    const issuerId = JSON.parse(this.$store.state.user.userInfo).userId
                    console.log(row)
                    thisVue.$set(thisVue.form, 'ticketName', row.ticketName)
                    thisVue.$set(thisVue.form, 'issuerId', issuerId)
                    thisVue.$set(thisVue.form, 'ticketId', row.ticketId)
                    thisVue.$set(thisVue.form, 'typeId', row.typeId)
                    thisVue.$set(thisVue.form, 'remain', row.remain)
                    thisVue.showTransfer = true
                  } else {
                    thisVue.$message({
                      type: 'error',
                      message: res.data.message
                    })
                  }
                }).catch(err => new Error(err))
              }
            }
          }
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      },
      rowStyle: {
        height: '57px'
      },
      form: {
        ticketName: '',
        issuerId: '',
        ticketId: '',
        typeId: '',
        remain: '',
        issueNumber: 1,
        receiverIds: []
      },
      rules: {
        issueNumber: [
          { validator: checkEmpty, trigger: 'blur' }
        ],
        receiverIds: [
          { validator: checkUserList, trigger: ['change', 'blur'] }
        ]
      },
      userList: [],
      writeOffCode: '',
      showWriteOff: false
    }
  },
  mounted() {
    this.getTypeList()
    this.getList()
  },
  watch: {
    '$route.meta.menuShow'() {
      if (this.$route.meta.menuShow) {
        this.getList()
      }
    }
  },
  computed: {
    selectedTypeMap: function() {
      let typeMap = {}
      for (var key in this.typeList) {
        if (this.typeList[key].typeId) typeMap[(this.typeList[key].typeId)] = this.typeList[key].typeName
      }
      return typeMap
    }
  },
  methods: {
    // 重置表单
    resetSearchForm() {
      this.search = ''
      this.tableSearch = ''
      this.selectedType = ''
      this.selectedStatus = ''
      this.headData.title = '票券列表'
      this.getList()
    },
    // 搜索栏搜索
    handleSearch() {
      this.search = this.tableSearch
      this.tableSearch = ''
      let str = ''
      console.log(this.typeList)
      console.log(this.selectedTypeMap)
      if (!(this.selectedType || this.selectedStatus || this.search)) {
        // 表单为空
        str = '票券列表'
      } else if ((this.selectedType || this.selectedStatus) && this.search) {
        str = '在 ' + (this.selectedTypeMap[this.selectedType] || '') + ' ' + (this.selectedStatus || '') + ' 的筛选条件中显示票券名称为“' + this.search + '”的搜索结果'
      } else if (this.search) {
        str = '显示票券名称为“' + this.search + '”的搜索结果'
      } else {
        str = '显示 ' + (this.selectedTypeMap[this.selectedType] || '') + ' ' + (this.selectedStatus || '') + ' 的筛选结果'
      }
      this.headData.title = str
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    // 类型标签搜索
    handleTypeSearch(type) {
      this.selectedType = type
      this.handleSearch()
    },
    // 状态标签搜索
    handleStatusSearch(status) {
      this.selectedStatus = status
      this.handleSearch()
    },
    // 分页跳转
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      this.getList()
    },
    // 获取类型数据
    async getTypeList() {
      let res = {}
      try {
        res = await this.$api.tickets.getAllTicketTypeList()
      } catch (err) {
        return new Error(err)
      }
      if (res.data.code === 0) {
        this.typeList = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    // 获取列表数据
    async getList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.tickets.getTicketsList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          typeId: this.selectedType,
          ticketName: this.search,
          status: this.selectedStatus
        })
      } catch (err) {
        this.loading = false
        return new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    // 分配票券
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            issuerId: this.form.issuerId,
            ticketId: this.form.ticketId,
            typeId: this.form.typeId,
            issueNumber: this.form.issueNumber,
            receiverIds: this.form.receiverIds
          }
          this.$api.tickets.sendTicket(params).then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.message
              })
              this.resetForm()
              this.handleTransferClose()
              this.getList()
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => new Error(err))
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.$set(this.form, 'issueNumber', 1)
      this.$set(this.form, 'receiverIds', [])
    },
    handleTransferClose() {
      this.resetForm()
      this.showTransfer = false
    },
    handleWriteOffClose() {
      this.showWriteOff = false
    },
    // 兑换票券
    handleWriteOff() {
      if (this.writeOffCode) {
        this.$api.tickets.writeOffTicket({
          redemptionCode: this.writeOffCode.trim()
        }).then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.message
            })
            this.handleWriteOffClose()
            this.getList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
            this.writeOffCode = ''
          }
        }).catch(err => new Error(err))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket-management {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 20px 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .top-left {
      display: flex;
      margin-right: 8px;
      .selector + .selector {
        margin-left: 8px;
      }
    }
  }
  .button-box{
    min-width: 160px;
  }
  .search-bar {
    display: flex;
    ::v-deep .search-input {
      width: 350px;
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
  }
  ::v-deep .form {
    min-width: 90%;
    width: 90%;
  }
  .bottom {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    width: 90%;
  }
  .cover {
    display: flex;
  }
}
</style>
