<template>
  <div class="student-detail">
    <div v-loading="infoLoading">
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>基本信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>用户ID：</span>
              <span>{{(basicInfo && basicInfo.userId) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>姓名：</span>
              <span>{{(basicInfo && basicInfo.realName) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>账号：</span>
              <span>{{(basicInfo && basicInfo.loginAccount) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>关联家长账号：</span>
              <template v-if="basicInfo && basicInfo.parentAccounts && basicInfo.parentAccounts.length > 0">
                <template v-for="(item, index) in basicInfo.parentAccounts">
                  <span v-if="index === 0" :key="index" class="arr">{{item}}</span>
                  <span v-else :key="index" class="arr">{{' | ' + item}}</span>
                </template>
              </template>
              <template v-else>
                <span>--</span>
              </template>
            </div>
            <div class="info-item">
              <span>学段：</span>
              <span>{{(basicInfo && basicInfo.stage) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>年级：</span>
              <span>{{(basicInfo && basicInfo.grade) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>账号创建时间：</span>
              <span>{{ basicInfo.createTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>套餐信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>套餐名称：</span>
              <span>{{(basicInfo && basicInfo.packages && basicInfo.packages.packageTypeName) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>剩余套餐天数：</span>
              <span>{{(basicInfo && basicInfo.packages && basicInfo.packages.packageDays) || '--'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      infoLoading: false,
      userId: '',
      basicInfo: {}
    }
  },
  mounted() {
    this.userId = this.$route.params.userId
    this.getList()
  },
  methods: {
    async getList() {
      this.infoLoading = true
      let res = {}
      try {
        res = await this.$api.userManagement.getStudentInfo({
          userId: this.userId
        })
      } catch (err) {
        this.infoLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.infoLoading = false
        this.basicInfo = res.data.data
      } else {
        this.infoLoading = false
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.student-detail {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    // padding: 16px 32px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    .detail-item {
      color: $--color-text-primary;
      // padding: 24px 0 51px;
      padding: 0 0 18px;
      border-bottom: $--header-border;
      &:last-child {
        border-bottom: none;
      }
      .detail-item-title {
        padding: 16px 24px;
        margin-bottom: 12px;
        border-bottom: $--header-border;
        font-size: 16px;
        font-weight: 600;
      }
      .detail-item-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .info-item {
          width: 50%;
          font-size: 14px;
          margin-top: 22px;
          padding-right: 16px;
          &.teaching {
            width: 50%;
          }
          .arr {
            display: inline-block;
            white-space: pre;
          }
        }
      }
      .detail-item-table {
        margin-top: 22px;
        // width: 400px;
      }
    }
    ::v-deep .ym-table {
      .ym-table-header {
        margin-bottom: 20px;
      }
      .ym-table-bottom {
        // padding: 19px 32px;
      }
      .el-table {
        .el-table__header {
          thead tr th {
            padding: 16px 0;
          }
        }
        .el-table__body {
          td {
            height: 56px;
          }
          div {
            color: $--color-text-primary;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .student-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .student-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 100%;
            &.teaching {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
