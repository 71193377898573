<template>
  <div class="teacher-list">
    <div class="search-bar block">
      <div class="label-input">
        <div class="label">手机号</div>
        <el-input v-model="search.phone"
                  size="small"
                  class="search-input"
                  placeholder="请输入"
                  clearable
                  @clear="resetInput"
                  @keyup.enter.native="handleSearch">
        </el-input>
      </div>
      <div class="label-input">
        <div class="label">审核状态</div>
        <el-select v-model="search.auditStatus" placeholder="请选择" class="search-input" size="small" @change="handleSelectChange">
          <el-option
            v-for="t in statusList"
            :key="t.value"
            :label="t.label"
            :value="t.value">
          </el-option>
        </el-select>
      </div>
      <div class="button-box">
        <el-button @click="handleSearch"
                   size="small"
                   type="primary">查询</el-button>
        <el-button @click="resetSearch"
                   size="small"
                   class="line-btn"
                   plain>重置</el-button>
      </div>
    </div>
    <div>
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
        <template #statusName={row}>
            <span :class="['status-dot', {'success': row.status === 3, 'progressing': row.status === 1, 'fail': row.status === 2}]"></span>
            <span class="status-text">{{row.statusName}}</span>
        </template>
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      loading: false,
      search: {
        phone: '',
        auditStatus: 0
      },
      statusList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '待审核',
          value: 1
        },
        {
          label: '审核不通过',
          value: 2
        },
        {
          label: '审核通过',
          value: 3
        }
      ],
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '用户ID',
          prop: 'userId',
          show: true,
          width: 220
        },
        {
          label: '手机号',
          prop: 'phone',
          show: true,
          'min-width': 100
        },
        {
          label: '注册时间',
          prop: 'createTime',
          show: true,
          enableSlot: true,
          'min-width': 150
        },
        {
          label: '审核状态',
          prop: 'statusName',
          show: true,
          enableSlot: true,
          'min-width': 104
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '查看',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'matchPlatformDetail', params: { userId: row.userId } })
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSearch() {
      if (this.search.phone.trim()) {
        // this.headData.title = '显示试卷名为: “' + this.formInput + '” 的搜索结果'
        this.$set(this.pageData, 'currentPage', 1)
        // this.search = this.formInput
        // this.formInput = ''
        this.getList()
      } else {
        this.search.phone = ''
      }
    },
    handleSelectChange() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    // 重置搜索框
    resetSearch() {
      this.search = {
        phone: '',
        auditStatus: 0
      }
      // this.headData.title = ''
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    resetInput() {
      this.$set(this.search, 'phone', '')
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      this.getList()
    },
    async getList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.userManagement.getTeacherListFromMatchPlatform({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          phone: this.search.phone.trim(),
          status: this.search.auditStatus
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.teacher-list {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    // border: $--header-border;
    // border-radius: $--border-radius-base;
    padding: 0 0 16px;
    margin-bottom: 20px;
  }
  .search-bar {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .label-input {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        margin-right: 12px;
        font-size: $--font-size-base;
      }
      ::v-deep .search-input {
        width: 230px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
    }
    .button-box {
      margin-top: 16px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body div {
        color: $--color-text-primary;
      }
    }
    .status-dot {
      display: inline-block;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      &.success {
        background-color: #27cc85;
      }
      &.fail {
        background-color: #ff584d;
      }
      &.progressing {
        position: relative;
        background-color: #006eff;
        &::after {
          content: "";
          position: absolute;
          box-sizing: border-box;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid #006eff;
          border-radius: 50%;
          animation: statusProgressing 1.2s ease-in-out infinite;
        }
      }
    }
    .status-text {
      margin-left: 8px;
    }
  }
}
@keyframes statusProgressing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
</style>
