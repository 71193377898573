<template>
  <div class="ym-cascader">
    <el-cascader
      :placeholder="placeholder"
      :props="props"
      :size="size"
      v-model="selectedOptions"
      :separator="separator"
      @change="handleChange"
    >
    </el-cascader>
  </div>
</template>
<script>
import { pcaa } from 'area-data-vue'
export default {
  name: 'AreaCascade',
  props: {
    // 设置联动层级(1-只选省份/2-省市联动/3-省市区联动)
    level: {
      type: String,
      default: '1'
    },
    // 初始值 例如：['广东省','广州市']
    value: {
      type: Array
    },
    // 设置默认文本
    placeholder: {
      type: String,
      default: '请选择'
    },
    // 选项分隔符
    separator: {
      type: String,
      default: ' / '
    },
    type: {
      type: String,
      default: 'code'
    },
    size: {
      type: String,
      default: ''
    }
  },
  watch: {
    // 当父组件传入初始值为空时，清空已选项
    value: {
      handler(val) {
        if (val.length === 0) {
          this.selectedOptions = []
        }
      },
      deep: true
    }
  },
  data() {
    return {
      selectedOptions: [],
      props: {
        lazy: true,
        level: this.level >= 4 ? 3 : this.level - 1,
        lazyLoad (node, resolve) {
          const { level, value } = node
          const key = value || 86
          setTimeout(() => {
            const nodes = Object.keys(pcaa[key]).map(item => ({
              value: item,
              label: pcaa[key][item],
              leaf: level >= this.level
            }))
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes)
          }, 100)
        }
      }
    }
  },
  created() {
    this.initParam()
  },
  methods: {
    // 文本转代码
    textToCode(textArray) {
      let obj = []
      let id = ''
      let key = 86
      for (let i = 0; i < textArray.length; i++) {
        id = Object.values(pcaa[key]).indexOf(textArray[i])
        key = Object.keys(pcaa[key])[id]
        obj.push(key)
      }
      return obj
    },
    // 代码转文本
    codeToText(codeArray) {
      let obj = []
      let text = ''
      let key = 86
      // console.log('zh', codeArray.length)
      for (let i = 0; i < codeArray.length; i++) {
        text = pcaa[key][codeArray[i]]
        key = codeArray[i]
        obj.push(text)
      }
      return obj
    },
    // 初始化
    initParam() {
      // 判断初始值传入类型
      if (this.value !== undefined && isNaN(this.value[0]) && this.value[0] !== undefined) {
        if (this.value[0].code !== undefined) {
          // 全部类型
          this.selectedOptions = this.value.map((item, index) => {
            return item.code
          })
        } else {
          // 文本类型
          this.selectedOptions = this.textToCode(this.value)
        }
      } else {
        // 代码类型
        this.selectedOptions = this.value
      }
    },
    // 回调
    handleChange(value) {
      let data = []
      if (this.type === 'all') {
        let text = this.codeToText(value)
        data = value.map((item, index) => {
          return { 'code': item, 'text': text[index] }
        })
      } else if (this.type === 'text') {
        data = this.codeToText(value)
      } else {
        data = value
      }
      this.$emit('input', data)
      this.$emit('changeData', data)
      console.log(data)
      console.log(this.selectedOptions)
    }
  }
}
</script>

<style>
.el-cascader-panel {
  height: 300px;
}
</style>
