<template>
  <div class="parent-detail">
    <div v-loading="infoLoading">
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>基本信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>用户ID：</span>
              <span>{{(basicInfo && basicInfo.userId) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>手机号：</span>
              <span>{{(basicInfo && basicInfo.phone) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>姓名：</span>
              <span>{{(basicInfo && basicInfo.name) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>身份证：</span>
              <span>{{(basicInfo && basicInfo.identificationId) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>注册时间：</span>
              <span>{{ basicInfo.createTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>已激活套餐信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>剩余测评份数：</span>
              <span>{{(basicInfo && basicInfo.remainderQuantity) || '--'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>未激活套餐信息</span>
          </div>
          <div class="detail-item-table">
            <ym-table-list :data="planData"
                          :columns="planColumns"
                          :tableOption="tableOption">
              <template #effectiveTime={row}>
                <template v-if="row && row.effectiveTime">
                  {{row.effectiveTime}}
                </template>
                <template v-else>
                  --
                </template>
              </template>
            </ym-table-list>
          </div>
        </div>
      </div> -->
      <!-- <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>关联账号信息</span>
          </div>
          <div class="detail-item-info">
            <ym-table-list :data="listData"
                           :columns="listColumns"
                           :tableOption="tableOption">
              <template #realName={row}>
                <template v-if="row.realName">{{row.realName}}</template>
                <template v-else>--</template>
              </template>
              <template #createTime={row}>
                {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}
              </template>
              <template #packageTypeName={row}>
                <template v-if="row.packages && row.packages.packageTypeName">
                  {{row.packages.packageTypeName}}
                </template>
                <template v-else>--</template>
              </template>
              <template #packageDays={row}>
                <template v-if="row.packages && row.packages.packageDays">
                  {{row.packages.packageDays}}
                </template>
                <template v-else>--</template>
              </template>
            </ym-table-list>
          </div>
        </div>
      </div> -->
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>服务信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>进行中服务信息：</span>
              <template v-if="orderInfo && orderInfo.length > 0">
                <span v-for="(order, index) in orderInfo" :key="index">
                  <template v-if="index === 0">
                    <span class="order" @click="handleOrderClick(order.orderId)">{{order.orderName}}</span>
                  </template>
                  <template v-else>
                    <span>、</span>
                    <span class="order" @click="handleOrderClick(order.orderId)">{{order.orderName}}</span>
                  </template>
                </span>
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>操作日志</span>
          </div>
          <div class="detail-item-table">
            <ym-table-list :loading="loading"
                          :data="logData"
                          :columns="logColumns"
                          :pageData="pageData"
                          :tableOption="tableOption"
                          @handlePageChange="handlePageChange">
              <template #time={row}>
                {{row.time | timeFilter('YYYY.MM.DD HH:mm:ss') }}
              </template>
            </ym-table-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      infoLoading: false,
      loading: false,
      userId: '',
      basicInfo: {},
      orderInfo: [],
      tableOption: {
        stripe: true
      },
      planData: [],
      planColumns: [
        {
          label: '类型',
          prop: 'packageTypeName',
          show: true,
          align: 'center'
        },
        {
          label: '有效期',
          prop: 'effectiveTime',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '数量',
          prop: 'quantity',
          show: true,
          align: 'center'
        }
      ],
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '用户ID',
          prop: 'userId',
          show: true,
          width: 180
        },
        {
          label: '账号',
          prop: 'loginAccount',
          show: true
        },
        {
          label: '姓名',
          prop: 'realName',
          show: true,
          enableSlot: true
        },
        {
          label: '账号创建时间',
          prop: 'createTime',
          show: true,
          enableSlot: true
        },
        {
          label: '套餐',
          prop: 'packageTypeName',
          show: true,
          enableSlot: true
        },
        {
          label: '剩余套餐天数',
          prop: 'packageDays',
          show: true,
          enableSlot: true
        }
      ],
      logData: [],
      logColumns: [
        {
          label: '操作',
          prop: 'operationDesc',
          show: true,
          align: 'center'
        },
        {
          label: '内容',
          prop: 'operationDetail',
          show: true,
          align: 'center'
        },
        {
          label: '时间',
          prop: 'time',
          show: true,
          align: 'center',
          enableSlot: true
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  mounted() {
    this.userId = this.$route.params.userId
    this.getList()
    this.getLogList()
  },
  methods: {
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getLogList()
    },
    async getList() {
      this.infoLoading = true
      let res = {}
      try {
        res = await this.$api.userManagement.getParentInfo({
          userId: this.userId
        })
      } catch (err) {
        this.infoLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.infoLoading = false
        this.basicInfo = res.data.data
        this.orderInfo = this.basicInfo.orderInfo
        this.listData = this.basicInfo.children
        this.planData = this.basicInfo.inactivePackages
      } else {
        this.infoLoading = false
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    handleOrderClick(orderId) {
      this.$router.push({ name: 'courseOrderDetail', params: { orderId: orderId } })
    },
    async getLogList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.userManagement.getLog({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          userId: this.userId
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data && res.data.code === 0) {
        this.logData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.parent-detail {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    // padding: 16px 32px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    .detail-item {
      color: $--color-text-primary;
      // padding: 24px 0 51px;
      padding: 0 0 18px;
      border-bottom: $--header-border;
      &:last-child {
        border-bottom: none;
      }
      .detail-item-title {
        padding: 16px 24px;
        margin-bottom: 12px;
        border-bottom: $--header-border;
        font-size: 16px;
        font-weight: 600;
      }
      .detail-item-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .info-item {
          width: 50%;
          font-size: 14px;
          margin-top: 22px;
          padding-right: 16px;
          &.teaching {
            width: 50%;
          }
          .course {
            display: inline-block;
            white-space: pre;
          }
          .order {
            color: $--color-primary;
            cursor: pointer;
          }
        }
      }
      .detail-item-table {
        margin-top: 22px;
        padding: 0 24px;
        // width: 400px;
      }
    }
    ::v-deep .ym-table {
      .ym-table-header {
        margin-bottom: 20px;
      }
      .ym-table-bottom {
        // padding: 19px 32px;
      }
      .el-table {
        .el-table__header {
          thead tr th {
            padding: 16px 0;
          }
        }
        .el-table__body {
          td {
            height: 56px;
          }
          div {
            color: $--color-text-primary;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .parent-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .parent-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 100%;
            &.teaching {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
