<template>
  <div class="teacher-detail">
    <div v-loading="infoLoading">
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>基本信息</span>
          </div>
          <div class="basic-info">
            <div class="detail-item-info">
              <div class="info-item">
                <span>用户ID：</span>
                <span>{{(basicInfo && basicInfo.userId) || '--'}}</span>
              </div>
              <!-- <div class="info-item">
                <span>昵称：</span>
                <span>{{(basicInfo && basicInfo.nickName) || '--'}}</span>
              </div> -->
              <div class="info-item">
                <span>姓名：</span>
                <span>{{(basicInfo && basicInfo.realName) || '--'}}</span>
              </div>
              <div class="info-item">
                <span>性别：</span>
                <template v-if="basicInfo && (basicInfo.sex || basicInfo.sex === 0)">
                  <span>{{basicInfo.sex === 0 ? '女' : '男'}}</span>
                </template>
                <template v-else>
                  <span>--</span>
                </template>
              </div>
              <div class="info-item">
                <span>手机号：</span>
                <span>{{(basicInfo && basicInfo.phone) || '--'}}</span>
              </div>
              <div class="info-item">
                <span>身份证号：</span>
                <span>{{(basicInfo && basicInfo.idCardNo) || '--'}}</span>
              </div>
            </div>
            <div class="avatar">
              <template v-if="basicInfo && basicInfo.avatar">
                <img :src="basicInfo.avatar" alt="头像">
              </template>
              <template v-else>
                <img src="" alt="">
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>服务信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>服务类型：</span>
              <span>{{teachInfo.stageStr || '--'}}</span>
            </div>
            <div class="info-item">
              <span>服务项目：</span>
              <span>{{teachInfo.subjectStr || '--'}}</span>
            </div>
            <div class="info-item">
              <span>服务城市：</span>
              <span>{{teachInfo.areaStr || '--'}}</span>
            </div>
            <!-- 通过审核的撮合平台托管员详情才会有进行中服务信息 -->
            <template v-if="checkedStatus === 3">
              <div class="info-item ongoing-course">
                <span>进行中服务信息：</span>
                <template v-if="orderInfo && orderInfo.length > 0">
                  <span v-for="(order, index) in orderInfo" :key="index">
                    <template v-if="index === 0">
                      <span class="order" @click="handleOrderClick(order.orderId)">{{order.orderName}}</span>
                    </template>
                    <template v-else>
                      <span>、</span>
                      <span class="order" @click="handleOrderClick(order.orderId)">{{order.orderName}}</span>
                    </template>
                  </span>
                </template>
                <template v-else>--</template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="button-box block" v-if="checkedStatus === 1">
        <el-button class="line-btn" size="small" @click="showRejectReason = true">审核不通过</el-button>
        <el-button type="primary" size="small" @click="handleApprove">审核通过</el-button>
      </div>
      <div class="detail block" v-if="checkedStatus === 3">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>区块信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item teaching info-item-block">
              <span>区块哈希：</span>
              <span>{{(blockChainInfo && blockChainInfo.blockHash) || '--'}}</span>
            </div>
            <div class="info-item teaching info-item-block">
              <span>上链编号：</span>
              <span>{{(blockChainInfo && blockChainInfo.evidenceCode) || '--'}}</span>
            </div>
            <div class="info-item teaching info-item-block">
              <span>上链时间：</span>
              <template v-if="blockChainInfo && blockChainInfo.blockTime">
                {{blockChainInfo.blockTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block" v-if="checkedStatus === 3">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>操作日志</span>
          </div>
          <div class="detail-item-table">
            <ym-table-list :loading="loading"
                          :data="listData"
                          :columns="listColumns"
                          :pageData="pageData"
                          :tableOption="tableOption"
                          @handlePageChange="handlePageChange">
              <template #time={row}>
                {{row.time | timeFilter('YYYY.MM.DD HH:mm:ss') }}
              </template>
            </ym-table-list>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="审核不通过描述说明"
               :visible.sync="showRejectReason"
               top="250px"
               width="710px"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               custom-class="dialog"
               @open="handleOpenDialog">
      <div class="checkbox">
        <el-checkbox-group v-model="reason.checkbox" size="small">
          <el-checkbox-button v-for="option in reasonOptions" :label="option" :key="option">{{option}}</el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class="desc">
        <el-input type="textarea" :rows="5" placeholder="请输入需要补充调整的说明" maxlength="100" show-word-limit v-model="reason.desc"></el-input>
      </div>
      <div class="footer">
        <el-button class="line-btn" size="small" @click="showRejectReason = false">取消</el-button>
        <el-button type="primary" size="small" @click="submitRejectReason">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      jumpFlag: false,
      infoLoading: false,
      loading: false,
      showRejectReason: false,
      reason: {
        // 审核不成功的原因
        checkbox: [],
        desc: ''
      },
      reasonOptions: ['填写的身份信息与学信网信息不一致', '头像不是本人', '头像图片模糊'],
      userId: '',
      checkedStatus: -1,
      basicInfo: {},
      xuexinInfo: {},
      teachInfo: {},
      orderInfo: {},
      blockChainInfo: {},
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          label: '操作',
          prop: 'operationDesc',
          show: true,
          align: 'center'
        },
        {
          label: '内容',
          prop: 'operationDetail',
          show: true,
          align: 'center'
        },
        {
          label: '时间',
          prop: 'time',
          show: true,
          align: 'center',
          enableSlot: true
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'teacherList' && !this.jumpFlag) {
      this.jumpFlag = true
      next({ name: 'teacherList', params: { tab: 'matchPlatform' } })
    } else {
      next()
    }
  },
  mounted() {
    this.userId = this.$route.params.userId
    this.getList()
  },
  methods: {
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getLogList()
    },
    async getList() {
      this.infoLoading = true
      let res = {}
      try {
        res = await this.$api.userManagement.getTeacherInfofromMatchPlatform({
          userId: this.userId
        })
      } catch (err) {
        this.infoLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.infoLoading = false
        this.basicInfo = res.data.data.basicInfo
        this.blockChainInfo = res.data.data.blockChainInfo
        if (this.basicInfo && this.basicInfo.avatar) {
          this.$set(this.basicInfo, 'avatar', window.BASE_URL + this.basicInfo.avatar)
        }
        this.xuexinInfo = res.data.data.xuexinInfo
        this.teachInfo = res.data.data.teachInfo
        this.orderInfo = res.data.data.orderInfo
        // 处理服务类型
        this.checkedStatus = res.data.data.checkedStatus
        if (this.teachInfo && this.teachInfo.stageList && this.teachInfo.stageList.length > 0) {
          this.$set(this.teachInfo, 'stageStr', this.teachInfo.stageList.join('、'))
        } else {
          this.$set(this.teachInfo, 'stageStr', '--')
        }
        // 处理辅导学科
        if (this.teachInfo && this.teachInfo.subjectList && this.teachInfo.subjectList.length > 0) {
          this.$set(this.teachInfo, 'subjectStr', this.teachInfo.subjectList.join('、'))
        } else {
          this.$set(this.teachInfo, 'subjectStr', '--')
        }
        // 处理授课区域
        if (this.teachInfo.allArea === 1) {
          this.$set(this.teachInfo, 'areaStr', this.teachInfo.city)
        } else if (this.teachInfo && this.teachInfo.areaList && this.teachInfo.areaList.length > 0 && this.teachInfo.city) {
          this.$set(this.teachInfo, 'areaStr', this.teachInfo.city + '：' + this.teachInfo.areaList.join('、'))
        } else {
          this.$set(this.teachInfo, 'areaStr', '--')
        }

        if (this.checkedStatus === 3) {
          this.getLogList()
        }
      } else {
        this.infoLoading = false
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    // 跳转到指定订单详情页
    handleOrderClick(orderId) {
      this.$router.push({ name: 'courseOrderDetail', params: { orderId: orderId } })
    },
    // 打开审核不通过弹窗时，清空上一次的输入数据
    handleOpenDialog() {
      this.reason = {
        checkbox: [],
        desc: ''
      }
    },
    submitRejectReason() {
      this.handleAudit(false)
    },
    handleApprove() {
      this.$confirm('是否通过审核？', '确认', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        customClass: 'confirm-box',
        cancelButtonClass: 'line-btn',
        type: 'warning'
      }).then(() => {
        this.handleAudit()
      }).catch(() => { })
    },
    // statusFlag为true通过审核，为false不通过审核
    async handleAudit(statusFlag = true) {
      if (!statusFlag) {
        if (this.reason.checkbox.length === 0 && !this.reason.desc) {
          this.$message({
            type: 'error',
            message: '请至少选择一个不通过原因或填写描述说明'
          })
          return
        }
      }
      let res = {}
      try {
        if (statusFlag) {
          res = await this.$api.userManagement.auditTeacherInfo({
            userId: this.basicInfo.userId,
            authStatus: 3
          })
        } else {
          res = await this.$api.userManagement.auditTeacherInfo({
            userId: this.basicInfo.userId,
            authStatus: 2,
            authMessage: this.reason.desc,
            failReason: this.reason.checkbox
          })
        }
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        if (statusFlag) {
          this.$message({
            type: 'success',
            message: '审核通过信息已经发送'
          })
        } else {
          this.$message({
            type: 'success',
            message: '审核不通过信息已经发送'
          })
        }
        this.$router.push({ name: 'teacherList' })
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async getLogList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.userManagement.getLog({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          userId: this.userId
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data && res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.el-dialog {
  .el-dialog__header {
    padding: 16px 20px;
    border-bottom: $--header-border;
    font-weight: 600;
    .el-dialog__title {
      font-size: $--font-size-medium;
    }
    .el-dialog__headerbtn {
      font-size: $--font-size-medium;
      line-height: 21px;
      .el-dialog__close:before {
        color: $--color-text-regular;
      }
    }
  }
}
.dialog {
  border-radius: $--border-radius-base;
}
</style>

<style lang="scss" scoped>
.teacher-detail {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    // padding: 16px 32px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    .detail-item {
      color: $--color-text-primary;
      // padding: 24px 0 51px;
      padding: 0 0 18px;
      // border-bottom: $--header-border;
      &:last-child {
        border-bottom: none;
      }
      .basic-info {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        .detail-item-info {
          width: 85%;
        }
        .avatar {
          width: 150px;
          margin-right: 36px;
          img {
            width: 150px;
            height: 150px;
            border-radius: 4px;
          }
        }
      }
      .detail-item-title {
        padding: 16px 24px;
        margin-bottom: 12px;
        border-bottom: $--header-border;
        font-size: 16px;
        font-weight: 600;
      }
      .detail-item-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .info-item {
          width: 50%;
          font-size: 14px;
          margin-top: 22px;
          padding-right: 16px;
          &.teaching {
            width: 50%;
          }
          &.ongoing-course {
            width: 100%;
          }
          .course {
            display: inline-block;
            white-space: pre;
          }
          .order {
            color: $--color-primary;
            cursor: pointer;
          }
        }
        .info-item-block{
          min-width:100%;
          span{
            word-break: break-all;
          }
        }
      }
      .detail-item-table {
        margin-top: 22px;
        padding: 0 24px;
        // width: 400px;
      }
    }
    ::v-deep .ym-table {
      .ym-table-header {
        margin-bottom: 20px;
      }
      .ym-table-bottom {
        // padding: 19px 32px;
      }
      .el-table {
        .el-table__header {
          thead tr th {
            padding: 16px 0;
          }
        }
        .el-table__body {
          td {
            height: 56px;
          }
          div {
            color: $--color-text-primary;
          }
        }
      }
    }
  }
  .button-box {
    padding-top: 16px;
    text-align: center;
    ::v-deep.el-button {
      width: 100px;
      &+.el-button {
        margin-left: 56px;
      }
    }
  }
  ::v-deep.el-checkbox-group {
    text-align: center;
    .el-checkbox-button {
      &+.el-checkbox-button {
        margin-left: 20px;
      }
      &__inner {
        width: 130px;
        height: 44px;
        line-height: 26px;
        border-radius: $--border-radius-base;
        border-left: 1px solid #DEDEDE;
        white-space: normal;
        word-break: break-all;
      }
      &:first-child {
        .el-checkbox-button__inner {
          line-height: 1;
        }
      }
      &.is-checked {
        .el-checkbox-button__inner {
          box-shadow: none;
          border-left-color: $--color-primary;
        }
      }
      &.is-focus {
        .el-checkbox-button__inner {
          border-left-color: $--color-primary;
        }
      }
    }
  }
  .desc {
    padding: 0 46px;
    margin: 32px 0;
    ::v-deep.el-textarea {
      .el-input__count {
        color: $--color-text-placeholder;
      }
    }
  }
  .footer {
    text-align: center;
    ::v-deep.el-button {
      width: 184px;
      &+.el-button {
        margin-left: 72px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .teacher-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 654px) {
  .teacher-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 100%;
            &.teaching {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
