<template>
  <div class="operation-setting">
    <div v-loading="commissionLoading && thresholdLoading">
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>订单佣金设置</span>
            <el-button type="primary"
                       size="small"
                       @click="openCommissionDialog">设置</el-button>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>托管订单薪资：</span>
              <template v-if="commissionPercentage && commissionPercentage.tutorBrokerageScale">
                <span>{{`${commissionPercentage.tutorBrokerageScale}%`}}</span>
              </template>
              <template v-else>--</template>
            </div>
            <div class="info-item">
              <span>推荐托管员佣金：</span>
              <template v-if="commissionPercentage && commissionPercentage.tutorReferenceBrokerageScale || commissionPercentage.tutorReferenceBrokerageScale === 0">
                <span>{{`${commissionPercentage.tutorReferenceBrokerageScale}%`}}</span>
              </template>
              <template v-else>--</template>
            </div>
            <div class="info-item">
              <span>推荐家长佣金：</span>
              <template v-if="commissionPercentage && commissionPercentage.parentReferenceBrokerageScale || commissionPercentage.parentReferenceBrokerageScale === 0">
                <span>{{`${commissionPercentage.parentReferenceBrokerageScale}%`}}</span>
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>提现审核临界值设置</span>
            <el-button type="primary"
                       size="small"
                       @click="openThresholdDialog">设置</el-button>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>临界值：</span>
              <template v-if="threshold && threshold.cashCriticalValue">
                <span>{{`${threshold.cashCriticalValue.toFixed(2)}元`}}</span>
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>服务订单预付款设置</span>
            <el-button type="primary"
                       size="small"
                       @click="openSubsistDialog">设置</el-button>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>预付款金额：</span>
              <template v-if="subsist && (subsist.subsist || subsist.subsist==0)">
                <span>{{`${subsist.subsist}元`}}</span>
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="订单佣金设置"
               :visible.sync="commissionDialog"
               top="250px"
               width="480px"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               custom-class="dialog">
      <el-form :model="commissionForm"
               label-position="left"
               ref="commissionForm"
               class="commission-form">
        <el-form-item label="托管订单新资"
                      prop="tutorBrokerageScale"
                      :rules="commissionRules.tutorBrokerageScale">
          <el-input v-model="commissionForm.tutorBrokerageScale"
                    size="mini"
                    @focus="getInputFocus($event)"
                    @input="checkCommission"></el-input>
          <span>%</span>
        </el-form-item>
        <el-form-item label="推荐托管员佣金"
                      prop="tutorReferenceBrokerageScale"
                      :rules="commissionRules.tutorReferenceBrokerageScale">
          <el-input v-model="commissionForm.tutorReferenceBrokerageScale"
                    size="mini"
                    @focus="getInputFocus($event)"
                    @input="checkCommission"></el-input>
          <span>%</span>
        </el-form-item>
        <el-form-item label="推荐家长佣金"
                      prop="parentReferenceBrokerageScale"
                      :rules="commissionRules.parentReferenceBrokerageScale">
          <el-input v-model="commissionForm.parentReferenceBrokerageScale"
                    size="mini"
                    @focus="getInputFocus($event)"
                    @input="checkCommission"></el-input>
          <span>%</span>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button class="line-btn"
                   size="small"
                   @click="commissionDialog = false">取消</el-button>
        <el-button type="primary"
                   size="small"
                   @click="submitCommission">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提现审核临界值设置"
               :visible.sync="thresholdDialog"
               top="250px"
               width="480px"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               custom-class="dialog"
               class="threshold">
      <el-form :model="thresholdForm"
               label-position="left"
               ref="thresholdForm"
               class="threshold-form"
               :rules="thresholdRules">
        <el-form-item label="临界值"
                      prop="cashCriticalValue">
          <el-input v-model="thresholdForm.cashCriticalValue"
                    size="mini"
                    @focus="getInputFocus($event)"></el-input>
          <span>元</span>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button class="line-btn"
                   size="small"
                   @click="thresholdDialog = false">取消</el-button>
        <el-button type="primary"
                   size="small"
                   @click="submitThreshold">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="预付款金额设置"
               :visible.sync="subsistDialog"
               top="250px"
               width="480px"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               custom-class="dialog"
               class="threshold">
      <el-form :model="subsistForm"
               label-position="left"
               ref="subsistForm"
               class="threshold-form"
               :rules="subsistRules">
        <el-form-item label="预付款金额"
                      prop="subsist">
          <el-input v-model="subsistForm.subsist"
                    size="mini"
                    @focus="getInputFocus($event)"></el-input>
          <span>元</span>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button class="line-btn"
                   size="small"
                   @click="subsistDialog = false">取消</el-button>
        <el-button type="primary"
                   size="small"
                   @click="submitSubsist">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    let formRule = (rule, value, callback) => {
      const regExp = /^\d+(\.\d+)?$/
      const sum = this.getTotalPercentage()
      if (!value && Number(value) !== 0) {
        callback(new Error('请设置分佣比例'))
      } else if (!regExp.test(value)) {
        callback(new Error('请输入正确的分佣比例'))
      } else if (sum > 100) {
        callback(new Error('分佣比例总和不能超过100%'))
      } else {
        callback()
      }
    }

    let thresholdRule = (rule, value, callback) => {
      const regExp = /^0\.([1-9])$|^0\.(0[1-9])$|^0\.([1-9][0-9])$|^[1-9][0-9]*\.[0-9][0-9]?$|^[1-9][0-9]*$/
      if (!value && Number(value) !== 0) {
        callback(new Error('请输入临界值'))
      } else if (!regExp.test(value)) {
        callback(new Error('请输入正确的临界值金额'))
      } else {
        callback()
      }
    }

    let formRuleSubsist = (rule, value, callback) => {
      const regExp = /^\d+$|^\d+\.?\d{1,2}$/g
      if (!regExp.test(value)) {
        callback(new Error('请输入大于等于0的预付款金额，且最多两位小数'))
      } else {
        if (Number(value) > 0) {
          callback()
        } else {
          callback(new Error('请输入大于0的预付款金额'))
        }
      }
    }

    return {
      commissionLoading: false,
      thresholdLoading: false,
      payLoading: false,
      commissionPercentage: {},
      subsist: {},
      threshold: {},
      commissionDialog: false,
      thresholdDialog: false,
      subsistDialog: false,
      subsistForm: {
        subsist: ''
      },
      commissionForm: {
        tutorBrokerageScale: '',
        tutorReferenceBrokerageScale: '',
        parentReferenceBrokerageScale: ''
      },
      subsistRules: {
        subsist: { validator: formRuleSubsist, trigger: 'blur' }
      },
      commissionRules: {
        tutorBrokerageScale: { validator: formRule, trigger: 'blur' },
        tutorReferenceBrokerageScale: { validator: formRule, trigger: 'blur' },
        parentReferenceBrokerageScale: { validator: formRule, trigger: 'blur' }
      },
      thresholdForm: {
        cashCriticalValue: ''
      },
      thresholdRules: {
        cashCriticalValue: { validator: thresholdRule, trigger: 'blur' }
      }
    }
  },
  mounted () {
    this.getCommissionSetting()
    this.getThresholdSetting()
    this.getPrePay()
  },
  methods: {
    // 获取订单预付款金额
    async getPrePay () {
      this.payLoading = true
      let res = {}
      try {
        res = await this.$api.setting.getPay()
      } catch (err) {
        this.payLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.subsist = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.payLoading = false
    },
    // 获取分佣设置
    async getCommissionSetting () {
      this.commissionLoading = true
      let res = {}
      try {
        res = await this.$api.setting.getCommissionSetting()
      } catch (err) {
        this.commissionLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.commissionPercentage = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.commissionLoading = false
    },
    // 获取提现临界值
    async getThresholdSetting () {
      this.thresholdLoading = true
      let res = {}
      try {
        res = await this.$api.setting.getThresholdSetting()
      } catch (err) {
        this.thresholdLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.threshold = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.thresholdLoading = false
    },
    // 点击输入框时全部选择
    getInputFocus (event) {
      event.currentTarget.select()
    },
    // 打开分佣设置时自动填充现有分佣比例
    openCommissionDialog () {
      this.commissionForm = {
        tutorBrokerageScale: this.commissionPercentage.tutorBrokerageScale,
        tutorReferenceBrokerageScale: this.commissionPercentage.tutorReferenceBrokerageScale,
        parentReferenceBrokerageScale: this.commissionPercentage.parentReferenceBrokerageScale
      }
      this.commissionDialog = true
    },
    openSubsistDialog () {
      this.subsistForm = {
        subsist: this.subsist.subsist
      }
      this.subsistDialog = true
    },
    // 打开提现临界值设置时自动填充现有临界值
    openThresholdDialog () {
      this.thresholdForm = {
        cashCriticalValue: this.threshold.cashCriticalValue.toFixed(2)
      }
      this.thresholdDialog = true
    },
    // 校验佣金设置
    submitCommission () {
      this.$refs.commissionForm.validate((valid) => {
        if (valid) {
          this.setCommissionPercentage()
        } else {
          return false
        }
      })
    },
    submitThreshold () {
      this.$refs.thresholdForm.validate((valid) => {
        if (valid) {
          this.setThreshold()
        } else {
          return false
        }
      })
    },
    submitSubsist () {
      this.$refs.subsistForm.validate((valid) => {
        if (valid) {
          this.setSubsist()
        } else {
          return false
        }
      })
    },

    // 总百分比校验（多个输入联动）
    checkCommission () {
      Object.keys(this.commissionForm).forEach(key => {
        this.$refs.commissionForm.validateField(key, error => {
          if (!error) {
          } else {
            return false
          }
        })
      })
    },
    // 获取当前输入的总百分比
    getTotalPercentage () {
      let sum = 0
      Object.keys(this.commissionForm).forEach(key => {
        if (!isNaN(this.commissionForm[key])) {
          sum += Number(this.commissionForm[key])
        }
      })
      return sum
    },
    // 设置佣金
    async setCommissionPercentage () {
      let res = {}
      try {
        res = await this.$api.setting.setCommissionPercentage({
          tutorBrokerageScale: this.commissionForm.tutorBrokerageScale,
          tutorReferenceBrokerageScale: this.commissionForm.tutorReferenceBrokerageScale,
          parentReferenceBrokerageScale: this.commissionForm.parentReferenceBrokerageScale
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '订单佣金设置成功'
        })
        this.commissionDialog = false
        this.getCommissionSetting()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    // 设置临界值
    async setThreshold () {
      let res = {}
      try {
        res = await this.$api.setting.setThreshold({
          cashCriticalValue: this.thresholdForm.cashCriticalValue
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '临界值设置成功'
        })
        this.thresholdDialog = false
        this.getThresholdSetting()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async setSubsist () {
      let res = {}
      try {
        res = await this.$api.setting.setPay({
          subsist: this.subsistForm.subsist
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '预付款金额设置成功'
        })
        this.subsistDialog = false
        this.getPrePay()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    }
  }
}
</script>

<style lang="scss">
.el-dialog {
  .el-dialog__header {
    padding: 16px 20px;
    border-bottom: $--header-border;
    .el-dialog__title {
      font-size: $--font-size-medium;
    }
    .el-dialog__headerbtn {
      font-size: $--font-size-medium;
      line-height: 21px;
      .el-dialog__close:before {
        color: $--color-text-regular;
      }
    }
  }
}
.dialog {
  border-radius: $--border-radius-base;
}
</style>

<style lang="scss" scoped>
.operation-setting {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    // padding: 16px 32px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    .detail-item {
      color: $--color-text-primary;
      // padding: 24px 0 51px;
      padding: 0 0 18px;
      border-bottom: $--header-border;
      &:last-child {
        border-bottom: none;
      }
      .detail-item-title {
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        margin-bottom: 12px;
        border-bottom: $--header-border;
        font-size: 16px;
        font-weight: 600;
      }
      .detail-item-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .info-item {
          width: 50%;
          font-size: 14px;
          margin-top: 22px;
          padding-right: 16px;
          &.teaching {
            width: 50%;
          }
          .arr {
            display: inline-block;
            white-space: pre;
          }
        }
      }
    }
  }
  .commission-form {
    ::v-deep.el-form-item {
      color: $--color-text-primary;
      &__label {
        color: $--color-text-primary;
      }
      &__content {
        .el-input {
          margin-right: 8px;
          width: 100px;
        }
        .el-form-item__error {
          margin-left: 96px;
        }
      }
    }
  }
  .threshold-form {
    ::v-deep.el-form-item {
      color: $--color-text-primary;
      &__label {
        color: $--color-text-primary;
      }
      &__content {
        .el-input {
          margin-right: 8px;
          width: 100px;
        }
        .el-form-item__error {
          margin-left: 55px;
        }
      }
    }
  }
  .threshold {
    .footer {
      margin-left: 55px;
    }
  }
  .footer {
    margin-top: 32px;
    margin-left: 96px;
    ::v-deep.el-button {
      width: 72px;
      & + .el-button {
        margin-left: 24px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .operation-setting {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .operation-setting {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 100%;
            &.teaching {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
