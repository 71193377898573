<template>
  <div class="ticket-type">
    <!-- 三级导航需要从二级导航接入 -->
    <router-view></router-view>
    <!-- 通过自定义$route.meta.menuShow控制跳转至三级导航时，二级导航内容不显示 -->
    <div class="ticket-type-part"
         v-if="$route.meta.menuShow">
      <div class="search-bar block">
        <el-input v-model="formInput"
                  class="search-input"
                  placeholder="搜索票券类型"
                  prefix-icon="el-icon-search"
                  clearable
                  @keyup.enter.native="handleSearch">
        </el-input>
        <div class="button-box">
          <el-button @click="handleSearch"
                     type="primary">搜索</el-button>
          <el-button @click="resetForm()" plain>重置</el-button>
        </div>
      </div>
      <div>
        <ym-table-list stripe
                       :loading="loading"
                       :headData="headData"
                       :data="listData"
                       :columns="listColumns"
                       :pageData="pageData"
                       @handlePageChange="handlePageChange">
          <template #createTime="{row}">
            {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
          </template>
        </ym-table-list>
      </div>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      loading: false,
      search: '', // 数据搜索项
      formInput: '', // 用户表单绑定项
      headData: {
        title: '票券类型',
        items: [
          {
            style: 'button',
            label: '新增',
            icon: 'ym-icon-tianjia',
            size: 'small',
            type: 'primary',
            clickEvent: (thisVue) => {
              thisVue.$router.push('/ticket/type/addition')
            }
          }
        ]
      },
      listData: [
        {
          typeId: '1366580038778880002',
          typeName: '票券一',
          description: '这是票券一',
          createTime: '1614653045731'
        }
      ],
      listColumns: [
        {
          label: '类型名称',
          prop: 'typeName',
          show: true,
          align: 'center'
        },
        {
          label: '描述',
          prop: 'description',
          show: true,
          showOverflowTooltip: true,
          align: 'center'
        },
        {
          label: '创建时间',
          prop: 'createTime',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          align: 'center',
          children: {
            edit: {
              label: '编辑',
              icon: 'ym-icon-bianji',
              clickEvent: (thisVue, row) => {
                this.$router.push({ name: 'ticket-type-edit', params: row })
              }
            },
            delete: {
              label: '删除',
              icon: 'ym-icon-shanchu',
              color: '#FF8851',
              clickEvent: (thisVue, row) => {
                thisVue.$confirm('此操作将永久删除该票券类型, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  thisVue.$api.tickets.deleteTicketType({
                    typeId: row.typeId
                  }).then(res => {
                    if (res.data.code === 0) {
                      thisVue.$message({
                        type: 'success',
                        message: '删除成功！'
                      })
                      thisVue.getList()
                    } else {
                      thisVue.$message({
                        type: 'error',
                        message: res.data.message
                      })
                    }
                  }).catch(err => {
                    thisVue.$message({
                      type: 'error',
                      message: err
                    })
                  })
                }).catch(() => {

                })
              }
            }
          }
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    '$route.meta.menuShow'() {
      if (this.$route.meta.menuShow) {
        this.getList()
      }
    }
  },
  methods: {
    handleSearch() {
      this.headData.title = '显示票券类型为: “' + this.formInput + '” 的搜索结果'
      this.$set(this.pageData, 'currentPage', 1)
      this.search = this.formInput
      this.formInput = ''
      this.getList()
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      this.getList()
    },
    // 重置搜索框
    resetForm(id) {
      this.formInput = ''
      this.search = ''
      this.headData.title = '票券类型'
      this.getList()
    },
    async getList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.tickets.getTicketTypeList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          typeName: this.search
        })
      } catch (err) {
        this.loading = false
        return new Error(err)
      }
      this.loading = false
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket-type {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 20px 24px;
    margin-bottom: 20px;
  }
  .search-bar {
    display: flex;
    ::v-deep .search-input {
      width: 350px;
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
  }
}
</style>
