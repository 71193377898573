<template>
  <!-- 自适应宽度 -->
  <el-row :justify="justify" type="flex" class="ym-form">
    <el-col :span="layout.span" :xl="layout.xl" :lg="layout.lg" :md="layout.md">

      <!-- 表单对象 -->
      <el-form ref="form"
        v-if="form"
        :model="form"
        :label-width="labelWidth"
        :size="size"
        :label-position="labelPosition"
        :label-suffix="labelSuffix"
        :inline="inline"
        :disabled="formDisabled">
        <template v-for="(groupData,groupIndex) in formData">
          <!-- 表单分组 -->
          <formGroup :groupData="groupData" :formData=formData :form="form" :key="groupIndex">
            <template :slot="slotName" v-for="slotName in groupData.slotArr">
              <slot :name="slotName"></slot>
            </template>
          </formGroup>
        </template>
      </el-form>
      <!-- 提交表单在父组件调用onSubmit方法 -->
      <!-- <el-button @click="onSubmit">提交</el-button> -->

    </el-col>
  </el-row>
</template>

<script>
// 响应式宽度工具
import calculateLayout from '../../utils/calculateLayout'

// 表单组
import formGroup from './formGroup'

export default {
  props: {

    refCode: {
      type: String,
      default: 'ymForm'
    },

    // 表单位置,start/end/center
    justify: {
      type: String,
      default: 'start'
    },

    // 表单域标签的位置，如果值为 left 或者 right 时，则需要设置 label-width, 可选 right/left/top
    labelPosition: {
      type: String,
      default: 'right'
    },

    // 用于控制该表单内组件的尺寸, 可选 medium / small / mini
    formSize: {
      type: String,
      default: 'medium'
    },

    // 表单域标签的宽度，例如 '100px'。作为 Form 直接子元素的 form-item 会继承该值。支持 auto。
    labelWidth: {
      type: String,
      default: 'auto'
    },

    // 表单域标签的后缀
    labelSuffix: {
      type: String
    },

    // 用于控制该表单内组件的尺寸 medium / small / mini
    size: {
      type: String,
      default: 'medium'
    },

    // 表单整体禁用, 若设置为 true，则表单内组件上的 disabled 属性不再生效
    formDisabled: {
      type: Boolean,
      default: false
    },

    // 表单图标动画, 需要表单项有图标 rotate旋转/ lateralOscillation横向摆动/ rotatingVibration旋转摆动
    iconAnimation: {
      type: String,
      default: 'none'
    },

    // 行内/横向表单模式
    inline: {
      type: Boolean,
      default: false
    },

    // 表单项渲染数据,表单根据此数组配置进行渲染
    formData: {
      type: Array,
      default: () => {
        return []
      }
    },

    // 表单值数据,表单初始值/更新值根据此对象进行渲染
    formValue: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 默认响应格栅数
    span: {
      type: [String, Number]
    },

    // ≥1920px 响应式栅格数
    xl: {
      type: [String, Number]
    },

    // ≥1200px 响应式栅格数
    lg: {
      type: [String, Number]
    },

    // ≥992px 响应式栅格数
    md: {
      type: [String, Number]
    }
  },
  data() {
    return {
      layout: {}, // 响应大小
      form: {}
    }
  },
  computed: {
    // 筛选form,去掉表单数据中的非法值
    submitForm: function () {
      // 去掉表单对象中的undefined属性和空列表
      for (let key in this.form) {
        if (this.form[key] === undefined) {
          delete this.form[key]
          continue
        }
        if (Array.isArray(this.form[key]) && this.form[key].length === 0) {
          delete this.form[key]
        }
      }
      // 去掉配置项中的hidden属性对应的表单值
      for (let groupIndex in this.formData) {
        let items = this.formData[groupIndex].items
        for (let key in items) {
          if (items[key].hidden) {
            delete this.form[key]
          }
        }
      }
      return this.form
    }
  },
  components: {
    formGroup
  },
  watch: {
    formData: 'initFormValueByFormData',

    // 传入form时及时回显表单数据
    formValue: {
      handler: 'updateFormValue',
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.$set(this, 'layout', calculateLayout.completionAttribute(this.span, this.xl, this.lg, this.md))
    // 调用子组件方法进行默认值的初始化
    this.initFormValueByFormData()
  },
  methods: {
    setColumns(data) {
      console.log(data)
    },

    // 移除表单项的校验结果。传入待移除的表单项的 prop 属性或者 prop 组成的数组，如不传则移除整个表单的校验结果
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop)
    },

    // 更新表单值
    updateFormValue() {
      this.$set(this, 'form', this.formValue)
    },

    // 提交事件
    onSubmit(methodName) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log('校验通过')
          this.$emit('onSubmit', this.submitForm, this.refCode)
        } else {
          console.log('校验不通过')
          return false
        }
      })
    },

    // 初始化表单, 给单选/多选赋予checked=true属性
    initFormValueByFormData() {
      let valueObj = {}
      for (let i = 0; i < this.formData.length; i++) {
        var formItems = this.formData[i].items
        for (var key in formItems) {
          switch (formItems[key].type) {
            case 'radio': initRadiobox(formItems[key].children); break
            case 'checkbox': initCheckbox(formItems[key].children); break
            case 'inputSelect': initInputSelect(formItems[key]); break
            default: initInputValue(formItems)
          }
        }
      }
      // 初始化多选框
      function initCheckbox(children) {
        valueObj[key] = []
        for (let child in children) {
          if (children[child].checked) {
            valueObj[key].push(children[child].value)
          }
        }
      }
      // 初始化单选框
      function initRadiobox(children) {
        for (let child in children) {
          if (children[child].checked) {
            valueObj[key] = children[child].value
            break
          }
        }
      }

      // 初始化复合输入框
      function initInputSelect(formItems) {
        let model = formItems.selectModel
        valueObj[model] = formItems.select.children[0].value
      }

      function initInputValue(formItems) {
        valueObj[key] = formItems[key].value
      }
      this.$set(this, 'form', valueObj)
      this.updateFormValue()
    }

  }
}
</script>

<style lang="scss">
.ym-form {
  .ym-form-title {
    margin: 15px 0;
  }

  .rate-input {
    transform: translateY(8px);
  }

  .el-range-separator {
    padding: 0;
  }
}

i {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
</style>
