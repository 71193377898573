<template>
  <div class="ticket-list-addition">
    <div class="cover">
      <h5 class="ym-title">新增票券</h5>
      <ym-form ref="ticket"
               class="form"
               :formValue="formValue"
               :formData="formData"
               labelPosition="top"
               @onSubmit="handleSubmit"></ym-form>
      <div class="bottom">
        <el-button type="primary"
                   @click="checkForm">新增</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import YmForm from '@/components/YmForm'

export default {
  components: {
    YmForm
  },
  mounted() {
    this.getTypeList(this.handleTypeList)
  },
  data() {
    return {
      typeList: [],
      formValue: { quantity: 1 },
      formData: [{
        items: {
          ticketName: {
            label: '票券名称',
            type: 'text',
            rules: [
              {
                validator: function (rule, value, callback) {
                  if (!value) {
                    return callback(new Error('票券名称不能为空'))
                  }
                  return callback()
                }
              }
            ]
          },
          quantity: {
            label: '票券数量',
            type: 'number',
            min: 1,
            rules: [{
              validator: function (rule, value, callback) {
                if (!value) {
                  return callback(new Error('票券数量不能为空'))
                }
                return callback()
              }
            }]
          },
          typeId: {
            label: '票券类型',
            type: 'select',
            filterable: true,
            children: [],
            rules: [{
              validator: function (rule, value, callback) {
                if (!value) {
                  return callback(new Error('所属票券类型不能为空'))
                }
                return callback()
              }
            }]
          },
          expiry: {
            label: '有效期',
            type: 'date',
            style: 'daterange',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now() - 8.64e7
              }
            },
            rules: [{
              validator: function (rule, value, callback) {
                if (!value) {
                  return callback(new Error('请选择有效日期'))
                } else {
                  for (let d of value) {
                    if (!d) {
                      return callback(new Error('请选择有效日期'))
                    }
                  }
                }
                return callback()
              },
              trigger: 'blur'
            }]
          },
          description: {
            label: '票券描述',
            type: 'textarea',
            row: '3'
          }
        }
      }],
      pickerOptions: {
        // 有效期不能选择当天之前的时间
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      }
    }
  },
  methods: {
    async getTypeList(func) {
      let res = {}
      try {
        res = await this.$api.tickets.getAllTicketTypeList()
      } catch (err) {
        return new Error(err)
      }
      if (res.data.code === 0) {
        this.typeList = res.data.data
        if (typeof func === 'function') {
          func()
        }
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    handleTypeList() {
      if (this.typeList.length !== 0) {
        const list = this.typeList.map(item => {
          return {
            label: item.typeName,
            value: item.typeId
          }
        })
        this.typeList = list
        this.$set(this.formData[0].items['typeId'], 'children', this.typeList)
      }
    },
    async handleSubmit() {
      let res = {}
      const params = {
        ticketName: this.formValue.ticketName,
        description: this.formValue.description,
        typeId: this.formValue.typeId,
        quantity: this.formValue.quantity,
        beginTime: Date.parse(this.setDateStart(this.formValue.expiry[0])),
        endTime: Date.parse(this.setDateEnd(this.formValue.expiry[1]))
      }
      try {
        res = await this.$api.tickets.createTicket(params)
      } catch (err) {
        return new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: res.data.message
        })
        this.$router.push('/ticket/list')
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    checkForm() {
      this.$refs.ticket.onSubmit()
    },
    handleCancel() {
      this.$router.go(-1)
    },
    setDateStart(d) {
      d.setHours(0)
      d.setMinutes(0)
      d.setSeconds(0)
      return d
    },
    setDateEnd(d) {
      d.setHours(23)
      d.setMinutes(59)
      d.setSeconds(59)
      return d
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket-list-addition {
  min-width: 440px;
  background-color: $--header-bg-color;
  border: $--header-border;
  border-radius: $--border-radius-base;
  padding: 20px 24px 43px;
  margin-bottom: 20px;
  .ym-title {
    margin-top: 20px;
  }
  ::v-deep .form {
    min-width: 500px;
    width: 500px;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    width: 500px;
    min-width: 500px;
  }
}
</style>
