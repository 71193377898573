<template>
  <div class="reservation-detail">
    <div v-loading="infoLoading">
      <div class="detail block">
        <div class="detail-item form-info">
          <div class="detail-item-title basic-info">
            <span>基本信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>订单号：</span>
              <span>{{(basicInfo && basicInfo.orderId) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>标题：</span>
              <span>{{(basicInfo && basicInfo.title) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>姓名：</span>
              <span>{{(basicInfo && basicInfo.name) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>学校：</span>
              <span>{{(basicInfo && basicInfo.school) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>年级：</span>
              <span>{{(basicInfo && basicInfo.grade) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>性别：</span>
              <template v-if="basicInfo && (basicInfo.sex || basicInfo.sex === 0)">
                <span>{{basicInfo.sex === 0 ? '女' : '男'}}</span>
              </template>
              <template v-else>
                <span>--</span>
              </template>
            </div>
            <div class="info-item">
              <span>服务地点：</span>
              <template v-if="basicInfo && [basicInfo.city || '', basicInfo.area || '', basicInfo.address || ''].join(' ')">
                <span>{{ [basicInfo.city || '', basicInfo.area || '', basicInfo.address || ''].join(' ') }}</span>
              </template>
              <template v-else>
                <span>--</span>
              </template>
            </div>
            <div class="info-item">
              <span>家长账号：</span>
              <span>{{(basicInfo && basicInfo.parentPhone) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>创建时间：</span>
              <span>{{ basicInfo.createTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}</span>
            </div>
            <div class="info-item">
              <span>订单状态：</span>
              <el-select v-model="form.authStatus" size="mini" class="info-item-status" :disabled="basicInfo.authStatus === 3">
                <el-option label="审核中" :value="2"></el-option>
                <el-option label="审核成功" :value="3"></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="detail-item form-info payment">
          <div class="detail-item-title">
            <span>服务信息</span>
          </div>
          <div class="detail-item-info">
            <div style="width: 100%;">
              <el-form ref="form"
                        :model="form"
                        label-suffix="："
                        label-position="right"
                        label-width="120px"
                        :rules="rules"
                        :disabled="basicInfo.authStatus === 3">
                <el-form-item label="期望服务日期" class="form-info-item expected-date info-input" prop="dateRange">
                  <el-date-picker v-model="form.dateRange"
                                  type="daterange"
                                  size="mini"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  :default-time="['00:00:00', '23:59:59']"
                                  @change="test"></el-date-picker>
                </el-form-item>
                <el-form-item label="服务时段" class="form-info-item expected-time info-input" prop="timeRange">
                  <el-time-picker v-model="form.timeRange"
                                  size="mini"
                                  is-range
                                  value-format='HH:mm'
                                  format='HH:mm'
                                  arrow-control
                                  ></el-time-picker>
                </el-form-item>

                <el-form-item label="服务类型" class="form-info-item">
                  <span>{{(basicInfo && basicInfo.stage) || '--'}}</span>
                </el-form-item>
                <el-form-item label="服务项目" class="form-info-item">
                  <span>{{(basicInfo && basicInfo.subject) || '--'}}</span>
                </el-form-item>

                <el-form-item prop="totalClass" label="预计次数" class="form-info-item info-input expected-total-class">
                  <el-input v-model="form.totalClass" size="mini" style="width: 100px; margin-right: 10px;"></el-input>
                  <span>次</span>
                </el-form-item>
                <el-form-item prop="price" label="单价" class="form-info-item info-input">
                  <span>{{ form.price || '--' }}元/次</span>
                </el-form-item>
                <el-form-item label="总价" class="form-info-item">
                  <template v-if="isNaN(form.totalClass * form.price)">
                    <span>0元</span>
                  </template>
                  <template v-else>
                    <span>{{(form.totalClass * form.price).toFixed(2)}}元</span>
                  </template>
                </el-form-item>
                <el-form-item label="预付" class="form-info-item">
                  <template v-if="basicInfo && (basicInfo.subsist || basicInfo.subsist === 0)">
                    <span>{{basicInfo.subsist.toFixed(2) + '元'}}</span>
                  </template>
                  <template v-else>--</template>
                </el-form-item>
                <el-form-item label="已付总费用" class="form-info-item actual-payed">
                  <template v-if="basicInfo && (basicInfo.actualPayment || basicInfo.actualPayment === 0)">
                    <span v-if="basicInfo.actualPayment !== 0">{{basicInfo.actualPayment.toFixed(2) + '元'}}</span>
                    <span v-else>{{basicInfo.actualPayment + '元'}}</span>
                  </template>
                  <template v-else>--</template>
                </el-form-item>

                <el-form-item label="备注" class="form-info-item">
                  <span>{{(basicInfo && basicInfo.remark) || '--'}}</span>
                </el-form-item>
              </el-form>
              <div class="btn-box" style="text-align: center;" v-if="basicInfo.authStatus === 2">
                <el-button class="line-btn" size="small" @click="handleCancel">取消</el-button>
                <el-button type="primary" size="small" class="update-btn" @click="handleUpdate">保存</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>操作日志</span>
          </div>
          <div class="detail-item-table">
            <ym-table-list :loading="loading"
                           :data="listData"
                           :columns="listColumns"
                           :pageData="pageData"
                           :tableOption="tableOption"
                           @handlePageChange="handlePageChange">
              <template #time={row}>
                {{row.time | timeFilter('YYYY.MM.DD HH:mm:ss') }}
              </template>
            </ym-table-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import { _session } from '@/utils/localStorage.js'

export default {
  components: {
    YmTableList
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'reservationList' && to.params.editMark) {
      // 限制只能通过点击预约订单的编辑进入，单击进入才会传editMark
      _session.set('editMark', true)
      next()
    } else if (_session.get('editMark')) {
      // 用于已经进入编辑页面后，刷新页面时能够保持当前页面
      next()
    } else {
      next({ name: 'reservationList' })
    }
  },
  beforeRouteLeave(to, from, next) {
    _session.remove('editMark')
    next()
  },
  beforeDestroy() {
    _session.remove('editMark')
  },
  mounted() {
    this.orderId = this.$route.params.orderId
    this.getOrderDetail()
  },
  data() {
    let checkTime = (rule, value, callback) => {
      console.log(value)
      if (!value || !value[0] || !value[1]) {
        callback(new Error('请选择期望服务日期'))
      } else {
        callback()
      }
    }

    let checkTotalClass = (rule, value, callback) => {
      const regExp = /^[1-9]$|^[1-9][0-9]*$/
      if (!value) {
        callback(new Error('请输入课时'))
      } else if (!regExp.test(value)) {
        callback(new Error('只能输入大于0的整数'))
      } else if (value > 10000) {
        callback(new Error('最大课时不能超过10000'))
      } else {
        callback()
      }
    }

    let checkPrice = (rule, value, callback) => {
      const regExp = /^0\.([1-9])$|^0\.(0[1-9])$|^0\.([1-9][0-9])$|^[1-9][0-9]*\.[0-9][0-9]?$|^[1-9][0-9]*$/
      const total = value * this.totalClass
      console.log(total)
      if (!value && value !== 0) {
        callback(new Error('请输入价格'))
      } else if (Number(value) === 0) {
        callback(new Error('单价不能为0'))
      } else if (!regExp.test(value)) {
        callback(new Error('单价输入格式错误'))
      } else if (value < window.MIN_PRICE) {
        callback(new Error('单价不能低于' + window.MIN_PRICE + '元'))
      } else if (value > 10000) {
        callback(new Error('单价不能超过10000元'))
      } else {
        callback()
      }
    }

    return {
      jumpFlag: false,
      infoLoading: false,
      loading: false,
      orderId: '',
      basicInfo: {},
      form: {
        authStatus: '',
        dateRange: ['2021-05-31', '2021-05-31'],
        timeRange: ['09:00', '18:00'],
        totalClass: '',
        price: ''
      },
      timePickerOptions: {
        format: 'HH:mm'
      },
      rules: {
        dateRange: { validator: checkTime, trigger: 'blur' },
        timeRange: { validator: checkTime, trigger: 'blur' },
        totalClass: { validator: checkTotalClass, trigger: ['change', 'blur'] },
        price: { validator: checkPrice, trigger: ['change', 'blur'] }
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          label: '角色',
          prop: 'userRoleName',
          show: true,
          align: 'center'
        },
        {
          label: '操作记录',
          prop: 'operationDetail',
          show: true,
          align: 'center'
          // enableSlot: true
        },
        {
          label: '时间',
          prop: 'time',
          show: true,
          align: 'center',
          enableSlot: true
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  methods: {
    test() {
      console.log(this.form)
    },
    async getOrderDetail() {
      this.infoLoading = true
      let res = {}
      try {
        res = await this.$api.order.getOrderDetail({
          orderId: this.orderId
        })
      } catch (err) {
        this.infoLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.basicInfo = res.data.data
        this.$set(this.form, 'authStatus', this.basicInfo.authStatus)
        this.$set(this.form, 'dateRange', [new Date(this.basicInfo.startDate), new Date(this.basicInfo.endDate)])
        this.$set(this.form, 'timeRange', [this.basicInfo.startTime || '09:00', this.basicInfo.endTime || '18:00'])
        this.$set(this.form, 'totalClass', this.basicInfo.totalClass)
        this.$set(this.form, 'price', this.basicInfo.salary)
        console.log(this.form)
        this.getLog()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.infoLoading = false
    },
    handleUpdate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.authStatus === 3) {
            this.$confirm('订单状态更新为“审核成功”后，将无法编辑订单，用户进行付尾款，是否继续操作？', '提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              customClass: 'confirm-box',
              cancelButtonClass: 'line-btn',
              type: 'warning'
            }).then(() => {
              this.updateOrder()
            }).catch(() => { })
          } else {
            this.updateOrder()
          }
        } else {
          return false
        }
      })
    },
    handleCancel() {
      this.$router.replace({ name: 'reservationList' })
    },
    async updateOrder() {
      let res = {}
      try {
        res = await this.$api.order.updateOrder({
          orderId: this.basicInfo.orderId,
          authStatus: this.form.authStatus,
          startDate: Date.parse(this.form.dateRange[0]),
          endDate: Date.parse(this.form.dateRange[1]),
          startTime: this.form.timeRange[0],
          endTime: this.form.timeRange[1],
          totalClass: this.form.totalClass,
          salary: this.form.price,
          totalSalary: this.form.totalClass * this.form.price
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '订单更新成功'
        })
        if (this.form.authStatus === 3) {
          this.$set(this.basicInfo, 'authStatus', 3)
        }
        this.getLog()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async getLog() {
      this.loading = true
      let res = {}
      try {
        res = await this.$api.order.getLog({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          orderId: this.basicInfo.orderId
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getLog()
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-detail {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    // padding: 16px 32px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    .detail-item {
      color: $--color-text-primary;
      // padding: 24px 0 51px;
      padding: 0 0 18px;
      border-bottom: $--header-border;
      &.form-info {
        border-bottom: none;
        padding: 0;
        .detail-item-title {
          &.basic-info {
            padding-bottom: 0;
          }
          margin-bottom: 0;
          padding-top: 24px;
          border-bottom: none;
          font-weight: 600;
        }
        .detail-item-info {
          padding: 0 0 32px;
          margin: 0 24px;
          border-bottom: $--header-border;
        }
        &:last-child {
          .detail-item-info {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
      &.payment {
        padding-bottom: 0;
      }
      &:last-child {
        border-bottom: none;
      }
      .detail-item-title {
        padding: 16px 24px;
        margin-bottom: 12px;
        border-bottom: $--header-border;
        font-size: 16px;
        font-weight: 600;
      }
      .detail-item-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .info-item {
          width: 33.33%;
          font-size: 14px;
          margin-top: 20px;
          padding-right: 16px;
          ::v-deep.info-item-status {
            width: 102px;
          }
        }
        ::v-deep.el-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.33%;
            font-size: 14px;
            margin-bottom: 8px;
            &.expected-date {
              .el-form-item__label {
                width: 98px !important;
              }
              .el-date-editor--daterange {
                &.el-input__inner {
                  width: 216px;
                }
                .el-range-input {
                  width: 70px;
                }
                .el-range-separator {
                  width: 24px;
                }
                &+.el-form-item__error {
                  left: 56px;
                }
              }
            }
            &.expected-time {
              .el-form-item__label {
                width: 70px !important;
              }
              .el-date-editor--timerange {
                &.el-input__inner {
                  width: 216px;
                }
                .el-range-input {
                  width: 70px;
                }
                .el-range-separator {
                  width: 24px;
                }
                &+.el-form-item__error {
                  left: 28px;
                }
              }
            }
            &.expected-total-class {
              .el-form-item__label {
                width: 70px !important;
              }
              .el-form-item__error {
                left: 29px;
              }
            }
            &__label {
              padding: 0;
              color: $--color-text-primary;
            }
            .el-input {
              width: 80px;
              margin-right: 8px;
            }
            .el-form-item__error {
              padding-top: 0;
            }
            &.actual-payed {
              .el-form-item__label {
                width: 84px !important;
              }
            }
          }
        }
        ::v-deep.btn-box {
          width: 100%;
          text-align: center;
          .el-button {
            width: 100px;
            &+.el-button {
              margin-left: 72px;
            }
          }
        }
      }
      .detail-item-table {
        margin-top: 22px;
        padding: 0 24px;
        // width: 400px;
      }
      .select-item {
        margin-top: 16px;
        .label {
          display: inline-block;
          width: 56px;
          margin-right: 12px;
          font-size: $--font-size-base;
          text-align: right;
        }
        ::v-deep.select {
          width: 180px;
          margin-right: 20px;
          .el-input__inner {
            line-height: 33px;
          }
        }
      }
    }
    ::v-deep .ym-table {
      .ym-table-header {
        margin-bottom: 20px;
      }
      .ym-table-bottom {
        // padding: 19px 32px;
      }
      .el-table {
        .el-table__header {
          thead tr th {
            padding: 16px 0;
          }
        }
        .el-table__body {
          td {
            height: 56px;
          }
          div {
            color: $--color-text-primary;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1315px) {
  .reservation-detail {
    .block {
      .detail-item.payment {
        .detail-item-info {
          ::v-deep.el-form-item {
            width: 50% !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1012px) {
  .reservation-detail {
    .block {
      .detail-item.payment {
        .detail-item-info {
          ::v-deep.el-form-item {
            width: 100% !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .reservation-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .reservation-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 100%;
          }
          ::v-deep.el-form-item {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
