<template>
    <div class="operation-plan">
    <div class="search-bar block">
      <div class="label-input">
        <div class="label">套餐类别</div>
        <el-select v-model="planType" placeholder="请选择套餐类别" size="small" @change="handleSearch">
          <el-option
            v-for="t in typeList"
            :key="t.packageTypeId"
            :label="t.packageTypeName"
            :value="t.packageTypeId">
          </el-option>
        </el-select>
      </div>
      <!-- <div class="label-input">
        <div class="label">上下架</div>
        <el-select v-model="status" placeholder="请选择套餐状态" size="small">
          <el-option
            v-for="s in statusList"
            :key="s.value"
            :label="s.label"
            :value="s.value">
          </el-option>
        </el-select>
      </div> -->
      <!-- <div class="button-box">
        <el-button @click="handleSearch"
                   size="small"
                   type="primary">查询</el-button>
        <el-button @click="resetForm()"
                   size="small"
                   class="line-btn"
                   plain>重置</el-button>
      </div> -->
    </div>
    <div class="control-bar block">
      <!-- <span class="title"></span> -->
      <el-button @click="handleCreatePlan"
                 size="small"
                 type="primary">新增</el-button>
    </div>
    <div>
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #price="{row}">
          {{row.price.toFixed(2)}}
        </template>
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
        <template #desc="{row}">
          <template v-if="row.desc">
            <el-tooltip class="item" effect="dark" :content="row.desc" placement="top">
              <text-line :text="row.desc" :clamp="3"></text-line>
            </el-tooltip>
          </template>
          <template v-else>--</template>
        </template>
        <!-- <template #stock="{row}">
          <div v-if="!row.isEdit" class="stock-col">
            <span>{{row.stock}}</span><span class="edit" @click="handleEditStock(row)">修改</span>
          </div>
          <div class="edit-part" v-else>
            <el-input v-focus v-model="row.stock" size="mini" @keyup.enter.native="handleSaveStock(row)" @blur="handleSaveStock(row)"></el-input>
            <span class="edit" @click="handleSaveStock(row)">保存</span>
          </div>
        </template> -->
        <!-- <template #status="{row}">
          <el-switch
            v-model="row.status"
            active-text="上架"
            inactive-text="下架"
            class="status-switch"
            :width=56
            :disabled="switchLoading[row.id]"
            @change="(val) => handleStatusChange(val, row)"></el-switch>
        </template> -->
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import TextLine from '@/components/TextLine'

export default {
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector('input').focus()
      }
    }
  },
  components: {
    YmTableList,
    TextLine
  },
  data() {
    return {
      loading: false,
      switchLoading: {},
      showStock: {},
      oldStock: {},
      planType: 0,
      // status: 0,
      typeList: [],
      // statusList: [
      //   {
      //     label: '全部',
      //     value: 0
      //   },
      //   {
      //     label: '上架',
      //     value: 1
      //   },
      //   {
      //     label: '下架',
      //     value: 2
      //   }
      // ],
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '套餐ID',
          prop: 'packageId',
          show: true
          // align: 'center'
        },
        {
          label: '套餐类别',
          prop: 'packageTypeName',
          show: true
          // showOverflowTooltip: true,
          // align: 'center'
        },
        {
          label: '套餐名称',
          prop: 'packageName',
          show: true
          // width: 160
        },
        {
          label: '价格',
          prop: 'price',
          show: true,
          enableSlot: true
        },
        {
          label: '免费测评份数',
          prop: 'freeQuantity',
          show: true,
          width: 120
          // align: 'center'
        },
        {
          label: '有效期',
          prop: 'effectiveDays',
          show: true,
          width: 80
          // align: 'center'
        },
        {
          label: '产品说明',
          prop: 'desc',
          show: true,
          enableSlot: true
          // width: 160
          // align: 'center'
        },
        // {
        //   label: '库存',
        //   prop: 'stock',
        //   show: false,
        //   width: 160,
        //   enableSlot: true
        //   // align: 'center'
        // },
        // {
        //   label: '上下架',
        //   prop: 'status',
        //   show: false,
        //   width: 120,
        //   enableSlot: true
        //   // align: 'center'
        // },
        {
          label: '创建时间',
          prop: 'createTime',
          show: true,
          width: 160,
          // align: 'center'
          enableSlot: true
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 110,
          align: 'right',
          children: {
            edit: {
              label: '编辑',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                this.$router.push({ name: 'editPlan', params: { planId: row.packageId } })
              }
            },
            delete: {
              label: '删除',
              color: '#F9374A',
              clickEvent: (thisVue, row) => {
                thisVue.$confirm('确定要删除"' + row.packageName + '"吗?', '删除', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  customClass: 'confirm-box',
                  cancelButtonClass: 'line-btn',
                  type: 'warning'
                }).then(() => {
                  thisVue.$api.operationManagement.deletePlan({
                    packageId: row.packageId
                  }).then(res => {
                    if (res.data.code === 0) {
                      thisVue.$message({
                        type: 'success',
                        message: '删除成功！'
                      })
                      thisVue.getList()
                    } else {
                      thisVue.$message({
                        type: 'error',
                        message: res.data.message
                      })
                    }
                  }).catch(err => {
                    thisVue.$message({
                      type: 'error',
                      message: err
                    })
                  })
                }).catch(() => {})
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  mounted() {
    this.getTypeList()
    this.getList()
  },
  methods: {
    async getTypeList() {
      let res = {}
      try {
        res = await this.$api.operationManagement.getPlanType()
      } catch (err) {
        console.log(err)
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.typeList = res.data.data
        this.typeList.unshift({
          packageTypeName: '全部',
          packageTypeId: 0
        })
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async getList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.operationManagement.getPlanList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          packageTypeId: this.planType
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    handleCreatePlan() {
      this.$router.push({ name: 'addPlan' })
    },
    handleEditStock(row) {
      this.$set(row, 'isEdit', true)
      this.oldStock[row.id] = row.stock
    },
    handleSaveStock(row) {
      const regExp = /^[0]$|^[1-9][0-9]*$/
      if (!regExp.test(row.stock)) {
        this.$message({
          type: 'error',
          message: '库存数量格式错误'
        })
      } else {
        console.log(this.oldStock)
        let target = this.listData.findIndex(item => {
          return item.id === row.id
        })
        if (Math.random() > 0.3) {
          this.$set(this.listData[target], 'stock', row.stock)
          console.log(this.listData)
          this.$set(row, 'isEdit', false)
        } else {
          this.$message({
            type: 'error',
            message: '网络错误'
          })
          this.$set(row, 'stock', this.oldStock[row.id])
          this.$set(row, 'isEdit', false)
        }
      }
    },
    handleStatusChange(val, row) {
      if (this.switchLoading[row.id]) {
        return
      }
      console.log(this.switchLoading[row.id])
      this.$set(this.switchLoading, row.id, true)
      setTimeout(() => {
        console.log(val)
        this.$set(this.switchLoading, row.id, false)
      }, 3000)
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    }
  }
}
</script>

<style>
  .el-tooltip__popper {
    max-width: 400px;
    line-height: 180%;
  }
</style>
<style lang="scss" scoped>
.operation-plan {
  color: $--color-text-primary;
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 16px 32px;
    margin-bottom: 20px;
    &.control-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 0;
      border: none;
      background-color: transparent;
      .title {
        font-weight: 600;
      }
      ::v-deep.el-button {
        width: 100px;
      }
    }
  }
  .search-bar {
    display: flex;
    // justify-content: space-between;
    .label-input {
      // display: flex;
      // align-items: center;
      .label {
        display: inline-block;
        margin-right: 12px;
        font-size: $--font-size-base;
      }
      // ::v-deep .search-input {
      //   width: 350px;
      //   margin-right: 10px;
      //   .el-input__inner {
      //     line-height: 33px;
      //   }
      // }
      & + .label-input {
        margin-left: 16px;
      }
    }
    .button-box {
      margin-left: 20px;
      min-width: 122px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body div {
        color: $--color-text-primary;
      }
    }
    .stock-col {
      display: flex;
      justify-content: space-between;
    }
    .edit {
      margin-right: 16px;
      color: $--color-primary;
      cursor: pointer;
    }
    .edit-part {
      display: flex;
      align-items: center;
      .el-input {
        width: 64px;
      }
    }
    .status-switch {
      .el-switch__label {
        position: absolute;
        display: none;
        color: #fff;
        user-select: none;
        span {
          font-size: 12px;
        }
      }
      .el-switch__label--left {
        z-index: 9;
        left: 21px;
      }
      .el-switch__label--right {
        z-index: 9;
        left: -2px;
      }
      .el-switch__label.is-active {
        display: block;
      }
      .el-switch .el-switch__core,
      .el-switch .el-switch__label {
        width: 50px !important;
      }
    }
  }
}
</style>
