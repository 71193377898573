<template>
  <div class="reservation">
    <div class="search-bar">
      <div class="search-bar-input">
        <div class="label-input">
          <div class="label">订单号</div>
          <el-input v-model="search.order"
                    class="search-input"
                    size="small"
                    placeholder="请输入"></el-input>
        </div>
        <div class="label-input">
          <div class="label">手机号</div>
          <el-input v-model="search.phone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"></el-input>
        </div>
        <div class="button-box">
          <el-button @click="handleSearch"
                    size="small"
                    type="primary">查询</el-button>
          <el-button @click="resetSearch"
                    size="small"
                    class="line-btn"
                    plain>重置</el-button>
        </div>
      </div>
      <div class="search-bar-select">
        <div class="select-item">
          <div class="label">服务地点</div>
          <ym-address class="cascader"
                      v-model="areaArr"
                      level="3"
                      type="text"
                      separator=""
                      size="small"
                      @changeData="handleArea"></ym-address>
        </div>
        <div class="select-item">
          <div class="label">预约时间</div>
          <el-date-picker v-model="search.createTime"
                          type="daterange"
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          @change="handleSearch">
          </el-date-picker>
        </div>
        <div class="select-item">
          <div class="label">学段</div>
          <el-select class="select" v-model="search.phase" size="small">
            <el-option v-for="item in phaseList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">服务项目</div>
          <el-select class="select" v-model="search.subject" size="small">
            <el-option v-for="item in phaseList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">订单状态</div>
          <el-select class="select" v-model="search.status" size="small">
            <el-option v-for="item in phaseList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">跟进人</div>
          <el-select class="select" v-model="search.follower" size="small">
            <el-option v-for="item in phaseList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="control-bar block">
      <!-- <span class="title"></span> -->
      <el-button @click="handleExport"
                 size="small"
                 type="primary"
                 :loading="btnLoading">{{btnLoading ? '导出中' : '导出'}}</el-button>
    </div>
    <div class="table-list">
        <ym-table-list :loading="loading"
                       :data="listData"
                       :columns="listColumns"
                       :pageData="pageData"
                       :tableOption="tableOption"
                       @handlePageChange="handlePageChange">
          <template #createTime="{row}">
            {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
          </template>
          <template #address={row}>
            {{`${row.address}`}}
          </template>
          <template #follower="{row}">
            <template v-if="row.follower">

            </template>
            <template v-else>
              <el-popover trigger="click" popper-class="follow-confirm" :ref="`popover-${row.id}`">
                <div class="title">订单跟进</div>
                <div class="body">
                  <span>确定跟进该预约订单吗？</span>
                  <el-form :ref="`form-${row.id}`" :model="form" :rules="rule">
                    <el-form-item prop="follower">
                      <el-select size="mini" v-model="form.follower">
                        <el-option v-for="item in userList"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
                <div class="footer">
                  <el-button class="line-btn" size="mini" @click="handlePopClose(row.id)">取消</el-button>
                  <el-button type="primary" size="mini" @click="handleFollow(row.id)">确定</el-button>
                </div>
                <el-button class="follower" slot="reference">跟进</el-button>
              </el-popover>
            </template>
          </template>
        </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmAddress from '@/components/AreaCascade'
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmAddress,
    YmTableList
  },
  data() {
    let checkFollower = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择跟进人'))
      } else {
        callback()
      }
    }

    return {
      list: [
        {
          value: 1,
          label: '1'
        },
        {
          value: 2,
          label: '2'
        }
      ],
      phaseList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '小学'
        },
        {
          value: 2,
          label: '初一'
        },
        {
          value: 3,
          label: '初二'
        },
        {
          value: 4,
          label: '初三'
        },
        {
          value: 5,
          label: '高一'
        },
        {
          value: 6,
          label: '高二'
        },
        {
          value: 7,
          label: '高三'
        }
      ],
      loading: false,
      btnLoading: false,
      areaArr: [],
      search: {
        oroder: '',
        phone: '',
        area: '',
        location: '',
        phase: '',
        subject: '',
        status: '',
        follower: '',
        createTime: ['', '']
      },
      userList: [],
      form: {
        follower: ''
      },
      rule: {
        follower: { validator: checkFollower, trigger: 'change' }
      },
      tableOption: {
        stripe: true
      },
      listData: [
        {
          id: 123,
          phone: 123,
          location: '广州 越秀区',
          createTime: 1622441210350,
          phase: '小学',
          subject: '英语',
          follower: ''
        },
        {
          id: 321,
          phone: 123,
          location: '广州 越秀区',
          createTime: 1622441210350,
          phase: '小学',
          subject: '英语',
          follower: ''
        }
      ],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '订单号',
          prop: 'id',
          show: true
        },
        {
          label: '手机号',
          prop: 'phone',
          show: true
        },
        {
          label: '服务地点',
          prop: 'address',
          show: true
          // width: 160
        },
        {
          label: '预约时间',
          prop: 'createTime',
          show: true,
          width: 160,
          enableSlot: true
        },
        {
          label: '学段',
          prop: 'phase',
          show: true
        },
        {
          label: '服务项目',
          prop: 'subject',
          show: true
        },
        {
          label: '订单状态',
          prop: 'status',
          show: true
        },
        {
          label: '跟进人',
          prop: 'follower',
          show: true,
          enableSlot: true
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '编辑',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'reservationDetail', params: { userId: row.userId } })
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  methods: {
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    resetSearch() {
      this.search = {
        oroder: '',
        phone: '',
        area: '',
        location: '',
        phase: 0,
        subject: 0,
        follower: 0,
        createTime: ['', '']
      }
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    handleArea() {
      const areaStr = this.areaArr[0] + this.areaArr[1] + this.areaArr[2]
      this.$set(this.search, 'area', areaStr)
      console.log(this.search)
    },
    handleExport() {

    },
    // 关闭跟随弹出框
    handlePopClose(id) {
      this.$refs[`popover-${id}`].doClose()
    },
    // 提交跟进人
    handleFollow(id) {
      this.$refs[`form-${id}`].validate((valid) => {
        console.log(valid)
        if (valid) {
          console.log('提交跟进')
          this.$refs[`popover-${id}`].doClose()
        } else {
          return false
        }
      })
    },
    getList() {

    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.follow-confirm {
  width: 250px;
  padding: 0;
  text-align: left;
  .title {
    padding: 6px 12px;
    border-bottom: $--header-border;
  }
  .body {
    padding: 12px;
    .el-form-item {
      margin-bottom: 0;
    }
    .el-form-item__error {
      padding: 0;
    }
    .el-select {
      margin-top: 8px;
      width: 128px;
    }
  }
  .footer {
    padding: 0 12px 12px;
    text-align: right;
  }
  .el-button--primary, .line-btn {
    padding: 4px 12px;
  }
}
</style>

<style lang="scss" scoped>
.reservation {
  .search-bar-input {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    .label-input {
      margin-top: 16px;
      .label {
        width: 56px;
        display: inline-block;
        // width: 84px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      ::v-deep .search-input {
        width: 215px;
        // margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
    }
    .button-box {
      // margin-left: auto;
      margin-top: 16px;
      margin-left: 28px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  .search-bar-select {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    .select-item {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        width: 56px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      .cascader {
        display: inline-block;
        margin-right: 14px;
      }
      ::v-deep.select {
        width: 100px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
      ::v-deep.el-date-editor--daterange {
        &.el-input__inner {
          width: 216px;
        }
        .el-range-input {
          width: 70px;
        }
        .el-range-separator {
          width: 24px;
        }

      }
    }
  }
  .control-bar {
    margin-bottom: 20px;
    ::v-deep.el-button {
      width: 100px;
    }
  }
  ::v-deep.table-list {
    .follower {
      padding: 2px;
      border: none;
      background-color: transparent;
      font-size: $--font-size-base;
      color: $--color-primary;
      &:hover {
        background-color: transparent;
      }
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body {
        td {
          height: 56px;
        }
        div {
          color: $--color-text-primary;
        }
      }
    }
  }
}
</style>
