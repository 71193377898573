
<template>
  <bannerList source="2"></bannerList>
</template>

<script>
import bannerList from './common/list'
export default {
  components: {
    bannerList
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
