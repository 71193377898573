<template>
  <div class="operation-plan-add">
    <div class="block">
      <!-- <div class="header">新增</div> -->
      <div class="main">
        <el-form
          ref="planForm"
          :model="planForm"
          label-position="right"
          label-width="auto"
          label-suffix="："
          :rules="rules">
          <el-form-item label="套餐类别" prop="type" class="type">
            <el-radio-group v-model="planForm.packageTypeId">
              <template v-for="type in typeList">
                <el-radio :key="type.packageTypeId" :label="type.packageTypeId">{{type.packageTypeName}}</el-radio>
              </template>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="套餐名称" prop="packageName">
            <el-input v-model="planForm.packageName" placeholder="请输入套餐名称" size="small" minlength="2" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="有效期" prop="effectiveDays" class="expiry">
            <el-select v-model="planForm.effectiveDays" placeholder="请选择" size="small" @change="checkSelect">
              <el-option
                v-for="item in expiryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="包含免费测评份数" prop="freeQuantity" class="bundled">
            <el-input v-model="planForm.freeQuantity" size="small"></el-input>
            <span class="tips">份</span>
          </el-form-item>
          <el-form-item label="价格" prop="price">
            <el-input v-model="planForm.price" placeholder="请输入价格" size="small"></el-input>
          </el-form-item>
          <el-form-item label="套餐说明" prop="desc" class="desc">
            <el-input v-model="planForm.desc" type="textarea" placeholder="请输入套餐说明" size="small" :rows="8" maxlength="200"></el-input>
          </el-form-item>
          <el-form-item class="footer-box">
            <el-button class="line-btn" size="small" @click="handleCancel">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit('planForm')">确认</el-button>
          </el-form-item>
          <!-- <el-form-item label="现价" prop="currentPrice">
            <el-input v-model="planForm.currentPrice" placeholder="请输入现价" size="small"></el-input>
          </el-form-item>
          <el-form-item label="库存数量" prop="stock">
            <el-input v-model="planForm.stock" placeholder="非必填，不填默认不设置库存" size="small" ></el-input> -->
            <!-- <span class="tips">库存非必填，不填默认不设置库存</span> -->
          <!-- </el-form-item>
          <el-form-item label="上下架" prop="status">
            <el-switch
            v-model="planForm.status"
            active-text="上架"
            inactive-text="下架"
            class="switch-btn"
            :width=56></el-switch>
          </el-form-item>
          <el-form-item label="是否支持退款" prop="refund">
            <el-switch
              v-model="planForm.refund"
              active-text="是"
              inactive-text="否"
              class="switch-btn refund"
              :width=56>
            </el-switch>
          </el-form-item>
          <el-form-item label="支持退款天数" prop="refundLimit" v-if="planForm.refund">
            <el-input v-model="planForm.refundLimit" placeholder="请输入退款天数" size="small"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    let validateName = (rule, value, callback) => {
      console.log(value)
      if (!value.trim()) {
        callback(new Error('套餐名称不能为空'))
      } else {
        callback()
      }
    }

    let validateExpiry = (rule, value, callback) => {
      console.log(value)
      if (!value) {
        callback(new Error('请选择套餐有效期'))
      } else {
        callback()
      }
    }

    let validateBundled = (rule, value, callback) => {
      const regExp = /^[0]$|^[1-9][0-9]*$/
      if (!value && value !== 0) {
        callback(new Error('请输入份数'))
      } else if (!regExp.test(value)) {
        callback(new Error('只能输入整数'))
      } else {
        callback()
      }
    }

    let validatePrice = (rule, value, callback) => {
      const regExp = /^0\.([1-9])$|^0\.(0[1-9])$|^0\.([1-9][0-9])$|^[1-9][0-9]*\.[0-9][0-9]?$|^[1-9][0-9]*$/
      if (!value) {
        callback(new Error('价格不能为空'))
      } else if (!regExp.test(value)) {
        callback(new Error('价格输入格式错误'))
      } else {
        callback()
      }
    }

    // let validateStock = (rule, value, callback) => {
    //   const regExp = /^[1-9][0-9]*$/
    //   if (value && !regExp.test(value)) {
    //     callback(new Error('库存数量格式错误'))
    //   } else {
    //     callback()
    //   }
    // }

    // let validateRefundLimit = (rule, value, callback) => {
    //   const regExp = /^[1-9][0-9]*$/
    //   if (this.planForm.refund) {
    //     if (!value) {
    //       callback(new Error('支持退款天数不能为空'))
    //     } else if (!regExp.test(value)) {
    //       callback(new Error('支持退款天数格式错误'))
    //     } else {
    //       callback()
    //     }
    //   } else {
    //     callback()
    //   }
    // }

    return {
      editFlag: false,
      typeList: [],
      planForm: {
        packageTypeId: 1,
        packageName: '',
        effectiveDays: '',
        freeQuantity: '0',
        desc: '',
        price: ''
        // originalPrice: '',
        // currentPrice: '',
        // stock: '',
        // status: false,
        // refund: false,
        // refundLimit: ''
      },
      rules: {
        packageName: [
          { validator: validateName, trigger: 'blur', required: true },
          { min: 2, max: 30, message: '套餐名称长度为2至30', trigger: 'blur' }
        ],
        effectiveDays: { validator: validateExpiry, trigger: 'blur', required: true },
        freeQuantity: { validator: validateBundled, trigger: 'blur', required: true },
        price: { validator: validatePrice, trigger: 'blur', required: true }
        // currentPrice: { validator: validatePrice, trigger: 'blur', required: true },
        // stock: { validator: validateStock, trigger: 'blur' },
        // refundLimit: { validator: validateRefundLimit, trigger: 'blur', required: true }
      },
      expiryOptions: []
    }
  },
  mounted() {
    this.getTypeList()
    this.getExpiryDays()
    if (this.$route.params.planId) {
      this.getPlanDetail(this.$route.params.planId)
      this.editFlag = true
    }
  },
  methods: {
    async getPlanDetail(id) {
      let res = {}
      try {
        res = await this.$api.operationManagement.getPlanDetail({
          packageId: id
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.planForm = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async getExpiryDays() {
      let res = {}
      try {
        res = await this.$api.operationManagement.getExpiryDays()
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.expiryOptions = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async getTypeList() {
      let res = {}
      try {
        res = await this.$api.operationManagement.getPlanType()
      } catch (err) {
        console.log(err)
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.typeList = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    handleSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.submitPlan()
        } else {
          console.log('error')
          return false
        }
      })
    },
    async submitPlan() {
      let res = {}
      try {
        res = await this.$api.operationManagement.submitPlan({
          packageId: this.editFlag ? this.$route.params.planId : '',
          packageTypeId: this.planForm.packageTypeId,
          packageName: this.planForm.packageName,
          effectiveDays: this.planForm.effectiveDays,
          freeQuantity: this.planForm.freeQuantity,
          desc: this.planForm.desc,
          price: this.planForm.price
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        if (this.editFlag) {
          this.$message({
            type: 'success',
            message: '编辑成功'
          })
        } else {
          this.$message({
            type: 'success',
            message: '添加成功'
          })
        }
        this.$router.push({ name: 'planList' })
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    handleCancel() {
      this.$router.push({ name: 'planList' })
    },
    checkSelect() {
      this.$refs.planForm.validateField('effectiveDays', error => {
        if (!error) {
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-plan-add {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 32px 32px 16px;
  }
  ::v-deep.el-form {
    .el-form-item {
      width: 588px;
      .el-form-item__label, .el-radio {
        color: $--color-text-primary;
      }
      &.expiry, &.bundled {
        display: inline-block;
      }
      &.expiry {
        width: 297px;
      }
      &.bundled {
        width: 260px;
        margin-left: 30px;
        .el-input{
          width: 90px;
        }
        .tips {
          display: inline-block;
          margin-left: 8px;
        }
      }
      &.desc {
        .el-form-item__label {
          line-height: 0;
        }
      }
      &.type {
        .el-form-item__label {
          // line-height: 0;
        }
        .el-radio {
          // margin-top: 3px;
          // margin-bottom: 6px;
        }
      }
      .switch-btn {
        .el-switch__label {
          position: absolute;
          display: none;
          color: #fff;
          user-select: none;
          span {
            font-size: 12px;
          }
        }
        .el-switch__label--left {
          z-index: 9;
          left: 21px;
        }
        .el-switch__label--right {
          z-index: 9;
          left: -2px;
        }
        .el-switch__label.is-active {
          display: block;
        }
        .el-switch .el-switch__core,
        .el-switch .el-switch__label {
          width: 50px !important;
        }
        &.refund {
          .el-switch__label--left {
            left: 27px;
          }
          .el-switch__label--right {
            left: 7px;
          }
        }
      }
    }
    .footer-box {
      .el-button {
        width: 100px;
        & + .el-button {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
