<template>
  <div class="agent-list">
    <div class="search-bar block">
      <div class="label-input">
        <div class="label">手机号</div>
        <el-input v-model="search.loginAccount"
                  size="small"
                  class="search-input"
                  placeholder="请输入"
                  clearable
                  @clear="resetSearch"
                  @keyup.enter.native="handleSearch">
        </el-input>
      </div>
      <div class="label-input">
        <div class="label">推荐人</div>
        <el-input v-model="search.superior"
                  size="small"
                  class="search-input"
                  placeholder="请输入"
                  clearable
                  @clear="resetSearch"
                  @keyup.enter.native="handleSearch">
        </el-input>
      </div>
      <div class="button-box">
        <el-button @click="handleSearch"
                   size="small"
                   type="primary">查询</el-button>
        <el-button @click="resetSearch"
                   size="small"
                   class="line-btn"
                   plain>重置</el-button>
      </div>
    </div>
    <div>
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #nickName="{row}">
          <template v-if="row.nickName">{{row.nickName}}</template>
          <template v-else>--</template>
        </template>
        <template #commission={row}>
          <template v-if="row.commission || row.commission === 0">{{row.commission.toFixed(2)}}</template>
          <template v-else>0.00</template>
        </template>
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      loading: false,
      search: {
        loginAccount: '',
        superior: ''
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '用户ID',
          prop: 'userId',
          show: true
          // width: 180
        },
        {
          label: '手机号',
          prop: 'loginAccount',
          show: true,
          'min-width': 100
        },
        {
          label: '粉丝数量',
          prop: 'fans',
          show: true
        },
        {
          label: '佣金',
          prop: 'commission',
          show: true,
          enableSlot: true
        },
        // {
        //   label: '推荐人',
        //   prop: 'superior',
        //   show: true,
        //   enableSlot: true,
        //   'min-width': 100
        // },
        {
          label: '注册时间',
          prop: 'createTime',
          show: true,
          enableSlot: true,
          'min-width': 150
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '查看',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'agentDetail', params: { userId: row.userId } })
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSearch() {
      if (this.search.loginAccount.trim() || this.search.superior.trim()) {
        this.$set(this.pageData, 'currentPage', 1)
        this.getList()
      } else {
        this.search = {
          loginAccount: '',
          superior: ''
        }
      }
    },
    // 重置搜索框
    resetSearch(id) {
      this.search = {
        loginAccount: '',
        superior: ''
      }
      // this.headData.title = ''
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    },
    async getList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.userManagement.getAgentList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          loginAccount: this.search.loginAccount.trim(),
          superior: this.search.superior.trim()
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.agent-list {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  .search-bar {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .label-input {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        margin-right: 12px;
        font-size: $--font-size-base;
      }
      ::v-deep .search-input {
        width: 230px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
    }
    .button-box {
      margin-top: 16px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body div {
        color: $--color-text-primary;
      }
    }
  }
}
</style>
