<template>
  <div class="report-statistics">
    <div class="search-bar block">
      <div class="label-input">
        <div class="label">账号</div>
        <el-input v-model="search.loginAccount"
                  size="small"
                  class="search-input"
                  placeholder="请输入"
                  clearable
                  @clear="handleSearch"
                  @keyup.enter.native="handleSearch">
        </el-input>
      </div>
      <div class="label-input">
        <div class="label">申请测评报告时间</div>
        <el-date-picker v-model="search.expiry"
                        type="daterange"
                        size="small"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="button-box">
        <el-button @click="handleSearch"
                   size="small"
                   type="primary">查询</el-button>
        <el-button @click="resetSearch"
                   size="small"
                   class="line-btn"
                   plain>重置</el-button>
      </div>
    </div>
    <div class="control-bar block">
      <!-- <span class="title"></span> -->
      <el-button @click="handleExport"
                 size="small"
                 type="primary"
                 :loading="btnLoading">{{ btnLoading ? '导出中' : '导出'}}</el-button>
    </div>
    <div>
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #commitTime="{row}">
          {{row.commitTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      btnLoading: false,
      loading: false,
      search: {
        loginAccount: '',
        expiry: ['', '']
      },
      statusList: [
        {
          statusId: 0,
          statusName: '全部'
        },
        {
          statusId: 1,
          statusName: '体验中'
        },
        {
          statusId: 2,
          statusName: '已付费'
        },
        {
          statusId: 3,
          statusName: '已注销'
        }
      ],
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '用户ID',
          prop: 'userId',
          show: true,
          width: 220
        },
        {
          label: '账号',
          prop: 'loginAccount',
          show: true
        },
        {
          label: '测评份数',
          prop: 'reportCounts',
          show: true
        },
        {
          label: '申请测评报告时间',
          prop: 'commitTime',
          show: true,
          enableSlot: true
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    // 重置搜索框
    resetSearch(id) {
      this.search = {
        loginAccount: '',
        expiry: ['', '']
      }
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    },
    async getList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.statistics.getReportStatisticsList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          loginAccount: this.search.loginAccount.trim(),
          beginTime: this.search.expiry[0] ? Date.parse(this.search.expiry[0]) : '',
          endTime: this.search.expiry[1] ? Date.parse(this.setDateEnd(this.search.expiry[1])) : ''
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    async handleExport() {
      this.btnLoading = true
      let res = {}
      try {
        res = await this.$api.statistics.exportReportStatisticsList()
      } catch (err) {
        this.btnLoading = false
        this.$message({
          type: 'error',
          message: '请求超时，下载失败'
        })
        return
      }
      if (res && res.data && res.data.code === 0) {
        this.btnLoading = false
        window.open(window.BASE_URL + res.data.data.url, '_blank')
        this.$message({
          type: 'success',
          message: '下载成功'
        })
      } else {
        this.btnLoading = false
        this.$message({
          type: 'error',
          message: '下载失败'
        })
      }
    },
    setDateEnd(d) {
      d.setHours(23)
      d.setMinutes(59)
      d.setSeconds(59)
      return d
    }
  }
}
</script>

<style lang="scss" scoped>
.report-statistics {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
    &.control-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 0;
      border: none;
      background-color: transparent;
      .title {
        font-weight: 600;
      }
      ::v-deep.el-button {
        width: 100px;
      }
    }
  }
  .search-bar {
    display: flex;
    flex-wrap: wrap;
    .label-input {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      ::v-deep .search-input {
        width: 230px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
      ::v-deep.el-date-editor {
        margin-right: 20px;
        .el-range-separator {
          width: 10%;
        }
      }
    }
    .button-box {
      // margin-left: auto;
      margin-top: 16px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body {
        td {
          height: 56px;
        }
        div {
          color: $--color-text-primary;
        }
      }
    }
    .parent-accounts {
      display: inline-block;
      white-space:pre;
    }
  }
}
</style>
