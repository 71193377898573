<template>
  <div class="distribution-detail">
    <div class="detail block">
      <div class="detail-item">
        <div class="detail-item-title">
          <span>发放信息</span>
        </div>
        <div class="detail-item-info">
          <div class="info-item">
            <span>批次号：</span>
            <span>{{(basicInfo && basicInfo.transferBatchId) || '--'}}</span>
          </div>
          <div class="info-item">
            <span>发放名称：</span>
            <span>{{(basicInfo && basicInfo.batchName) || '--'}}</span>
          </div>
          <div class="info-item">
            <span>发放时间：</span>
            <span>{{ basicInfo.transferTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}</span>
          </div>
          <div class="info-item">
            <span>发送手机号：</span>
            <span>{{(basicInfo && basicInfo.targetUserPhone) || '--'}}</span>
          </div>
          <div class="info-item">
            <span>发放状态：</span>
            <span>{{(basicInfo && basicInfo.transferStatus) || '--'}}</span>
          </div>
          <div class="info-item">
            <span>操作账号：</span>
            <span>{{(basicInfo && basicInfo.transferAccount) || '--'}}</span>
          </div>
          <div class="info-item">
            <span>备注：</span>
            <span>{{(basicInfo && basicInfo.remark) || '--'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="detail block">
      <div class="detail-item">
        <div class="detail-item-title">
          <span>套餐信息</span>
        </div>
        <div class="detail-item-table">
          <ym-table-list :loading="loading"
                         :data="planData"
                         :columns="listColumns"
                         :tableOption="tableOption">
            <template #effectiveDays={row}>
              <template v-if="row.effectiveDays">
                {{row.effectiveDays}}
              </template>
              <template v-else>--</template>
            </template>
          </ym-table-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      loading: false,
      batchId: '',
      basicInfo: {},
      tableOption: {
        stripe: true
      },
      planData: [],
      listColumns: [
        {
          label: '产品名称',
          prop: 'productName',
          show: true,
          align: 'center'
        },
        {
          label: '产品类型',
          prop: 'productTypeName',
          show: true,
          align: 'center'
        },
        {
          label: '有效期',
          prop: 'effectiveDays',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '数量',
          prop: 'quantity',
          show: true,
          align: 'center'
        }
      ]
      // pageData: {
      //   size: 10, // 每页的数据量
      //   total: 0, // 数据总量
      //   layout: 'prev, pager, next, total, jumper, slot',
      //   currentPage: 1 // 当前页
      // }
    }
  },
  mounted() {
    this.batchId = this.$route.params.batchId
    this.getList()
    // this.getLogList()
  },
  methods: {
    // handlePageChange(page) {
    //   this.$set(this.pageData, 'currentPage', page)
    //   console.log(this.pageData)
    //   this.getLogList()
    // },
    async getList() {
      let res = {}
      try {
        res = await this.$api.operationManagement.getBatchDetail({
          transferBatchId: this.batchId
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.basicInfo = res.data.data
        this.planData = this.basicInfo.packageAssessmentList
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    }
    // async getLogList() {
    //   let res = {}
    //   try {
    //     this.loading = true
    //     res = await this.$api.userManagement.getLog({
    //       page: this.pageData.currentPage,
    //       size: this.pageData.size,
    //       userId: this.userId
    //     })
    //   } catch (err) {
    //     this.loading = false
    //     throw new Error(err)
    //   }
    //   if (res.data && res.data.code === 0) {
    //     this.listData = res.data.data.records
    //     this.$set(this.pageData, 'total', res.data.data.total)
    //   } else {
    //     this.$message({
    //       type: 'error',
    //       message: res.data.message
    //     })
    //   }
    //   this.loading = false
    // }
  }
}
</script>

<style lang="scss" scoped>
.distribution-detail {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    // padding: 16px 32px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    .detail-item {
      color: $--color-text-primary;
      // padding: 24px 0 51px;
      padding: 0 0 18px;
      border-bottom: $--header-border;
      &:last-child {
        border-bottom: none;
      }
      .detail-item-title {
        padding: 16px 24px;
        margin-bottom: 12px;
        border-bottom: $--header-border;
        font-size: 16px;
        font-weight: 600;
      }
      .detail-item-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .info-item {
          width: 50%;
          font-size: 14px;
          margin-top: 22px;
          padding-right: 16px;
          &.teaching {
            width: 50%;
          }
          .course {
            display: inline-block;
            white-space: pre;
          }
        }
      }
      .detail-item-table {
        margin-top: 22px;
        padding: 0 24px;
        // width: 400px;
      }
    }
    ::v-deep .ym-table {
      .ym-table-header {
        margin-bottom: 20px;
      }
      .ym-table-bottom {
        // padding: 19px 32px;
      }
      .el-table {
        .el-table__header {
          thead tr th {
            padding: 16px 0;
          }
        }
        .el-table__body {
          td {
            height: 56px;
          }
          div {
            color: $--color-text-primary;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .teacher-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .teacher-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 100%;
            &.teaching {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
