<template>
  <div class="teacher-detail">
    <div v-loading="infoLoading">
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>基本信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>用户ID：</span>
              <span>{{(basicInfo && basicInfo.userId) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>姓名：</span>
              <span>{{(basicInfo && basicInfo.realName) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>手机号：</span>
              <span>{{(basicInfo && basicInfo.phone) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>昵称：</span>
              <span>{{(basicInfo && basicInfo.nickName) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>性别：</span>
              <span>{{(basicInfo && basicInfo.gender) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>生日：</span>
              <span>{{(basicInfo && basicInfo.birthday) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>注册时间：</span>
              <span>{{ basicInfo.createTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}</span>
            </div>
            <div class="info-item">
              <span>注册邮箱：</span>
              <span>{{(basicInfo && basicInfo.userEmail) || '--'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>服务信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item teaching">
              <span>学校：</span>
              <span>{{(basicInfo && basicInfo.schoolName) || '--'}}</span>
            </div>
            <div class="info-item teaching">
              <span>学历：</span>
              <span>{{(basicInfo && basicInfo.degree) || '--'}}</span>
            </div>
            <div class="info-item teaching">
              <span>地区：</span>
              <span>{{(basicInfo && basicInfo.province && (`${basicInfo.province}${basicInfo.city}${basicInfo.address}`)) || '--'}}</span>
            </div>
            <div class="info-item teaching">
              <span>学段：</span>
              <span>{{(basicInfo && basicInfo.stage) || '--'}}</span>
            </div>
            <div class="info-item teaching">
              <span>学科：</span>
              <template v-if="basicInfo && basicInfo.courses">
                <template v-for="(course, index) in basicInfo.courses">
                  <span v-if="index === 0" :key="index" class="course">{{course}}</span>
                  <span v-else :key="index" class="course">{{' | ' + course}}</span>
                </template>
              </template>
              <template v-else>
                <span>--</span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>已激活套餐信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>套餐名称：</span>
              <span>{{(basicInfo && basicInfo.packages && basicInfo.packages.packageTypeName) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>剩余套餐天数：</span>
              <span>{{(basicInfo && basicInfo.packages && basicInfo.packages.packageDays) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>剩余测评份数：</span>
              <span>{{(basicInfo && basicInfo.packages && basicInfo.packages.reportQuantity) || '--'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>未激活套餐信息</span>
          </div>
          <div class="detail-item-table">
            <ym-table-list :data="planData"
                          :columns="planColumns"
                          :tableOption="tableOption">
              <template #effectiveTime={row}>
                <template v-if="row && row.effectiveTime">
                  {{row.effectiveTime}}
                </template>
                <template v-else>
                  --
                </template>
              </template>
            </ym-table-list>
          </div>
        </div>
      </div> -->
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>操作日志</span>
          </div>
          <div class="detail-item-table">
            <ym-table-list :loading="loading"
                          :data="listData"
                          :columns="listColumns"
                          :pageData="pageData"
                          :tableOption="tableOption"
                          @handlePageChange="handlePageChange">
              <template #time={row}>
                {{row.time | timeFilter('YYYY.MM.DD HH:mm:ss') }}
              </template>
            </ym-table-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      jumpFlag: false,
      infoLoading: false,
      loading: false,
      userId: '',
      basicInfo: {},
      tableOption: {
        stripe: true
      },
      planData: [],
      planColumns: [
        {
          label: '类型',
          prop: 'packageTypeName',
          show: true,
          align: 'center'
        },
        {
          label: '有效期',
          prop: 'effectiveTime',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '数量',
          prop: 'quantity',
          show: true,
          align: 'center'
        }
      ],
      listData: [],
      listColumns: [
        {
          label: '操作',
          prop: 'operationDesc',
          show: true,
          align: 'center'
        },
        {
          label: '时间',
          prop: 'time',
          show: true,
          align: 'center',
          enableSlot: true
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'teacherList' && !this.jumpFlag) {
      this.jumpFlag = true
      next({ name: 'teacherList', params: { tab: 'yuntuofu' } })
    } else {
      next()
    }
  },
  mounted() {
    this.userId = this.$route.params.userId
    this.getList()
    this.getLogList()
  },
  methods: {
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getLogList()
    },
    async getList() {
      this.infoLoading = true
      let res = {}
      try {
        res = await this.$api.userManagement.getTeacherInfo({
          userId: this.userId
        })
      } catch (err) {
        this.infoLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.infoLoading = false
        this.basicInfo = res.data.data
        this.planData = this.basicInfo.inactivePackages
      } else {
        this.infoLoading = false
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async getLogList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.userManagement.getLog({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          userId: this.userId
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data && res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.teacher-detail {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    // padding: 16px 32px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    .detail-item {
      color: $--color-text-primary;
      // padding: 24px 0 51px;
      padding: 0 0 18px;
      border-bottom: $--header-border;
      &:last-child {
        border-bottom: none;
      }
      .detail-item-title {
        padding: 16px 24px;
        margin-bottom: 12px;
        border-bottom: $--header-border;
        font-size: 16px;
        font-weight: 600;
      }
      .detail-item-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .info-item {
          width: 50%;
          font-size: 14px;
          margin-top: 22px;
          padding-right: 16px;
          &.teaching {
            width: 50%;
          }
          .course {
            display: inline-block;
            white-space: pre;
          }
        }

      }
      .detail-item-table {
        margin-top: 22px;
        padding: 0 24px;
        // width: 400px;
      }
    }
    ::v-deep .ym-table {
      .ym-table-header {
        margin-bottom: 20px;
      }
      .ym-table-bottom {
        // padding: 19px 32px;
      }
      .el-table {
        .el-table__header {
          thead tr th {
            padding: 16px 0;
          }
        }
        .el-table__body {
          td {
            height: 56px;
          }
          div {
            color: $--color-text-primary;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .teacher-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .teacher-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 100%;
            &.teaching {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
