<template>
  <div class='group-edit'>
    <el-form :md="18" ref="form" :model="form" :rules="rules" label-width="100px" size="medium" class="group-edit-form">
      <el-form-item label="用户组名" prop="groupName">
        <el-input v-model="form.groupName"></el-input>
      </el-form-item>
      <el-form-item label="用户组描述" prop="groupDescription">
        <el-input v-model="form.groupDescription" type="textarea" :rows="3"></el-input>
      </el-form-item>
      <el-form-item label="关联角色" prop="roleIds">
        <div class="group-edit-form-box">
          <el-checkbox-group v-model="form.roleIds">
            <el-checkbox v-for="role in userRoleList" :label="role.roleId" :key="role.roleId">{{role.roleName}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item class="button-box">
        <el-button @click="goBack=>{this.$router.go(-1)}" size="small" class="line-btn">返回</el-button>
        <el-button type="primary" @click="beforeSubmit" size="small">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        groupId: '',
        groupName: '',
        groupDescription: '',
        roleIds: []
      },
      rules: {
        groupName: [
          { required: true, message: '请输入组名', trigger: 'change' },
          { min: 4, max: 16, message: '长度 4-16 个字符', trigger: 'change' },
          { pattern: /^[a-zA-Z][a-zA-Z0-9]+[\d\w]*$/, message: '只能输入数字字母下划线,且只能以字母开头' }
        ],
        roleIds: [
          { required: true, message: '选择关联角色', trigger: 'change' }
        ]
      },
      userRoleList: [],
      isIndeterminate: true
    }
  },
  mounted() {
    this.getUserRoleList()
  },
  methods: {
    beforeSubmit() {
      let thisVue = this
      this.$refs.form.validate((valid) => {
        if (valid) {
          thisVue.onSubmit()
        } else {
          return false
        }
      })
    },
    // 提交表单
    async onSubmit() {
      this.loading = true
      try {
        if (this.groupId) {
        // 编辑
          const { data } = await this.$api.userGroup.updateUserGroup(this.form)
          console.log({ '提交编辑表单': this.form, '返回数据': data })
          if (data.code === 0 && data.message) {
            const { response } = await this.$api.userGroup.updateUserRoleOfUserGroup(this.form)
            console.log(response)
            this.$router.push({ name: 'userGroup-list', params: { action: 'edit-success' } })
          } else {
            this.$message.error('编辑用户组失败' + (data.message || ''))
          }
        } else {
        // 新增
          const { data } = await this.$api.userGroup.addUserGroup(this.form)
          console.log({ '提交新增表单': this.form, '返回数据': data })
          if (data.code === 0 && data.message) {
            this.$router.push({ name: 'userGroup-list', params: { action: 'add-success' } })
          } else {
            this.$message.error('创建用户组失败' + (data.message || ''))
          }
        }
      } catch (e) {
        this.loading = false
        console.log(e)
        this.$message.error('提交表单失败')
      }

      this.loading = false
    },
    // 获取全部角色, 并初始化表单
    async getUserRoleList() {
      let { data } = await this.$api.userRole.getUserRoleList()
      console.log({ '获取全部角色': data })
      if (!(data.code === 0 && data.data)) {
        this.$message.error('获取角色列表失败')
        return
      }
      // 此处设置多选数据
      this.userRoleList = data.data
      this.groupId = this.$route.query.groupId

      // 编辑页面,回显数据
      if (this.groupId) {
        // 没有提供查询用户组详情接口 o(╥﹏╥)o, 只能从url中获取参数
        this.form.groupId = this.$route.query.groupId
        this.form.groupName = this.$route.query.groupName
        this.form.groupDescription = this.$route.query.groupDescription
        // 根据用户id查询关联角色
        let { data } = await this.$api.userGroup.getUserRoleByUserGroup(this.groupId)
        console.log({ '传递id': this.groupId, '查询关联角色': data })
        if (data.code === 0 && data.data) {
          for (var key in data.data) {
            if (data.data[key].roleId) this.form.roleIds.push(data.data[key].roleId)
          }
        } else {
          this.$message.error('查询用户组失败')
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.group-edit {
  background: #fff;
  padding: 30px;
  .group-edit-form{
    max-width: 600px;

    .group-edit-form-box{
      border: 1px solid #DCDFE6;
      padding: 15px;
      border-radius: 4px;
    }
    .button-box {
      ::v-deep.el-button {
        width: 100px;
        & + .el-button {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
