<template>
  <div class="comment">
    <div class="search-bar block">
      <div class="search-bar-input">
        <div class="label-input">
          <div class="label order-id-label">订单号</div>
          <el-input v-model="search.orderId"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">订单名称</div>
          <el-input v-model="search.title"
                    class="search-input order-name"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">订单发布者</div>
          <el-input v-model="search.parentPhone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">接单托管员</div>
          <el-input v-model="search.tutorPhone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="button-box">
          <el-button @click="handleSearch"
                    size="small"
                    type="primary">查询</el-button>
          <el-button @click="resetSearch"
                    size="small"
                    class="line-btn"
                    plain>重置</el-button>
        </div>
      </div>
      <div class="search-bar-select">
        <div class="select-item">
          <div class="label score-label">星级</div>
          <el-select class="select total-class" v-model="search.score" size="small" @change="handleSearch">
            <el-option v-for="item in scoreList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">评价时间</div>
          <el-date-picker v-model="search.createTime"
                          type="daterange"
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          @change="handleSearch">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="table-list">
      <ym-table-list :loading="loading"
                      :data="listData"
                      :columns="listColumns"
                      :pageData="pageData"
                      :tableOption="tableOption"
                      @handlePageChange="handlePageChange">
        <template #score={row}>
          {{`${row.score}星`}}
        </template>
        <template #content="{row}">
          <template v-if="row.content">
            <el-tooltip class="item" effect="dark" :content="row.content" placement="top">
              <text-line :text="row.content" :clamp="3"></text-line>
            </el-tooltip>
          </template>
          <template v-else>--</template>
        </template>
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import TextLine from '@/components/TextLine'

export default {
  components: {
    YmTableList,
    TextLine
  },
  data() {
    return {
      scoreList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '1星'
        },
        {
          value: 2,
          label: '2星'
        },
        {
          value: 3,
          label: '3星'
        },
        {
          value: 4,
          label: '4星'
        },
        {
          value: 5,
          label: '5星'
        }
      ],
      loading: false,
      search: {
        orderId: '',
        title: '',
        parentPhone: '',
        tutorPhone: '',
        score: 0,
        createTime: ['', '']
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '订单号',
          prop: 'orderId',
          show: true
        },
        {
          label: '订单名称',
          prop: 'title',
          show: true
        },
        {
          label: '星级',
          prop: 'score',
          show: true,
          enableSlot: true,
          width: 64
        },
        {
          label: '评价内容',
          prop: 'content',
          show: true,
          enableSlot: true
        },
        {
          label: '订单发布者',
          prop: 'parentPhone',
          show: true
        },
        {
          label: '接单托管员',
          prop: 'tutorPhone',
          show: true
        },
        {
          label: '评价人',
          prop: 'assessUserPhone',
          show: true
        },
        {
          label: '评价时间',
          prop: 'createTime',
          show: true,
          enableSlot: true,
          width: 160
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '查看',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'courseOrderDetail', params: { orderId: row.orderId } })
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  watch: {
    'search.createTime': {
      // 对点击日期选择器的清空按钮进行特殊处理
      handler(val) {
        if (val === null) {
          this.$set(this.search, 'createTime', ['', ''])
          console.log(this.search)
          this.getList()
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.params.orderId) {
      this.$set(this.search, 'orderId', this.$route.params.orderId)
    }
    this.getList()
  },
  methods: {
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    resetSearch() {
      this.search = {
        orderId: '',
        title: '',
        parentPhone: '',
        tutorPhone: '',
        score: 0,
        createTime: ['', '']
      }
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    async getList() {
      this.loading = true
      let res = {}
      try {
        res = await this.$api.feedback.getCommentList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          orderId: this.search.orderId.trim(),
          title: this.search.title.trim(),
          parentPhone: this.search.parentPhone.trim(),
          tutorPhone: this.search.tutorPhone.trim(),
          score: this.search.score,
          createTimeStart: this.search.createTime[0] ? Date.parse(this.search.createTime[0]) : '',
          createTimeEnd: this.search.createTime[1] ? Date.parse(this.search.createTime[1]) : ''
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.comment {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  .search-bar-input {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    .label-input {
      margin-top: 16px;
      .label {
        width: 70px;
        display: inline-block;
        // width: 84px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
        &.order-id-label {
          width: 56px;
        }
      }
      ::v-deep .search-input {
        width: 215px;
        // margin-right: 14px;
        .el-input__inner {
          line-height: 33px;
        }
        &.order-name {
          margin-right: 14px;
        }
      }
    }
    .button-box {
      // margin-left: auto;
      margin-top: 16px;
      margin-left: 28px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  .search-bar-select {
    display: flex;
    flex-wrap: wrap;
    .select-item {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        width: 70px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
        &.score-label {
          width: 56px;
        }
      }
      .cascader {
        display: inline-block;
        margin-right: 14px;
      }
      ::v-deep.select {
        width: 100px;
        // margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
      ::v-deep.el-date-editor--daterange {
        &.el-input__inner {
          width: 216px;
        }
        .el-range-input {
          width: 70px;
        }
        .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  .control-bar {
    margin-bottom: 10px;
    ::v-deep.el-button {
      width: 100px;
    }
  }
  ::v-deep.table-list {
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body {
        td {
          height: 56px;
        }
        div {
          color: $--color-text-primary;
        }
      }
    }
  }
}
@media screen and (max-width: 1564px) {
  .comment {
    .search-bar-input {
      .label-input {
        .label {
          &.order-id-label {
            width: 70px;
          }
        }
      }
    }
    .search-bar-select {
      .label {
        &.score-label {
          width: 70px !important;
        }
      }
    }
  }
}
</style>
