<template>
  <div class="ticket-type-addition">
    <div class="cover">
      <h5 class="ym-title">新增票券类型</h5>
      <ym-form ref="ticketType"
               class="form"
               :formValue="formValue"
               :formData="formData"
               labelPosition="top"
               @onSubmit="handleSubmit"></ym-form>
      <div class="bottom">
        <el-button type="primary"
                   @click="checkForm">新增</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import YmForm from '@/components/YmForm'

export default {
  components: {
    YmForm
  },
  data() {
    return {
      formValue: {},
      formData: [{
        items: {
          typeName: {
            label: '类型名称',
            type: 'text',
            rules: [
              {
                validator: function (rule, value, callback) {
                  if (!value) {
                    return callback(new Error('票券类型名称不能为空'))
                  }
                  return callback()
                }
              }
            ]
          },
          description: {
            label: '描述',
            type: 'textarea',
            rows: '3'
          }
        }
      }]
    }
  },
  methods: {
    async handleSubmit() {
      console.log(this.formValue)
      let params = {
        typeName: this.formValue.typeName,
        description: this.formValue.description
      }
      let res = {}

      try {
        res = await this.$api.tickets.createTicketType(params)
      } catch (err) {
        return new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: res.data.message
        })
        this.$router.push('/ticket/type')
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    checkForm() {
      this.$refs.ticketType.onSubmit()
    },
    handleCancel() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='scss' scoped>
.ticket-type-addition {
  min-width: 440px;
  background-color: $--header-bg-color;
  border: $--header-border;
  border-radius: $--border-radius-base;
  padding: 20px 24px 43px;
  margin-bottom: 20px;
  .ym-title {
    margin-top: 20px;
  }
  ::v-deep .form {
    min-width: 500px;
    width: 500px;
  }
  .bottom {
    display: flex;
    justify-content: flex-end;
    width: 500px;
    min-width: 500px;
  }
}
</style>
