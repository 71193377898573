<template>
  <div class="password">
    <div class="cover">
      <!-- <h5 class="ym-title">修改密码</h5> -->
      <h5 class="ym-title"></h5>
      <el-form ref="form"
               :model="form"
               class="form"
               :rules="rules"
               label-width="80px">
        <el-form-item label="旧密码"
                      prop="password">
          <el-input v-model="form.password"
                    type="password"
                    placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码"
                      prop="newPassword">
          <el-input v-model="form.newPassword"
                    type="password"
                    placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码"
                      prop="confirmPassword">
          <el-input v-model="form.confirmPassword"
                    type="password"
                    placeholder="请确认新密码"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom">
        <el-button @click="handleCancel" class="line-btn" size="small">取消</el-button>
        <el-button type="primary"
                   size="small"
                   @click="submitForm('form')">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
const crypto = require('crypto')

export default {
  data() {
    let checkEmpty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('输入不能为空'))
      }
      return callback()
    }
    let confirm = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        return callback(new Error('两次输入的密码不一致'))
      }
      return callback()
    }
    return {
      form: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        password: [
          { validator: checkEmpty, trigger: 'blur' }
        ],
        newPassword: [
          { validator: checkEmpty, trigger: 'blur' }
        ],
        confirmPassword: [
          { validator: checkEmpty, trigger: 'blur' },
          { validator: confirm, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 哈希加密(SHA1)
    encrypt(password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSubmit()
        } else {
          return false
        }
      })
    },
    async handleSubmit() {
      const id = JSON.parse(this.$store.state.user.userInfo).userId
      let params = {
        oldPassword: this.encrypt(this.form.password),
        newPassword: this.encrypt(this.form.newPassword)
      }
      let res = {}
      try {
        res = await this.$api.user.changePassword(params, id)
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: res.data.message
        })
        this.$set(this.form, 'password', '')
        this.$set(this.form, 'newPassword', '')
        this.$set(this.form, 'confirmPassword', '')
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
        this.formValue = {}
      }
    },
    handleCancel() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang='scss' scoped>
.password {
  min-width: 440px;
  background-color: $--header-bg-color;
  border: $--header-border;
  border-radius: $--border-radius-base;
  padding: 20px 24px 30px;
  margin-bottom: 20px;
  .ym-title {
    margin-top: 20px;
  }
  ::v-deep .form {
    min-width: 500px;
    width: 500px;
  }
  .bottom {
    display: flex;
    // justify-content: flex-end;
    width: 500px;
    min-width: 500px;
    margin: 10px 80px;
    ::v-deep.el-button {
      width: 100px;
      & + .el-button {
        margin-left: 24px;
      }
    }
  }
}
</style>
