
import timeFormat from '@/utils/timeFormat.js'

// 搜索表单配置
let formData = [
  {
    items: {
      loginAccount: {
        label: '账号',
        type: 'text',
        // clearable: true,
        size: 'small',
        placeholder: '请输入'
      },
      userName: {
        label: '昵称',
        type: 'text',
        // clearable: true,
        size: 'small',
        placeholder: '请输入'
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: '用户列表',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'ym-icon-tianjia',
      size: 'medium',
      type: 'primary',
      clickEvent: (thisVue) => {
        thisVue.$router.push({ name: 'user-add' })
      }
    }
  ]
}

let tableOption = {
  stripe: true
}

// 列表columns配置
let columnsData = [
  {
    type: '占位栏',
    show: true,
    width: '22'
  },
  {
    label: '用户id',
    prop: 'userId',
    show: false
  },
  {
    label: '账号',
    prop: 'loginAccount',
    show: true,
    minWidth: '120'
  },
  {
    label: '用户角色',
    prop: 'groupIds',
    show: false
  },
  {
    label: '昵称',
    prop: 'userName',
    show: true,
    minWidth: '80',
    enableSlot: true
  },
  {
    label: '创建时间',
    prop: 'createTime',
    show: true,
    minWidth: '120',
    formatter: (row, column, cellValue, index) => {
      return timeFormat(cellValue, 'YYYY-MM-DD HH:mm:ss')
    }
  },
  {
    label: '操作',
    prop: 'operators',
    minWidth: '120',
    fixed: 'right',
    align: 'right',
    children: {
      edit: {
        label: '编辑',
        // icon: 'ym-icon-bianji',
        clickEvent: (thisVue, row) => {
          thisVue.$router.push({ name: 'user-edit', query: { userId: row.userId, userName: row.userName, groupIds: row.groupIds[0], phone: row.phone } })
        },
        showFilter: function (item, row, thisVue) {
          return row.userId !== '1'
        }
      },
      delete: {
        label: '删除',
        // icon: 'ym-icon-shanchu',
        color: '#F9374A',
        clickEvent: (thisVue, row) => {
          thisVue.$confirm('此操作将删除该用户,是否确定?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            thisVue.deleteRow(row)
          }).catch(() => {

          })
        },
        showFilter: function (item, row, thisVue) {
          return row.userId !== '1'
        }
      },
      rset: {
        label: '重置密码',
        icon: 'ym-icon-shuaxin',
        color: '#FF8851',
        clickEvent: (thisVue, row) => {
          thisVue.$confirm('此操作将重置该用户密码,是否确定?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            thisVue.resetUserPassword(row)
          }).catch(() => {

          })
        }
      }
    }
  },
  {
    type: '占位栏',
    show: true,
    width: '22',
    fixed: 'right'
  }
]

// 分页配置项
let pageData = {
  size: 10, // 每页的数据量
  total: 0, // 数据总量
  layout: 'prev, pager, next, total, jumper, slot',
  page: 1 // 当前页
}

// 新增用户配置
let addFormData = [
  {
    items: {
      loginAccount: {
        label: '登录账号',
        type: 'text',
        rules: [
          { required: true, message: '请输入登录账号', trigger: 'change' },
          { minx: 4, max: 20, message: '输入 4-20 个字符', trigger: 'change' },
          { pattern: /^[a-zA-Z][a-zA-Z0-9]+[\d\w]*$/, message: '只能输入数字字母下划线,且只能以字母开头' }
        ]
      },
      formPassword: {
        label: '登录密码',
        type: 'password',
        showPassword: true,
        rules: [
          { required: true, message: '请输入登录密码', trigger: 'change' },
          { min: 6, max: 16, message: '请输入 6 至 16 个字符', trigger: 'change' }
        ]
      },
      confirmPassword: {
        label: '确认密码',
        type: 'password',
        showPassword: true,
        rules: [
          { required: true, message: '请输入登录密码', trigger: 'change' },
          { min: 6, max: 16, message: '请输入 6 至 16 个字符', trigger: 'change' }
        ]
      },
      userName: {
        label: '用户昵称',
        type: 'text',
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      },
      department: {
        label: '所属部门',
        type: 'text',
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      },
      phone: {
        label: '联系电话',
        type: 'text',
        rules: [
          { required: true, max: 11, message: '最长输入 11 个字符', trigger: 'change' },
          { pattern: /^1[3-9]\d{9}$/, message: '只能输入合法的11位手机号码' }
        ]
      },
      userGroup: {
        label: '所属用户组',
        type: 'select',
        rules: [
          { required: true, message: '请选择用户组', trigger: 'change' }
        ],
        children: []
      }

    }
  }
]

let editFormData = [
  {
    items: {
      userName: {
        label: '昵称',
        type: 'text',
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      },
      phone: {
        label: '联系电话',
        type: 'text',
        rules: [
          { required: true, max: 11, message: '最长输入 11 个字符', trigger: 'change' },
          { pattern: /^1[3-9]\d{9}$/, message: '只能输入合法的11位手机号码' }
        ]
      },
      userGroup: {
        label: '所属用户组',
        type: 'select',
        rules: [
          { required: true, message: '请选择用户组', trigger: 'change' }
        ],
        children: [
        ]
      }
    }
  }
]

let tableObj = {
  'formData': formData, // 顶部搜索表单配置
  'columnsData': columnsData, // 表格列/操作列配置
  'headData': headData, // 表头图标配置
  'pageData': pageData, // 分页数据
  'addFormData': addFormData, // 新增用户表单
  'editFormData': editFormData, // 编辑用户表单
  'tableOption': tableOption
}

export default tableObj
