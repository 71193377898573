<template>
  <exception title="500"
             backUrl="/"></exception>
</template>

<script>
import Exception from '@/components/Exception/index.vue'
export default {
  components: {
    'exception': Exception
  }
}
</script>
