<template>
  <div class="audit-statement">
    <div class="search-bar block">
      <div class="search-bar-input">
        <div class="label-input">
          <div class="label">提现单号</div>
          <el-input v-model="search.id"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">手机号</div>
          <el-input v-model="search.phone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="button-box">
          <el-button @click="handleSearch"
                    size="small"
                    type="primary">查询</el-button>
          <el-button @click="resetSearch"
                    size="small"
                    class="line-btn"
                    plain>重置</el-button>
        </div>
      </div>
      <div class="search-bar-select">
        <div class="select-item">
          <div class="label">状态</div>
          <el-select class="select status" v-model="search.status" size="small" @change="handleSearch">
            <el-option v-for="item in statusList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">申请时间</div>
          <el-date-picker v-model="search.createTime"
                          type="daterange"
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          @change="handleSearch">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="table-list">
      <ym-table-list :loading="loading"
                      :data="listData"
                      :columns="listColumns"
                      :pageData="pageData"
                      :tableOption="tableOption"
                      @handlePageChange="handlePageChange">
        <template #amount="{row}">
          <template v-if="row.amount || row.amount === 0">
            {{row.amount.toFixed(2)}}
          </template>
          <template v-else>--</template>
        </template>
        <template #balance="{row}">
          <template v-if="row.balance || row.balance === 0">
            {{row.balance.toFixed(2)}}
          </template>
          <template v-else>--</template>
        </template>
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
        <template #status={row}>
            <span :class="['status-dot', {'fail': row.status === 2, 'progressing': row.status === 1}]"></span>
            <template>
              <span class="status-text" v-if="row.status === 1">待审核</span>
              <span class="status-text" v-if="row.status === 2">审核不通过</span>
            </template>
        </template>
        <template #remark="{row}">
          <template v-if="row.remark">
            <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
              <text-line :text="row.remark" :clamp="3"></text-line>
            </el-tooltip>
          </template>
          <template v-else>--</template>
        </template>
      </ym-table-list>
    </div>
    <el-dialog title="提现审核"
               :visible.sync="showAudit"
               top="250px"
               width="710px"
               :close-on-click-modal="false"
               :destroy-on-close="true"
               custom-class='dialog'
               @open="handleDialogOpen">
      <div class="desc">
        <el-input type="textarea" :rows="5" placeholder="请输入需要补充调整的说明" maxlength="100" show-word-limit v-model="desc"></el-input>
      </div>
      <div class="footer">
        <el-button class="line-btn" size="small" @click="handleAudit(2)">审核不通过</el-button>
        <el-button type="primary" size="small" @click="handleAudit(3)">审核通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import TextLine from '@/components/TextLine'

export default {
  components: {
    YmTableList,
    TextLine
  },
  data() {
    return {
      statusList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '待审核'
        },
        {
          value: 2,
          label: '审核不通过'
        }
      ],
      loading: false,
      areaArr: [],
      search: {
        id: '',
        phone: '',
        status: 0,
        createTime: ['', '']
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '提现单号',
          prop: 'id',
          show: true
        },
        {
          label: '提现人',
          prop: 'userName',
          show: true
        },
        {
          label: '手机号',
          prop: 'phone',
          show: true
        },
        {
          label: '提现金额',
          prop: 'amount',
          show: true,
          enableSlot: true
        },
        {
          label: '余额',
          prop: 'balance',
          show: true,
          enableSlot: true
        },
        {
          label: '状态',
          prop: 'status',
          show: true,
          enableSlot: true
        },
        {
          label: '申请时间',
          prop: 'createTime',
          show: true,
          width: 160,
          enableSlot: true
        },
        {
          label: '备注',
          prop: 'remark',
          show: true,
          enableSlot: true
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            audit: {
              label: '审核',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.showAudit = true
                thisVue.auditId = row.id
              },
              showFilter: function(item, row, thisVue) {
                return row.status === 1
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      },
      showAudit: false,
      desc: '',
      auditId: ''
    }
  },
  watch: {
    'search.createTime': {
      // 对点击日期选择器的清空按钮进行特殊处理
      handler(val) {
        if (val === null) {
          this.$set(this.search, 'createTime', ['', ''])
          console.log(this.search)
          this.getList()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    resetSearch() {
      this.search = {
        id: '',
        phone: '',
        status: 0,
        createTime: ['', '']
      }
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    async getList() {
      this.loading = true
      let res = {}
      try {
        res = await this.$api.money.getAuditList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          id: this.search.id.trim(),
          phone: this.search.phone.trim(),
          status: this.search.status,
          startTime: this.search.createTime[0] ? Date.parse(this.search.createTime[0]) : '',
          endTime: this.search.createTime[1] ? Date.parse(this.search.createTime[1]) : ''
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    },
    handleDialogOpen() {
      this.desc = ''
    },
    async handleAudit(status) {
      let res = {}
      try {
        res = await this.$api.money.handleAudit({
          id: this.auditId,
          checkStatus: status,
          checkMessage: this.desc
        })
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: '审核已提交'
        })
        this.showAudit = false
        this.getList()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    }
  }
}
</script>

<style lang="scss">
.el-dialog {
  .el-dialog__header {
    padding: 16px 20px;
    border-bottom: $--header-border;
    font-weight: 600;
    .el-dialog__title {
      font-size: $--font-size-medium;
    }
    .el-dialog__headerbtn {
      font-size: $--font-size-medium;
      line-height: 21px;
      .el-dialog__close:before {
        color: $--color-text-regular;
      }
    }
  }
}
.dialog {
  border-radius: $--border-radius-base;
}
</style>

<style lang="scss" scoped>
.audit-statement {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  .search-bar-input {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    .label-input {
      margin-top: 16px;
      .label {
        width: 56px;
        display: inline-block;
        // width: 84px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      ::v-deep .search-input {
        width: 215px;
        // margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
    }
    .button-box {
      // margin-left: auto;
      margin-top: 16px;
      margin-left: 28px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  .search-bar-select {
    display: flex;
    flex-wrap: wrap;
    .select-item {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        width: 56px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      ::v-deep.select {
        width: 100px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
        &.status {
          width: 120px;
        }
      }
      ::v-deep.el-date-editor--daterange {
        &.el-input__inner {
          width: 216px;
        }
        .el-range-input {
          width: 70px;
        }
        .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  .control-bar {
    margin-bottom: 10px;
    ::v-deep.el-button {
      width: 100px;
    }
  }
  ::v-deep.table-list {
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body {
        td {
          height: 56px;
        }
        div {
          color: $--color-text-primary;
        }
      }
      .status-dot {
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        &.fail {
          background-color: #ff584d;
        }
        &.progressing {
          position: relative;
          background-color: #006eff;
          &::after {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #006eff;
            border-radius: 50%;
            animation: statusProgressing 1.2s ease-in-out infinite;
          }
        }
      }
      .status-text {
        margin-left: 8px;
      }
    }
  }
  .desc {
    padding: 0 46px;
    margin-bottom: 32px;
    ::v-deep.el-textarea {
      .el-input__count {
        color: $--color-text-placeholder;
      }
    }
  }
  .footer {
    text-align: center;
    ::v-deep.el-button {
      width: 184px;
      &+.el-button {
        margin-left: 72px;
      }
    }
  }
}
@keyframes statusProgressing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
</style>
