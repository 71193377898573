<template>
  <div class="course">
    <div class="search-bar block">
      <div class="search-bar-input">
        <div class="label-input">
          <div class="label">订单号</div>
          <el-input v-model="search.orderId"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">手机号</div>
          <el-input v-model="search.phone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="button-box">
          <el-button @click="handleSearch"
                     size="small"
                     type="primary">查询</el-button>
          <el-button @click="resetSearch"
                     size="small"
                     class="line-btn"
                     plain>重置</el-button>
        </div>
      </div>
      <div class="search-bar-select">
        <div class="select-item">
          <div class="label status-label">审核状态</div>
          <el-select class="select"
                     v-model="search.auditStatus"
                     size="small"
                     @change="handleSearch">
            <el-option v-for="item in auditStatusList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="table-list">
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>

        <!-- <template #auditStatus="{row}">
          <el-button class="follower">查看</el-button>
          <el-popover trigger="click"
                      popper-class="follow-confirm">
            <div class="title">审核订单</div>
            <div class="body">
              <span>确定跟进该预约订单吗？</span>
              <el-select size="mini"
                         v-model="row.auditStatus">
                <el-option v-for="item in auditStatusList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </div>
            <el-button class="follower" slot="reference">审批</el-button>
          </el-popover>
        </template> -->
      </ym-table-list>
    </div>
  </div>
</template>

<script>
// import YmAddress from '@/components/AreaCascade'
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    // YmAddress,
    YmTableList
  },
  data () {
    return {
      auditStatusList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '待审核'
        },
        {
          value: 1,
          label: '已通过'
        },
        {
          value: 2,
          label: '已拒绝'
        }
      ],

      loading: false,
      areaArr: [],
      search: {
        orderId: '',
        phone: '',
        auditStatus: ''
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '订单号',
          prop: 'orderId',
          show: true
        },
        {
          label: '订单标题',
          prop: 'title',
          show: true
        },
        {
          label: '手机号',
          prop: 'phone',
          show: true,
          width: 200
        },
        {
          label: '学段',
          prop: 'stage',
          show: true,
          width: 200
        },
        {
          label: '服务项目',
          prop: 'subject',
          show: true,
          width: 200
        },
        {
          label: '申请时间',
          prop: 'createTime',
          show: true,
          width: 200,
          enableSlot: true
        },
        {
          label: '审核状态',
          prop: 'auditStatusText',
          show: true,
          width: 200
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 150,
          align: 'right',
          children: {
            view: {
              label: '查看',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'courseOrderDetail', params: { orderId: row.orderId } })
              }
            },
            view2: {
              label: '通过',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$confirm('确认是否审核订单？审核订单后状态不能恢复', '审核订单', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  thisVue.submitAuditStatus(row, 1)
                })
              },
              showFilter: function (item, row, thisVue) {
                return ([0].includes(row.auditStatus))
              }
            },
            view3: {
              label: '拒绝',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$confirm('确认是否审核订单？审核订单后状态不能恢复', '审核订单', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  thisVue.submitAuditStatus(row, 2)
                })
              },
              showFilter: function (item, row, thisVue) {
                return ([0].includes(row.auditStatus))
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  // watch: {
  //   'search.payTime': {
  //     // 对点击日期选择器的清空按钮进行特殊处理
  //     handler (val) {
  //       if (val === null) {
  //         this.$set(this.search, 'payTime', ['', ''])
  //         console.log(this.search)
  //         this.getList()
  //       }
  //     },
  //     deep: true
  //   }
  // },
  mounted () {
    this.getList()
  },
  methods: {
    handleSearch () {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    resetSearch () {
      this.search = {
        orderId: '',
        phone: ''
      }
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    // handleArea () {
    //   this.$set(this.search, 'city', this.areaArr[1])
    //   this.$set(this.search, 'area', this.areaArr[2])
    //   this.getList()
    //   console.log(this.search)
    // },
    async getList () {
      this.loading = true
      let res = {}
      try {
        res = await this.$api.order.getCloseAuditList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          orderId: this.search.orderId.trim(),
          auditStatus: this.search.auditStatus
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    handlePageChange (page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    },
    submitAuditStatus (row, status) {
      this.$api.order.closeAudit({
        orderId: row.orderId,
        auditAction: status
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '审核成功'
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        this.$message({
          type: 'error',
          message: err
        })
      })
    }
  }
}
</script>

<style lang="scss">
.el-popconfirm {
  &__main {
    margin: 16px 0;
  }
  .el-button--text {
    color: #4674e6;
    border: 1px solid #4674e6;
    padding: 4px 12px;
    &:hover,
    &:focus {
      border: 1px solid #4674e6;
      background-color: #edf1fd;
    }
    &:active {
      border: 1px solid #4674e6;
      background-color: #fff;
    }
  }
  .el-button--primary {
    padding: 4px 12px;
  }
}
</style>

<style lang="scss" scoped>
.course {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  .search-bar-input {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    .label-input {
      margin-top: 16px;
      .label {
        width: 56px;
        display: inline-block;
        // width: 84px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      ::v-deep .search-input {
        width: 215px;
        // margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
    }
    .button-box {
      // margin-left: auto;
      margin-top: 16px;
      margin-left: 28px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover,
        &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  .search-bar-select {
    display: flex;
    flex-wrap: wrap;
    .select-item {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        width: 56px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
        &.status-label {
        }
      }
      .cascader {
        display: inline-block;
        margin-right: 14px;
      }
      ::v-deep.select {
        width: 100px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
        &.total-class {
          width: 120px;
        }
        &.total-salary {
          width: 120px;
        }
      }
      ::v-deep.el-date-editor--daterange {
        &.el-input__inner {
          width: 216px;
        }
        .el-range-input {
          width: 70px;
        }
        .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  .control-bar {
    margin-bottom: 10px;
    ::v-deep.el-button {
      width: 100px;
    }
  }
  ::v-deep.table-list {
    .follower {
      padding: 2px;
      border: none;
      background-color: transparent;
      font-size: $--font-size-base;
      color: $--color-primary;
      &:hover {
        background-color: transparent;
      }
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body {
        td {
          height: 56px;
        }
        div {
          color: $--color-text-primary;
        }
      }
      .status-dot {
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        &.ongoing {
          position: relative;
          background-color: #27cc85;
          &::after {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #27cc85;
            border-radius: 50%;
            animation: statusProgressing 1.2s ease-in-out infinite;
          }
        }
        &.finish {
          background-color: #778899;
        }
        &.close {
          background-color: red;
        }
        &.progressing {
          position: relative;
          background-color: #006eff;
          &::after {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #006eff;
            border-radius: 50%;
            animation: statusProgressing 1.2s ease-in-out infinite;
          }
        }
      }
      .status-text {
        margin-left: 8px;
      }
    }
  }
}
@keyframes statusProgressing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
</style>
