
<template>
  <div class="banner">
      <el-form ref="bannerform"
        :model="form"
        :file-list="fileList"
        label-position="right"
        label-width="auto"
        :rules="rules"
        label-suffix="：">
        <el-form-item label="banner类型"  prop="type">
          <el-select v-model="form.type" placeholder="请选择banner类型" @change="handleTypeChange">
            <el-option label="视频" :value='1'></el-option>
            <el-option label="图片" :value='2'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="banner名称"  prop="name">
          <el-input v-model.trim="form.name" placeholder="请输入banner名称"></el-input>
        </el-form-item>

          <el-form-item :label="typeMap[form.type]+'文件'" v-if="form.type" prop="path">
            <div class="upload-box">
              <template v-if="form.type==2">
                <el-upload
                  :file-list="fileList"
                  ref="upload"
                  class="upload-demo"
                  :action="uploadPath"
                  list-type="picture"
                  :headers="uploadHeaders"
                  :before-upload="imgCheck"
                  :on-preview="handlePreview"
                  :on-remove="handleDelete"
                  :on-error="handleError"
                  :on-success="uploadSuccess"
                  :limit="1"
                  :on-exceed="handleExceed">
                  <el-button size="small">选择图片</el-button>
                  <div slot="tip" class="el-upload__tip"> 图片文件不超过5M，建议尺寸：宽688*高270</div>
                </el-upload>
              </template>

              <template v-else>
                <el-upload
                  :file-list="fileList"
                  ref="upload"
                  class="upload-demo"
                  :action="uploadPath"
                  list-type="text"
                  :headers="uploadHeaders"
                  :before-upload="movieCheck"
                  :on-preview="handlePreview"
                  :on-remove="handleDelete"
                  :on-error="handleError"
                  :on-success="uploadSuccess"
                  :limit="1"
                  :on-exceed="handleExceed">
                  <el-button size="small">选择视频</el-button>
                  <div slot="tip" class="el-upload__tip"> 视频文件不超过500M</div>
                </el-upload>

                <el-upload style="margin-top: 30px"
                  :file-list="coverList"
                  ref="upload"
                  class="upload-demo"
                  :action="uploadPath"
                  list-type="picture"
                  :headers="uploadHeaders"
                  :before-upload="imgCheck"
                  :on-preview="handlePreview"
                  :on-remove="handleCoverDelete"
                  :on-error="handleError"
                  :on-success="uploadCoverSuccess"
                  :limit="1"
                  :on-exceed="handleExceed">
                  <el-button size="small">选择封面</el-button>
                  <div slot="tip" class="el-upload__tip"> 封面图片不超过5M，建议尺寸：宽688*高270</div>
                </el-upload>

              </template>

            </div>
          </el-form-item>

        <el-form-item label="跳转链接" prop="link" v-if="form.type==2">
          <el-input v-model.trim="form.link" placeholder="请输入banner跳转链接"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit('bannerform')" :loading="loading">确认</el-button>
          <el-button @click="handleCancel">取消</el-button>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
import { getToken } from '@/utils/localStorage.js'
import { MessageBox } from 'element-ui'
export default {

  data() {
    var validateCover = (rule, value, callback) => {
      if (this.form.type === 1 && !this.form.cover) {
        callback(new Error('视频文件请选择封面'))
      } else {
        callback()
      }
    }
    return {
      id: '',
      form: {},
      source: null,
      loading: false,
      typeMap: {
        1: '视频',
        2: '图片'
      },
      fileList: [],
      coverList: [],

      limitText: '5M',
      uploadPath: window.BASE_URL + '/v1/banner/upload',
      uploadHeaders: {
        'dreamkey-token': getToken()
      },
      rules: {
        name: [
          { required: true, message: '请输入banner名称', trigger: 'blur' },
          { max: 30, message: '长度在30字符以内', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择banner类型', trigger: 'change' }
        ],
        path: [
          { required: true, message: '图片、视频为必填项' },
          { validator: validateCover, trigger: 'blur' }
        ],
        link: [
          { required: true, message: '请输入跳转链接，仅支持公众号链接跳转', trigger: 'blur' },
          { max: 2000, message: '长度在2000字符以内', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.$set(this.form, 'type', 2)
    this.source = this.$route.query.source
    let id = this.$route.query.id
    this.id = id
    if (id) {
      // 编辑
      this.getData(id)
    }
  },
  methods: {

    async getData(id) {
      let { data } = await this.$api.banner.get(id)
      if (data.code === 0) {
        this.form = JSON.parse(JSON.stringify(data.data))
        let path = data.data.path
        let baseUrl = window.BASE_URL
        this.$set(this, 'fileList', [{
          name: getFileNameByUrl(path),
          url: baseUrl + path
        }])
        if (data.data.type === 1 && data.data.cover) {
          let cover = data.data.cover
          this.$set(this, 'coverList', [{
            name: getFileNameByUrl(cover),
            url: window.BASE_URL + cover
          }])
        }
      } else {
        this.$message.error('获取图片数据失败')
      }

      function getFileNameByUrl(url) {
        let index = url.lastIndexOf('/')
        return url.substring(index + 1, url.length)
      }
    },

    // 类型转变，清空file
    handleTypeChange() {
      this.fileList = []
      this.form.path = null
    },

    // 图片校验
    imgCheck(file) {
      let accept = ['image/png', 'image/jpg', 'image/jpeg']
      if (!accept.includes(file.type)) {
        this.$message.warning('图片格式需要为png/jpg/jpeg')
        return false
      }
      if (file.size / 1024 / 1024 > 5) {
        this.$message.warning('图片大小不得超过5M')
        return false
      }
      return true
    },

    // 视频校验
    movieCheck(file) {
      let accept = ['video/mp4']
      if (!accept.includes(file.type)) {
        this.$message.warning('视频格式需要为mp4')
        return false
      }
      if (file.size / 1024 / 1024 > 500) {
        this.$message.warning('视频大小不得超过500M')
        return false
      }
      return true
    },

    // 上传失败
    handleError() {
      this.fileList = []
      this.path = null
      this.$message.error('上传失败')
    },

    // 移除图片
    handleDelete() {
      this.fileList = []
      this.form.path = null
    },
    handleCoverDelete() {
      this.coverList = []
      this.form.cover = null
    },

    uploadCoverSuccess({ code, data, message }, file) {
      if (code === 10202) {
        this.fileList = []
        MessageBox.confirm('当前登录已超时或未登录，请重新登录', '重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.$router.push({ name: 'login' })
        })
        return
      }
      if (code === 0) {
        this.form.cover = data.path
      } else {
        this.$message.error(message || '封面上传失败')
      }
    },

    // 上传成功
    uploadSuccess({ code, data, message }, file) {
      if (code === 10202) {
        this.fileList = []
        MessageBox.confirm('当前登录已超时或未登录，请重新登录', '重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false
        }).then(() => {
          this.$router.push({ name: 'login' })
        })
        return
      }
      if (code === 0) {
        this.form.path = data.path
      } else {
        this.fileList = []
        this.form.path = null
        this.$message.error(message || '文件上传失败')
      }
    },

    handlePreview(file) {
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传一个文件`)
    },
    // 返回
    handleCancel() {
      this.$router.go(-1)
    },
    // 表单校验
    handleSubmit(form) {
      if (this.loading) {
        return
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.submit()
        } else {
          console.log('error')
          return false
        }
      })
    },
    // 提交表单
    async submit() {
      this.loading = true
      try {
        let res
        if (this.id) {
          res = await this.$api.banner.update({ source: this.source, ...this.form })
        } else {
          res = await this.$api.banner.add({ source: this.source, ...this.form })
        }
        let data = res.data
        if (data.code === 0) {
          this.loading = false
          this.$message.success('保存成功')
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        } else {
          this.$message.error('保存失败' + (':' + data.message || ''))
        }
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.banner{
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 32px 32px 16px;

  .upload-box{
    padding: 12px 16px 28px;
    border: 1px #DEDEDE dashed;
    border-radius: $--border-radius-base;
  }

  ::v-deep.el-form {
    .el-form-item {
      width: 588px;
      .el-form-item__label, .el-radio {
        color: $--color-text-primary;
      }
      .el-upload-list--picture .el-upload-list__item-thumbnail{
        height: 70px;
        width: 175px;
        object-fit: contain;
      }
      &.expiry, &.bundled {
        display: inline-block;
      }
      &.expiry {
        width: 297px;
      }
      &.bundled {
        width: 260px;
        margin-left: 30px;
        .el-input{
          width: 90px;
        }
        .tips {
          display: inline-block;
          margin-left: 8px;
        }
      }
      &.desc {
        .el-form-item__label {
          line-height: 0;
        }
      }
      &.type {
        .el-form-item__label {
          // line-height: 0;
        }
        .el-radio {
          // margin-top: 3px;
          // margin-bottom: 6px;
        }
      }
      .switch-btn {
        .el-switch__label {
          position: absolute;
          display: none;
          color: #fff;
          user-select: none;
          span {
            font-size: 12px;
          }
        }
        .el-switch__label--left {
          z-index: 9;
          left: 21px;
        }
        .el-switch__label--right {
          z-index: 9;
          left: -2px;
        }
        .el-switch__label.is-active {
          display: block;
        }
        .el-switch .el-switch__core,
        .el-switch .el-switch__label {
          width: 50px !important;
        }
        &.refund {
          .el-switch__label--left {
            left: 27px;
          }
          .el-switch__label--right {
            left: 7px;
          }
        }
      }
    }
    .footer-box {
      .el-button {
        width: 100px;
        & + .el-button {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
