<template>
  <exception title="404"
             subtitle="抱歉，您访问的页面不存在！"
             extra="返回首页"
             backUrl="/"
             buttonType="primary"></exception>
</template>

<script>
import Exception from '@/components/Exception/index.vue'
export default {
  components: {
    'exception': Exception
  }
}
</script>
