<template>
  <div class="ticket-management-detail">
    <div class="part">
      <div class="block">
        <div class="top-left">
          <div class="selector">
            <el-select v-model="selectedStatus"
                       placeholder="状态"
                       clearable
                       filterable
                       @change="handleStatusSearch">
              <el-option v-for="status in statusList"
                         :key="status.value"
                         :label="status.value"
                         :value="status.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div>
        <ym-table-list stripe
                       :loading="loading"
                       :headData="headData"
                       :data="listData"
                       :columns="listColumns"
                       :pageData="pageData"
                       :row-style="rowStyle"
                       @handlePageChange="handlePageChange">
          <template #issueTime="{row}">
            <template v-if="row.issueTime">{{row.issueTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
            <template v-else>-</template>
          </template>
          <template #issuerAccount="{row}">
            <template v-if="row.issuerAccount">{{row.issuerAccount}}</template>
            <template v-else>-</template>
          </template>
          <template #receiverAccount="{row}">
            <template v-if="row.receiverAccount">{{row.receiverAccount}}</template>
            <template v-else>-</template>
          </template>
           <template #exchangeTime="{row}">
            <template v-if="row.exchangeTime">{{row.exchangeTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
            <template v-else>-</template>
          </template>
        </ym-table-list>
      </div>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      loading: false,
      selectedStatus: '',
      statusList: [
        {
          value: '未兑换'
        },
        {
          value: '已兑换'
        },
        {
          value: '已过期'
        }
      ],
      headData: {
        title: '票券列表'
      },
      listData: [],
      listColumns: [
        {
          label: '票券实体ID',
          prop: 'ticketEntityId',
          show: true,
          align: 'center'
        },
        {
          label: '发送方',
          prop: 'issuerAccount',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '接收方',
          prop: 'receiverAccount',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '发送时间',
          prop: 'issueTime',
          show: true,
          align: 'center',
          enableSlot: true
        },
        {
          label: '状态',
          prop: 'status',
          show: true,
          align: 'center'
        },
        {
          label: '兑换码',
          prop: 'redemptionCode',
          show: true,
          align: 'center'
        },
        {
          label: '兑换时间',
          prop: 'exchangeTime',
          show: true,
          align: 'center',
          enableSlot: true
        }
        // {
        //   label: '操作',
        //   prop: 'operators',
        //   fixed: 'right',
        //   align: 'center',
        //   children: {
        //     detail: {
        //       label: '兑换',
        //       icon: 'ym-icon-quanxuan',
        //       clickEvent: (thisVue, row) => {
        //         console.log('兑换')
        //       }
        //     }
        //   }
        // }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      },
      rowStyle: {
        height: '57px'
      }
    }
  },
  mounted() {
    console.log(this.$route.query)
    this.getList()
  },
  methods: {
    // 搜索栏搜索
    handleSearch() {
      let str = ''
      if (!this.selectedStatus) {
        str = '票券列表'
      } else {
        str = '显示 ' + ' ' + this.selectedStatus + ' 的筛选结果'
      }
      this.headData.title = str
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    // 状态标签搜索
    handleStatusSearch(status) {
      console.log(status)
      this.selectedStatus = status
      this.handleSearch()
    },
    // 分页跳转
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      this.getList()
    },
    // 获取列表数据
    async getList() {
      let res = {}
      const ticketId = this.$route.query.ticketId
      try {
        this.loading = true
        res = await this.$api.tickets.getTicketsEntities({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          ticketId: ticketId,
          status: this.selectedStatus
        })
      } catch (err) {
        this.loading = false
        return new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.ticket-management-detail {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 20px 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .top-left {
      display: flex;
      margin-right: 8px;
      .selector + .selector {
        margin-left: 8px;
      }
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
  }
  ::v-deep .form {
    min-width: 90%;
    width: 90%;
  }
  .bottom {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    width: 90%;
  }
  .cover {
    display: flex;
  }
}
</style>
