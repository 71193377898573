<template>
  <div class="matched-order">
    <div class="block">
      <div class="table-tabs">
      <el-tabs v-model="currentOrder">
        <el-tab-pane label="预约订单"
                      name="reservation">
          <div class="dialog-content">
            <reservation></reservation>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课时订单"
                      name="course">
          <div class="dialog-content">
            <course></course>
          </div>
        </el-tab-pane>
        <el-tab-pane label="退款订单"
                      name="refund">
          <div class="dialog-content">

          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    </div>
  </div>
</template>

<script>
import Reservation from './reservation/index.vue'
import Course from './course/index.vue'

export default {
  components: {
    Reservation,
    Course
  },
  data() {
    return {
      currentOrder: ''
    }
  },
  mounted() {
    console.log(this.$route.params)
    if (this.$route.params && this.$route.params.tab) {
      this.currentOrder = this.$route.params.tab
    } else {
      this.currentOrder = 'reservation'
    }
  }
}
</script>

<style lang="scss" scoped>
.matched-order {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  ::v-deep .el-tabs {
    .el-tabs__header {
      padding: 14px 24px 0;
      margin-bottom: 0;
      border-bottom: $--header-border;
      .el-tabs__nav-wrap {
        &::after {
          background-color: transparent;
        }
      }
      .el-tabs__item {
        font-size: $--font-size-base;
        line-height: 24px;
      }
    }
    .el-tabs__content {
      padding-top: 20px;
    }
  }
}
</style>
