<template>
  <div class="course">
    <div class="search-bar block">
      <div class="search-bar-input">
        <div class="label-input">
          <div class="label">订单号</div>
          <el-input v-model="search.orderId"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="label-input">
          <div class="label">手机号</div>
          <el-input v-model="search.phone"
                    class="search-input"
                    size="small"
                    placeholder="请输入"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"></el-input>
        </div>
        <div class="button-box">
          <el-button @click="handleSearch"
                     size="small"
                     type="primary">查询</el-button>
          <el-button @click="resetSearch"
                     size="small"
                     class="line-btn"
                     plain>重置</el-button>
        </div>
      </div>
      <div class="search-bar-select">
        <div class="select-item">
          <div class="label">服务地点</div>
          <ym-address class="cascader"
                      v-model="areaArr"
                      level="3"
                      type="text"
                      separator=""
                      size="small"
                      @changeData="handleArea"></ym-address>
        </div>
        <div class="select-item">
          <div class="label">付费时间</div>
          <el-date-picker v-model="search.payTime"
                          type="daterange"
                          size="small"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          @change="handleSearch">
          </el-date-picker>
        </div>
        <div class="select-item">
          <div class="label">总次数</div>
          <el-select class="select total-class"
                     v-model="search.totalClassFilter"
                     size="small"
                     @change="handleSearch">
            <el-option v-for="item in totalClassList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label">总金额</div>
          <el-select class="select total-salary"
                     v-model="search.totalSalaryFilter"
                     size="small"
                     @change="handleSearch">
            <el-option v-for="item in totalSalaryList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="select-item">
          <div class="label status-label">订单状态</div>
          <el-select class="select"
                     v-model="search.orderStatus"
                     size="small"
                     @change="handleSearch">
            <el-option v-for="item in orderStatusList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="table-list">
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #location={row}>
          {{`${row.city} ${row.area}`}}
        </template>
        <template #address={row}>
          {{`${row.address}`}}
        </template>
        <template #payTime="{row}">
          {{row.payTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
        <template #totalSalary="{row}">
          <span v-if="row.totalSalary || row.totalSalary === 0">{{row.totalSalary.toFixed(2)}}</span>
          <span v-else>--</span>
        </template>
        <template #tutorPhone="{row}">
          <span v-if="row.tutorPhone">{{row.tutorPhone}}</span>
          <span v-else>--</span>
        </template>
        <template #orderStatus={row}>
          <span :class="['status-dot', {'ongoing': row.orderStatus === 2, 'progressing': row.orderStatus === 1, 'finish': row.orderStatus === 3,'close': row.orderStatus === 4}]"></span>
          <template>
            <span class="status-text"
                  v-if="row.orderStatus === 1">招募中</span>
            <span class="status-text"
                  v-else-if="row.orderStatus === 2">授课中</span>
            <span class="status-text"
                  v-else-if="row.orderStatus === 3">已完结</span>
            <span class="status-text"
                  v-else>已关闭</span>
          </template>
        </template>
        <template #tryLesson={row}>
          <template>
            <span class="status-text"
                  v-if="row.orderStatus ===1">
              {{row.tryStatus === 1 ? '是': '否'}}
            </span>
            <!-- 是否试课（1-是，0-否） -->
            <span class="status-text"
                  v-else>--</span>
          </template>
        </template>
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmAddress from '@/components/AreaCascade'
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmAddress,
    YmTableList
  },
  data () {
    return {
      totalClassList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '10次以下'
        },
        {
          value: 2,
          label: '10-20次'
        },
        {
          value: 3,
          label: '21-30次'
        },
        {
          value: 4,
          label: '31-40次'
        },
        {
          value: 5,
          label: '41-50次'
        },
        {
          value: 6,
          label: '50次以上'
        }
      ],
      totalSalaryList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '500以下'
        },
        {
          value: 2,
          label: '501-1000'
        },
        {
          value: 3,
          label: '1001-1500'
        },
        {
          value: 4,
          label: '1501-2000'
        },
        {
          value: 5,
          label: '2001-2500'
        },
        {
          value: 6,
          label: '2501-3000'
        },
        {
          value: 7,
          label: '3000以上'
        }
      ],
      orderStatusList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '招募中'
        },
        {
          value: 2,
          label: '授课中'
        },
        {
          value: 3,
          label: '已完结'
        },
        {
          value: 4,
          label: '已关闭'
        }
      ],
      loading: false,
      areaArr: [],
      search: {
        orderId: '',
        phone: '',
        city: '',
        area: '',
        totalClassFilter: 0,
        totalSalaryFilter: 0,
        orderStatus: 0,
        payTime: ['', '']
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '订单号',
          prop: 'orderId',
          show: true
        },
        {
          label: '订单标题',
          prop: 'title',
          show: true
        },
        {
          label: '手机号',
          prop: 'phone',
          show: true,
          width: 130
        },
        {
          label: '服务地点',
          prop: 'address',
          show: true,
          enableSlot: true
          // width: 160
        },
        {
          label: '付费时间',
          prop: 'payTime',
          show: true,
          width: 160,
          enableSlot: true
        },
        {
          label: '总次数',
          prop: 'totalClass',
          show: true
        },
        {
          label: '总金额',
          prop: 'totalSalary',
          show: true,
          enableSlot: true
        },
        {
          label: '接单托管员',
          prop: 'tutorPhone',
          show: true,
          enableSlot: true,
          width: 130
        },
        {
          label: '订单状态',
          prop: 'orderStatus',
          show: true,
          enableSlot: true
        },
        // {
        //   label: '是否试课',
        //   prop: 'tryLesson',
        //   show: true,
        //   enableSlot: true
        // },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '查看',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'courseOrderDetail', params: { orderId: row.orderId } })
              }
            }
            // view2: {
            //   label: '关闭',
            //   color: '#4674e6',
            //   clickEvent: (thisVue, row) => {
            //     thisVue.$confirm('确认是否关闭订单？关闭订单后状态不能恢复', '订单关闭', {
            //       confirmButtonText: '确定',
            //       cancelButtonText: '取消',
            //       type: 'warning'
            //     }).then(() => {
            //       thisVue.$api.order.closeOrder({
            //         orderId: row.orderId
            //       }).then(res => {
            //         if (res.data.code === 0) {
            //           thisVue.$message({
            //             type: 'success',
            //             message: '订单已关闭'
            //           })
            //           thisVue.getList()
            //         } else {
            //           thisVue.$message({
            //             type: 'error',
            //             message: res.data.message
            //           })
            //         }
            //       }).catch(err => {
            //         thisVue.$message({
            //           type: 'error',
            //           message: err
            //         })
            //       })
            //     })
            //   },
            //   showFilter: function (item, row, thisVue) {
            //     return ([1, 2].includes(row.orderStatus))
            //   }
            // }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  watch: {
    'search.payTime': {
      // 对点击日期选择器的清空按钮进行特殊处理
      handler (val) {
        if (val === null) {
          this.$set(this.search, 'payTime', ['', ''])
          console.log(this.search)
          this.getList()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    handleSearch () {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    resetSearch () {
      this.search = {
        orderId: '',
        phone: '',
        city: '',
        area: '',
        totalClassFilter: 0,
        totalSalaryFilter: 0,
        orderStatus: 0,
        payTime: ['', '']
      }
      this.areaArr = []
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    handleArea () {
      this.$set(this.search, 'city', this.areaArr[1])
      this.$set(this.search, 'area', this.areaArr[2])
      this.getList()
      console.log(this.search)
    },
    async getList () {
      this.loading = true
      let res = {}
      try {
        res = await this.$api.order.getProcessingOrderList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          orderId: this.search.orderId.trim(),
          phone: this.search.phone.trim(),
          city: this.search.city,
          area: this.search.area,
          totalClassFilter: this.search.totalClassFilter,
          totalSalaryFilter: this.search.totalSalaryFilter,
          orderStatus: this.search.orderStatus,
          payTimeStart: this.search.payTime[0] ? Date.parse(this.search.payTime[0]) : '',
          payTimeEnd: this.search.payTime[1] ? Date.parse(this.search.payTime[1]) : ''
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    handlePageChange (page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.el-popconfirm {
  &__main {
    margin: 16px 0;
  }
  .el-button--text {
    color: #4674e6;
    border: 1px solid #4674e6;
    padding: 4px 12px;
    &:hover,
    &:focus {
      border: 1px solid #4674e6;
      background-color: #edf1fd;
    }
    &:active {
      border: 1px solid #4674e6;
      background-color: #fff;
    }
  }
  .el-button--primary {
    padding: 4px 12px;
  }
}
</style>

<style lang="scss" scoped>
.course {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  .search-bar-input {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    .label-input {
      margin-top: 16px;
      .label {
        width: 56px;
        display: inline-block;
        // width: 84px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      ::v-deep .search-input {
        width: 215px;
        // margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
    }
    .button-box {
      // margin-left: auto;
      margin-top: 16px;
      margin-left: 28px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover,
        &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  .search-bar-select {
    display: flex;
    flex-wrap: wrap;
    .select-item {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        width: 56px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
        &.status-label {
        }
      }
      .cascader {
        display: inline-block;
        margin-right: 14px;
      }
      ::v-deep.select {
        width: 100px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
        &.total-class {
          width: 120px;
        }
        &.total-salary {
          width: 120px;
        }
      }
      ::v-deep.el-date-editor--daterange {
        &.el-input__inner {
          width: 216px;
        }
        .el-range-input {
          width: 70px;
        }
        .el-range-separator {
          width: 24px;
        }
      }
    }
  }
  .control-bar {
    margin-bottom: 10px;
    ::v-deep.el-button {
      width: 100px;
    }
  }
  ::v-deep.table-list {
    .follower {
      padding: 2px;
      border: none;
      background-color: transparent;
      font-size: $--font-size-base;
      color: $--color-primary;
      &:hover {
        background-color: transparent;
      }
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body {
        td {
          height: 56px;
        }
        div {
          color: $--color-text-primary;
        }
      }
      .status-dot {
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        &.ongoing {
          position: relative;
          background-color: #27cc85;
          &::after {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #27cc85;
            border-radius: 50%;
            animation: statusProgressing 1.2s ease-in-out infinite;
          }
        }
        &.finish {
          background-color: #778899;
        }
        &.close {
          background-color: red;
        }
        &.progressing {
          position: relative;
          background-color: #006eff;
          &::after {
            content: "";
            position: absolute;
            box-sizing: border-box;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #006eff;
            border-radius: 50%;
            animation: statusProgressing 1.2s ease-in-out infinite;
          }
        }
      }
      .status-text {
        margin-left: 8px;
      }
    }
  }
}
@keyframes statusProgressing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
</style>
