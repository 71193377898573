<template>
  <div class="dashboard">

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.dashboard {

}
</style>
