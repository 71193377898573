<template>
  <exception>
    <template>
      <el-button type="primary"
                 @click="goHome">返回首页</el-button>
    </template>
  </exception>
</template>

<script>
import Exception from '@/components/Exception/index.vue'
export default {
  components: {
    'exception': Exception
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
