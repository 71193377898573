<template>
  <div class="student-list">
    <div class="search-bar block">
      <div class="label-input">
        <div class="label">姓名</div>
        <el-input v-model="search.name"
                  size="small"
                  class="search-input"
                  placeholder="请输入"
                  clearable
                  @clear="resetSearch"
                  @keyup.enter.native="handleSearch">
        </el-input>
      </div>
      <div class="label-input">
        <div class="label">关联家长账号</div>
        <el-input v-model="search.associatedAccount"
                  size="small"
                  class="search-input"
                  placeholder="请输入"
                  clearable
                  @clear="resetSearch"
                  @keyup.enter.native="handleSearch">
        </el-input>
      </div>
      <div class="button-box">
        <el-button @click="handleSearch"
                   size="small"
                   type="primary">查询</el-button>
        <el-button @click="resetSearch"
                   size="small"
                   class="line-btn"
                   plain>重置</el-button>
      </div>
    </div>
    <div>
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #realName="{row}">
          <template v-if="row.realName">{{row.realName}}</template>
          <template v-else>--</template>
        </template>
        <template #createTime="{row}">
          {{row.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}
        </template>
        <template #parentAccounts="{row}">
          <!-- <span class="parent-accounts">
            {{ row.parentAccounts }}
          </span> -->
          <template v-for="(account, index) in row.parentAccounts">
            <span v-if="index === 0" :key="index" class="parent-accounts">{{account}}</span>
            <span v-else :key="index" class="parent-accounts">{{' | ' + account}}</span>
          </template>
        </template>
      </ym-table-list>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      loading: false,
      search: {
        name: '',
        associatedAccount: ''
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '用户ID',
          prop: 'userId',
          show: true,
          width: 220
        },
        {
          label: '姓名',
          prop: 'realName',
          show: true,
          width: 120,
          enableSlot: true
        },
        {
          label: '账号',
          prop: 'loginAccount',
          show: true
          // width: 160
        },
        {
          label: '注册时间',
          prop: 'createTime',
          show: true,
          enableSlot: true,
          'min-width': 150
        },
        {
          label: '关联家长账号',
          prop: 'parentAccounts',
          show: true,
          enableSlot: true,
          'min-width': 100
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '查看',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'studentDetail', params: { userId: row.userId } })
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  mounted() {
    this.getList()
    // this.handleParentAccounts()
  },
  methods: {
    handleSearch() {
      if (this.search.name.trim() || this.search.associatedAccount.trim()) {
        // this.headData.title = '显示试卷名为: “' + this.formInput + '” 的搜索结果'
        this.$set(this.pageData, 'currentPage', 1)
        // this.search = this.formInput
        // this.formInput = ''
        this.getList()
      } else {
        this.search = {
          name: '',
          associatedAccount: ''
        }
      }
    },
    // 重置搜索框
    resetSearch(id) {
      this.search = {
        name: '',
        associatedAccount: ''
      }
      this.$set(this.pageData, 'currentPage', 1)
      // this.headData.title = ''
      this.getList()
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    },
    async getList() {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.userManagement.getStudentList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          name: this.search.name.trim(),
          parentAccount: this.search.associatedAccount.trim()
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    handleParentAccounts() {
      this.listData.forEach((element, index) => {
        let accountStr = element.parentAccounts.join(' | ')
        this.$set(this.listData[index], 'parentAccounts', accountStr)
      })
      console.log(this.listData)
    }
  }
}
</script>

<style lang="scss" scoped>
.student-list {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  .search-bar {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    .label-input {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        // width: 84px;
        margin-right: 12px;
        font-size: $--font-size-base;
        // text-align: right;
      }
      ::v-deep .search-input {
        width: 230px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
    }
    .button-box {
      margin-top: 16px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover, &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body div {
        color: $--color-text-primary;
      }
    }
    .parent-accounts {
      display: inline-block;
      white-space:pre;
    }
  }
}
</style>
