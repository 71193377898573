<template>
  <div class="table-list">

    <!-- 顶部搜索栏 -->
    <div class="table-list-form block">
      <ymForm ref="tableForm" refCode="tableForm" :inline="true"
        :formValue="formValue"
        :formData="formData"
        @onSubmit="onSubmit">
      </ymForm>
      <div class="table-list-form-button-box">
        <el-button size="small" @click="submitForm('tableForm')" type="primary">查询</el-button>
        <el-button size="small" @click="resetForm('tableForm')" class="line-btn">重置</el-button>
      </div>
    </div>
    <div class="control-bar block">
      <!-- <span class="title">{{headData.title}}</span> -->
      <!-- <span class="title"></span> -->
      <el-button @click="handleCreateUserGroup"
                 size="small"
                 type="primary">新建用户组</el-button>
    </div>
    <!-- 列表组件 -->
    <div>
      <ym-table-list
        :loading="loading"
        :data="tableData"
        :columns="columns"
        :pageData="pageData"
        :tableOption="tableOption"
        row-key="id"
        @handlePageChange="handlePageChange">
        <!-- 使用列表插槽 -->
        <template #groupDescription="{row}">
          <template v-if="row.groupDescription || row.groupDescription === 0">
            {{row.groupDescription}}
          </template>
          <template v-else>--</template>
        </template>
      </ym-table-list>
    </div>
    <el-dialog :visible.sync="dialogVisible" :title="usertableTitle" width="600px">
      <div style="margin: -10px 10px 0" >
       <el-table :data="usertableData" border style="width: 100%" max-height="280">
        <el-table-column prop="loginAccount" label="账号"></el-table-column>
        <el-table-column prop="userName" label="昵称"></el-table-column>
      </el-table>
      </div>
<!--       <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import ymForm from '@/components/YmForm'
import tableObj from './data.js'
export default {
  components: {
    YmTableList,
    ymForm
  },
  data() {
    return {
      loading: false,
      formValue: {}, // 用以显示的form表单
      searchForm: {}, // 用以搜索的obj
      tableData: [],
      dialogVisible: false, // 显示用户
      tableObj: tableObj,
      usertableData: [],
      usertableTitle: '用户列表',
      columns: tableObj.columnsData,
      pageData: tableObj.pageData,
      formData: tableObj.formData,
      // headData: tableObj.headData,
      tableOption: tableObj.tableOption
    }
  },
  mounted() {
    this.showAction()
    this.pageData.page = 1
    this.getData()
  },
  methods: {

    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    // 提交事件
    onSubmit(form, ref) {
      this.searchForm = JSON.parse(JSON.stringify(this.formValue))
      // this.formValue = {}
      // let groupName = this.searchForm.groupName
      // this.headData.title = '搜索 “' + (groupName || '') + '” 的结果'
      this.getData()
    },

    // 重置表单
    resetForm(id) {
      this.formValue = {}
      this.searchForm = {}
      // this.headData.title = '用户组列表'
      this.getData()
    },

    handleCreateUserGroup() {
      this.$router.push({ name: 'userGroup-add' })
    },

    // 判断当前页是否是从其他动作跳转而来
    showAction() {
      let action = this.$route.params.action
      if (action && action === 'add-success') {
        this.$message({ message: '新增用户组成功', type: 'success' })
      }
      if (action && action === 'edit-success') {
        this.$message({ message: '编辑用户组成功', type: 'success' })
      }
    },

    // 查看用户组下全部用户
    async showUser(row) {
      const { data } = await this.$api.userGroup.getUserByGroup(row.groupId)
      console.log({ '查看用户组下用户': data, '参数': row.groupId })
      if (!data || !data.data) {
        this.message.error('接口请求失败')
        return
      }
      this.usertableData = data.data
      this.dialogVisible = true
      this.usertableTitle = '显示 ' + row.groupName + ' 组中包含全部用户'
    },

    // 删除列表某一条用户组
    async deleteRow(row) {
      const { data } = await this.$api.userGroup.deleteUserGroup(row.groupId)
      if (data.code === 0) {
        this.$message({ message: '删除用户组成功', type: 'success' })
        this.getData()
      } else {
        this.$message.error('删除用户组失败: ' + data.message)
        this.getData()
      }
    },

    // 获取表格数据
    async getData() {
      this.loading = true
      Object.assign(this.searchForm, this.pageData)
      try {
        const { data } = await this.$api.userGroup.getUserGroupByPage(this.searchForm)
        console.log({ '用户组列表': data, '搜索条件': this.searchForm })
        this.loading = false
        if (!(data && data.data && data.data.records)) {
          this.$message.error('查询用户组失败: ' + data.data.message)
          return
        }
        if (data.data.records.length === 0 && data.data.total !== 0 && this.pageData.page > 1) {
          this.$set(this.pageData, 'page', this.pageData.page - 1)
          this.getData()
          return
        }
        this.tableData = data.data.records
        this.$set(this.pageData, 'total', Number.parseInt(data.data.total))
      } catch (e) {
        this.loading = false
        console.log(e)
        this.$message.error('获取用户组列表失败')
      }
    },
    handlePageChange(goPage) {
      this.$set(this.pageData, 'page', goPage)
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.table-list {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 16px 32px;
    margin-bottom: 20px;
    &.control-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 0;
      border: none;
      background-color: transparent;
      .title {
        font-weight: 600;
      }
      ::v-deep.el-button {
        width: 100px;
      }
    }
  }
  .table-list-form{
    ::v-deep.ym-form{
      float: left;
      // width: calc(100% - 230px);
      .el-input__inner {
        width: 350px;
      }
    }
    .table-list-form-button-box {
      min-width: 122px;
    }
  }
  ::v-deep.el-table {
    .el-table__header {
      thead tr th {
        padding: 16px 0;
      }
    }
    .el-table__body div {
      color: $--color-text-primary;
    }
  }
  ::v-deep.el-dialog {
    border-radius: $--border-radius-base;
    color: $--color-text-primary;
    .el-dialog__header {
      padding: 16px 20px;
      border-bottom: 1px solid $--border-color-light;
      .el-dialog__title {
        font-size: $--font-size-large;
      }
      .el-dialog__headerbtn {
        font-size: $--font-size-medium;
        line-height: 21px;
        .el-dialog__close:before {
          color: $--color-text-regular;
        }
      }
    }
    .el-dialog__body {
      // padding: 0;
      .dialog-body {
        padding: 4px 20px 20px;
        border-bottom: 1px solid $--border-color-light;
      }
    }
  }
}
</style>
