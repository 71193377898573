<template>
  <div class="teacher-list">
    <div class="block">
      <div class="table-tabs">
        <el-tabs v-model="currentTab">
          <el-tab-pane label="撮合平台"
                       name="matchPlatform">
            <div class="dialog-content">
              <template v-if="currentTab === 'matchPlatform'">
                <match-platform></match-platform>
              </template>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import MatchPlatform from './matchPlatform/index.vue'

export default {
  components: {
    MatchPlatform
  },
  data () {
    return {
      currentTab: 'matchPlatform'
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.teacher-list {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
  }
  ::v-deep .el-tabs {
    .el-tabs__header {
      padding: 14px 24px 0;
      margin-bottom: 0;
      border-bottom: $--header-border;
      .el-tabs__nav-wrap {
        &::after {
          background-color: transparent;
        }
      }
      .el-tabs__item {
        font-size: $--font-size-base;
        line-height: 24px;
      }
    }
    .el-tabs__content {
      padding-top: 20px;
    }
  }
}
</style>
