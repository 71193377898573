<template>
  <div class="course-detail">
    <div v-loading="infoLoading">
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>基本信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item">
              <span>订单号：</span>
              <span>{{(basicInfo && basicInfo.orderId) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>标题：</span>
              <span>{{(basicInfo && basicInfo.title) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>服务类型：</span>
              <span>{{(basicInfo && basicInfo.stage) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>服务项目：</span>
              <span>{{(basicInfo && basicInfo.subject) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>性别：</span>
              <template v-if="basicInfo && (basicInfo.sex || basicInfo.sex === 0)">
                <span>{{basicInfo.sex === 0 ? '女' : '男'}}</span>
              </template>
              <template v-else>
                <span>--</span>
              </template>
            </div>
            <div class="info-item">
              <span>服务地点：</span>
              <template v-if="basicInfo && [basicInfo.city || '', basicInfo.area || '', basicInfo.address || ''].join(' ')">
                <span>{{ [basicInfo.city || '', basicInfo.area || '', basicInfo.address || ''].join(' ') }}</span>
              </template>
              <template v-else>
                <span>--</span>
              </template>
            </div>
            <!-- <div class="info-item">
              <span>是否公开：</span>
              <span>{{(basicInfo && basicInfo.gender) || '--'}}</span>
            </div> -->
            <div class="info-item">
              <span>家长账号：</span>
              <span>{{(basicInfo && basicInfo.parentPhone) || '--'}}</span>
            </div>
            <div class="info-item">
              <span>创建时间：</span>
              <span>{{ basicInfo.createTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}</span>
            </div>
            <div class="info-item">
              <span>状态：</span>
              <template v-if="basicInfo && basicInfo.orderStatus">
                <template v-if="basicInfo.orderStatus === 1">
                  <span>招募中</span>
                </template>
                <template v-else-if="basicInfo.orderStatus === 2">
                  <span>授课中</span>
                </template>
                <template v-if="basicInfo.orderStatus === 3">
                  <span>已完结</span>
                </template>
                <template v-if="basicInfo.orderStatus === 4">
                  <span>已关闭</span>
                </template>
              </template>
              <template v-else>
                <span>--</span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>服务信息</span>
          </div>
          <el-form ref="form">
            <div class="detail-item-info">
              <div class="info-item">
                <span>期望服务日期：</span>
                <span>{{ basicInfo.startDate | timeFilter('YYYY.MM.DD') }}-{{ basicInfo.endDate | timeFilter('YYYY.MM.DD') }}</span>
              </div>
              <div class="info-item">
                <span>服务时段：</span>
                <span>{{`${basicInfo.startTime}-${basicInfo.endTime}`}}</span>
              </div>
              <div class="info-item">
                <span>预计次数：</span>
                <template v-if="basicInfo && basicInfo.totalClass">
                  {{basicInfo.totalClass + '次'}}
                </template>
                <template v-else>--</template>
              </div>
              <div class="info-item">
                <span>单价：</span>
                <template v-if="basicInfo && basicInfo.salary">
                  {{basicInfo.salary + '元/次'}}
                </template>
                <template v-else>--</template>
              </div>
              <div class="info-item">
                <span>总价：</span>
                <template v-if="basicInfo && basicInfo.totalSalary">
                  {{basicInfo.totalSalary.toFixed(2) + '元'}}
                </template>
                <template v-else>--</template>
              </div>
              <div class="info-item">
                <span>预付：</span>
                <template v-if="basicInfo && (basicInfo.subsist || basicInfo.subsist === 0)">
                  <span v-if="basicInfo.subsist !== 0">{{basicInfo.subsist.toFixed(2) + '元'}}</span>
                  <span v-else>{{basicInfo.subsist + '元'}}</span>
                </template>
                <template v-else>--</template>
              </div>
              <div class="info-item">
                <span>已付总费用：</span>
                <template v-if="basicInfo && (basicInfo.actualPayment || basicInfo.actualPayment === 0)">
                  <span v-if="basicInfo.actualPayment !== 0">{{basicInfo.actualPayment.toFixed(2) + '元'}}</span>
                  <span v-else>{{basicInfo.actualPayment + '元'}}</span>
                </template>
                <template v-else>--</template>
              </div>
              <!-- 执行中或已完成订单详情 -->
              <template v-if="basicInfo.orderStatus === 2 || basicInfo.orderStatus === 3">
                <div class="info-item">
                  <span>剩余次数：</span>
                  <template v-if="basicInfo && (basicInfo.laveClass || basicInfo.laveClass === 0)">
                    {{basicInfo.laveClass + '次'}}
                  </template>
                  <template v-else>--</template>
                </div>
                <!-- <div class="info-item">
                  <span>课程点评：</span>
                  <span class="comment"
                        @click="JumpToReview">课程点评</span>
                </div>
                <div class="info-item">
                  <span>课程教案：</span>
                  <span class="comment"
                        @click="JumpToLesson">课程教案</span>
                </div> -->
              </template>
              <!-- 已完成订单详情 -->
              <template v-if="basicInfo.orderStatus === 3">
                <div class="info-item">
                  <span>服务评价：</span>
                  <span class="comment"
                        @click="JumpToComment">服务评价</span>
                </div>
              </template>
              <!-- 退款订单详情 -->
              <!-- <template v-if="basicInfo.orderStatus === 4">
                <div class="info-item">
                  <span>退款：</span>
                  <span>{{(basicInfo && basicInfo.realName) || '--'}}</span>
                </div>
              </template> -->
            </div>
          </el-form>
        </div>
      </div>
      <div class="detail block"
           v-if="basicInfo.orderStatus !== 4">
        <div class="detail-item">
          <div class="detail-item-title">
            <template v-if="basicInfo.orderStatus === 1">
              <span>报名托管员信息</span>
            </template>
            <template v-else-if="basicInfo.orderStatus === 2">
              <span>辅导托管员信息</span>
            </template>
            <template v-else-if="basicInfo.orderStatus === 3">
              <span>接单/报名托管员信息</span>
            </template>
          </div>
          <div class="detail-item-table">
            <ym-table-list :loading="loading"
                           :data="teacherData"
                           :columns="teacherColumns"
                           :tableOption="tableOption">
              <template #sex={row}>
                <span v-if="row.sex === 0">女</span>
                <span v-if="row.sex === 1">男</span>
              </template>
              <template #tryLesson={row}>
                <span v-if="row.tryStatus === 1">已试课</span>
                <span v-else>--</span>
              </template>
            </ym-table-list>
          </div>
        </div>
      </div>
      <div class="detail block"
           v-if="basicInfo.orderStatus !== 1">
        <div class="detail-item brokerage-info">
          <div class="detail-item-title">
            <span>分佣信息</span>
          </div>
          <el-form ref="form">
            <div class="detail-item-info">
              <div class="info-item">
                <span>托管员报酬：</span>
                <template v-if="brokerageInfo && (brokerageInfo.tutorBrokerageSum || brokerageInfo.tutorBrokerageSum === 0)">
                  <span class="brokerage">
                    {{brokerageInfo.tutorBrokerageSum.toFixed(2)}}元
                  </span>
                </template>
                <template v-else>--</template>
              </div>
              <div class="info-item">
                <span>推荐托管员佣金：</span>
                <template v-if="brokerageInfo && (brokerageInfo.tutorReferenceBrokerageSum || brokerageInfo.tutorReferenceBrokerageSum === 0)">
                  <span class="brokerage">
                    {{brokerageInfo.tutorReferenceBrokerageSum.toFixed(2)}}元
                  </span>
                </template>
                <template v-else>--</template>
              </div>
              <div class="info-item">
                <span>推荐家长佣金：</span>
                <template v-if="brokerageInfo && (brokerageInfo.parentReferenceBrokerageSum || brokerageInfo.parentReferenceBrokerageSum === 0)">
                  <span class="brokerage">
                    {{brokerageInfo.parentReferenceBrokerageSum.toFixed(2)}}元
                  </span>
                </template>
                <template v-else>--</template>
              </div>
              <div class="info-item">
                <span>平台分佣：</span>
                <template v-if="brokerageInfo && (brokerageInfo.platformBrokerageSum || brokerageInfo.platformBrokerageSum === 0)">
                  <span class="brokerage">
                    {{brokerageInfo.platformBrokerageSum.toFixed(2)}}元
                  </span>
                </template>
                <template v-else>--</template>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>区块信息</span>
          </div>
          <div class="detail-item-info">
            <div class="info-item teaching">
              <span>区块哈希：</span>
              <span>{{(blockChainInfo && blockChainInfo.blockHash) || '--'}}</span>
            </div>
            <div class="info-item teaching">
              <span>上链编号：</span>
              <span>{{(blockChainInfo && blockChainInfo.evidenceCode) || '--'}}</span>
            </div>
            <div class="info-item teaching">
              <span>上链时间：</span>
              <template v-if="blockChainInfo && blockChainInfo.blockTime">
                {{blockChainInfo.blockTime | timeFilter('YYYY.MM.DD HH:mm:ss') }}
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>
      </div>
      <div class="detail block">
        <div class="detail-item">
          <div class="detail-item-title">
            <span>系统记录</span>
          </div>
          <div class="detail-item-table">
            <ym-table-list :loading="loading"
                           :data="listData"
                           :columns="listColumns"
                           :pageData="pageData"
                           :tableOption="tableOption"
                           @handlePageChange="handlePageChange">
              <template #time={row}>
                {{row.time | timeFilter('YYYY.MM.DD HH:mm:ss') }}
              </template>

            </ym-table-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'orderList' && !this.jumpFlag) {
      this.jumpFlag = true
      next({ name: 'orderList', params: { tab: 'course' } })
    } else {
      next()
    }
  },
  mounted () {
    this.orderId = this.$route.params.orderId
    this.getOrderDetail()
  },
  data () {
    return {
      jumpFlag: false,
      infoLoading: false,
      loading: false,
      enrollLoading: false,
      basicInfo: {},
      blockChainInfo: {},
      brokerageInfo: {},
      orderId: '',
      role: '',
      roleList: [
        {
          value: 0,
          label: '全部'
        },
        {
          value: 1,
          label: '小学'
        },
        {
          value: 2,
          label: '初一'
        },
        {
          value: 3,
          label: '初二'
        },
        {
          value: 4,
          label: '初三'
        },
        {
          value: 5,
          label: '高一'
        },
        {
          value: 6,
          label: '高二'
        },
        {
          value: 7,
          label: '高三'
        }
      ],
      tableOption: {
        stripe: true
      },
      teacherData: [],
      teacherColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '名称',
          prop: 'realName',
          show: true
        },
        {
          label: '手机号',
          prop: 'phone',
          show: true
        },
        {
          label: '性别',
          prop: 'sex',
          show: true,
          enableSlot: true
        },
        {
          label: '服务类型',
          prop: 'stageStr',
          show: true
        },
        {
          label: '服务项目',
          prop: 'subjectStr',
          show: true
        },
        // {
        //   label: '是否试课',
        //   prop: 'tryLesson',
        //   show: true,
        //   enableSlot: true,
        //   align: 'center'
        // },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '查看',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$router.push({ name: 'matchPlatformDetail', params: { userId: row.userId } })
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      listData: [],
      listColumns: [
        {
          label: '角色',
          prop: 'userRoleName',
          show: true,
          align: 'center'
        },
        {
          label: '操作记录',
          prop: 'operationDetail',
          show: true,
          align: 'center'
          // enableSlot: true
        },
        {
          label: '时间',
          prop: 'time',
          show: true,
          align: 'center',
          enableSlot: true
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      }
    }
  },
  methods: {
    async getOrderDetail () {
      this.infoLoading = true
      let res = {}
      try {
        res = await this.$api.order.getOrderDetail({
          orderId: this.orderId
        })
      } catch (err) {
        this.infoLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.basicInfo = res.data.data
        this.blockChainInfo = res.data.data.blockChainInfo
        this.getLog()
        this.getEnrollList(this.basicInfo.orderStatus)
        if (this.basicInfo.orderStatus !== 1) {
          this.getBrokerageInfo()
        }
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      if (this.basicInfo.orderStatus === 1) {
        this.infoLoading = false
      }
    },
    // 根据订单状态执行不同的数据请求
    async getEnrollList (orderStatus) {
      this.enrollLoading = true
      let res = {}
      try {
        if (orderStatus === 1) {
          res = await this.$api.order.getEnrollList({
            orderId: this.orderId
          })
        } else {
          res = await this.$api.order.getTutorInfo({
            orderId: this.orderId
          })
        }
      } catch (err) {
        this.enrollLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.teacherData = res.data.data
        this.teacherData.forEach(item => {
          if (item && item.stageList && item.stageList.length > 0) {
            this.$set(item, 'stageStr', item.stageList.join('、'))
          }
          if (item && item.subjectList && item.subjectList.length > 0) {
            this.$set(item, 'subjectStr', item.subjectList.join('、'))
          }
        })
        console.log(this.teacherData)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    // 分佣信息
    async getBrokerageInfo () {
      let res = {}
      try {
        res = await this.$api.order.getBrokerageInfo({
          orderId: this.basicInfo.orderId
        })
      } catch (err) {
        this.infoLoading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.brokerageInfo = res.data.data
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.infoLoading = false
    },
    async getLog () {
      this.loading = true
      let res = {}
      try {
        res = await this.$api.order.getLog({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          orderId: this.basicInfo.orderId
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    },
    JumpToComment () {
      this.$router.push({ name: 'comment', params: { orderId: this.orderId } })
    },
    JumpToReview () {
      this.$router.push({ name: 'review', params: { orderId: this.orderId } })
    },
    JumpToLesson () {
      this.$router.push({ name: 'lessonTemplate', params: { orderId: this.orderId } })
    },
    handlePageChange (page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getLog()
    }
  }
}
</script>

<style lang="scss" scoped>
.course-detail {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    // padding: 16px 32px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    .detail-item {
      color: $--color-text-primary;
      // padding: 24px 0 51px;
      padding: 0 0 18px;
      border-bottom: $--header-border;
      &:last-child {
        border-bottom: none;
      }
      .detail-item-title {
        padding: 16px 24px;
        margin-bottom: 12px;
        border-bottom: $--header-border;
        font-size: 16px;
        font-weight: 600;
      }
      .detail-item-info {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .info-item {
          width: 33.33%;
          font-size: 14px;
          margin-top: 20px;
          padding-right: 16px;
          .comment {
            color: $--color-primary;
            cursor: pointer;
          }
          .brokerage {
            color: #f56c6c;
          }
          &.teaching {
            width: 50%;
          }
        }
      }
      .detail-item-table {
        margin-top: 22px;
        padding: 0 24px;
        // width: 400px;
      }
      .select-item {
        margin-top: 16px;
        .label {
          display: inline-block;
          width: 56px;
          margin-right: 12px;
          font-size: $--font-size-base;
          text-align: right;
        }
        ::v-deep.select {
          width: 180px;
          margin-right: 20px;
          .el-input__inner {
            line-height: 33px;
          }
        }
      }
      &.brokerage-info {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
    ::v-deep .ym-table {
      .ym-table-header {
        margin-bottom: 20px;
      }
      .ym-table-bottom {
        // padding: 19px 32px;
      }
      .el-table {
        .el-table__header {
          thead tr th {
            padding: 16px 0;
          }
        }
        .el-table__body {
          td {
            height: 56px;
          }
          div {
            color: $--color-text-primary;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .course-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .course-detail {
    .block {
      .detail-item {
        .detail-item-info {
          .info-item {
            width: 100%;
            &.teaching {
              width: 100%;
            }
          }
        }
        &.brokerage-info {
          .detail-item-info {
            .info-item {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
