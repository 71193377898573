<template>
  <div class="distribution">
    <div class="search-bar block">
      <div class="label-input">
        <div class="label">账号</div>
        <el-input v-model="search.loginAccount"
                  size="small"
                  class="search-input"
                  placeholder="请输入"
                  clearable
                  @clear="resetSearch"
                  @keyup.enter.native="handleSearch">
        </el-input>
      </div>
      <div class="label-input">
        <div class="label"
             style="width:unset">接收消息手机号</div>
        <el-input v-model="search.phone"
                  size="small"
                  class="search-input"
                  placeholder="请输入"
                  clearable
                  @clear="resetSearch"
                  @keyup.enter.native="handleSearch">
        </el-input>
      </div>
      <div class="button-box">
        <el-button @click="handleSearch"
                   size="small"
                   type="primary">查询</el-button>
        <el-button @click="resetSearch"
                   size="small"
                   class="line-btn"
                   plain>重置</el-button>
      </div>
    </div>
    <div class="control-bar block">
      <el-button @click="handleAdd"
                 size="small"
                 type="primary">添加</el-button>
      <span style="flex-grow:1;text-align:left;padding-left:20px;font-size:14px">添加接收消息手机号的运营人员即可接收到用户订单变动的消息通知</span>
    </div>
    <div>
      <ym-table-list :loading="loading"
                     :data="listData"
                     :columns="listColumns"
                     :pageData="pageData"
                     :tableOption="tableOption"
                     @handlePageChange="handlePageChange">
        <template #type="{row}">
          {{row.type === 1 ? '订单通知消息': ''}}
        </template>
      </ym-table-list>

      <el-dialog :visible.sync="showModal"
                 width="440px"
                 title="添加接收消息通知的手机号">
        <el-form :model="form"
                 :rules="formRules"
                 ref="form"
                 label-width="120px">
          <el-form-item label="账号："
                        prop="userId">
            <el-select v-model="form.userId">
              <el-option v-for="(item,index) in followerSelectList"
                         :label="item.loginAccount"
                         :value="item.userId"
                         value-key="loginAccount"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接收手机号："
                        v-if="form.userId">
            {{form.phone}}
          </el-form-item>
          <el-form-item label="姓名："
                        v-if="form.userId">
            {{form.userName}}
          </el-form-item>
          <el-form-item label="通知类型："
                        v-if="form.userId">
            订单消息通知
          </el-form-item>
        </el-form>
        <div slot="footer">
          <div class="footer-btn">
            <el-button size="small"
                       @click="showModal = false">取消</el-button>
            <el-button type="primary"
                       size="small"
                       @click="addHandle">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data () {
    return {
      loading: false,
      search: {
        phone: '',
        loginAccount: ''
      },
      tableOption: {
        stripe: true
      },
      listData: [],
      listColumns: [
        {
          type: '占位栏',
          show: true,
          width: '22'
        },
        {
          label: '账号',
          prop: 'loginAccount',
          show: true
        },
        {
          label: '接收消息手机号',
          prop: 'phone',
          show: true
          // width: 180
        },
        {
          label: '姓名',
          prop: 'userName',
          show: true
        },
        {
          label: '消息通知类型',
          prop: 'type',
          show: true,
          enableSlot: true
        },
        {
          label: '操作',
          prop: 'operators',
          fixed: 'right',
          width: 80,
          align: 'right',
          children: {
            view: {
              label: '解绑',
              color: '#4674e6',
              clickEvent: (thisVue, row) => {
                thisVue.$api.notice.checkNotice({ id: row.recordId }).then(res => {
                  if (res.data.code === 0) {
                    // flag
                    // （true - 当前账号有跟进的订单
                    //   false - 当前账号没有跟进的订单）
                    if (!res.data.data) {
                      thisVue.$confirm('确定解绑此手机号码, 如果解绑,此手机将不再收到消息通知?', '解绑', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        thisVue.unBind(row.recordId)
                      }).catch(() => {
                      })
                    } else {
                      // 有跟进订单
                      thisVue.$confirm('确定解绑此手机号码, 当前账号有订单正在跟进?', '解绑', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                      }).then(() => {
                        thisVue.unBind(row.recordId)
                      }).catch(() => {
                      })
                    }
                  } else {
                    thisVue.$message({
                      type: 'error',
                      message: res.data.message
                    })
                  }
                })
              }
            }
          }
        },
        {
          type: '占位栏',
          show: true,
          width: '22',
          fixed: 'right'
        }
      ],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      },
      showModal: false,
      form: {
        userId: '',
        phone: '',
        type: 1
      },
      selectFollow: {},
      followerSelectList: [],
      formRules: {
        userId: [
          { trigger: 'blur', required: true, message: '请选择' }
        ]
      }
    }
  },
  mounted () {
    this.getList()
  },
  watch: {
    'form.userId': function (newVal, oldVal) {
      this.followerSelectList.some(item => {
        if (item.userId === newVal) {
          this.form.phone = item.phone
          this.form.userName = item.userName
        }
      })
    }
  },
  methods: {
    unBind (id) {
      this.$api.notice.unBindNotice({ recordId: id }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    addHandle () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.bindNotice()
        } else {
          return false
        }
      })
    },
    async bindNotice () {
      let res = {}
      try {
        res = await this.$api.notice.bindNotice(this.form)
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.$message({
          type: 'success',
          message: res.data.message
        })
        this.showModal = false
        this.getList()
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    async getFollowersList () {
      let res = {}
      try {
        res = await this.$api.order.getFollowersList()
      } catch (err) {
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.followersList = JSON.parse(JSON.stringify(res.data.data))
        this.followerSelectList = res.data.data
        // this.followersList.unshift({
        //   userId: 0,
        //   userName: '全部'
        // })
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
    },
    handleAdd () {
      this.showModal = true
      this.form.userId = ''
      this.form.phone = ''
      this.getFollowersList()
    },
    handleSearch () {
      if (this.search.phone.trim() || this.search.loginAccount.trim()) {
        // this.headData.title = '显示试卷名为: “' + this.formInput + '” 的搜索结果'
        this.$set(this.pageData, 'currentPage', 1)
        // this.search = this.formInput
        // this.formInput = ''
        this.getList()
      } else {
        this.search = {
          phone: '',
          loginAccount: ''
        }
      }
    },
    // 重置搜索框
    resetSearch (id) {
      this.search = {
        phone: '',
        loginAccount: ''
      }
      this.$set(this.pageData, 'currentPage', 1)
      // this.headData.title = ''
      this.getList()
    },
    handledistribute () {
      this.$router.push({ name: 'distribute' })
    },
    handlePageChange (page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getList()
    },
    async getList () {
      let res = {}
      try {
        this.loading = true
        res = await this.$api.notice.getNoticeList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          loginAccount: this.search.loginAccount.trim(),
          phone: this.search.phone.trim()
        })
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
      if (res.data.code === 0) {
        this.listData = res.data.data.records
        this.$set(this.pageData, 'total', res.data.data.total)
      } else {
        this.$message({
          type: 'error',
          message: res.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 0 32px 16px;
    margin-bottom: 20px;
    &.control-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 0;
      border: none;
      background-color: transparent;
      .title {
        font-weight: 600;
      }
      ::v-deep.el-button {
        width: 100px;
      }
    }
  }
  .search-bar {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    .label-input {
      // display: flex;
      // align-items: center;
      margin-top: 16px;
      .label {
        display: inline-block;
        width: 56px;
        margin-right: 12px;
        font-size: $--font-size-base;
        text-align: right;
      }
      ::v-deep .search-input {
        width: 230px;
        margin-right: 20px;
        .el-input__inner {
          line-height: 33px;
        }
      }
    }
    .button-box {
      margin-top: 16px;
      ::v-deep.line-btn {
        color: $--color-primary;
        border: 1px solid $--color-primary;
        &:hover,
        &:focus {
          background-color: $--color-primary-light-9;
        }
        &:active {
          background-color: #fff;
        }
      }
    }
  }
  ::v-deep .ym-table {
    .ym-table-header {
      margin-bottom: 20px;
    }
    .ym-table-bottom {
      // padding: 19px 32px;
    }
    .el-table {
      .el-table__header {
        thead tr th {
          padding: 16px 0;
        }
      }
      .el-table__body div {
        color: $--color-text-primary;
      }
    }
    .status-dot {
      display: inline-block;
      vertical-align: middle;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      &.success {
        background-color: #27cc85;
      }
      &.fail {
        background-color: #ff584d;
      }
      &.progressing {
        position: relative;
        background-color: #006eff;
        &::after {
          content: "";
          position: absolute;
          box-sizing: border-box;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid #006eff;
          border-radius: 50%;
          animation: statusProgressing 1.2s ease-in-out infinite;
        }
      }
    }
    .status-text {
      margin-left: 8px;
    }
  }
}
@keyframes statusProgressing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }

  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
</style>
